import { parseStyleString } from '@/lib/utils';
import { REVIEW_STATUS } from '@/sharedTypes';
import { Button, Stack } from '@mantine/core';
import parse, { Element } from 'html-react-parser';
import { memo, useMemo } from 'react';
import { MailberryNode } from './MailberryNode';

interface Props {
  html?: React.ReactNode;
  emailPreview?: any; // Add proper type based on your data structure
  emailReview?: any; // Add proper type based on your data structure
  selectedNodeId?: string | null;
  localCommentsCounter?: number;
  setStyles?: (styles: Record<string, any>) => void;
  toggleCommentInput?: (uniqueId: string) => void;
  getComments?: (uniqueId: string) => any;
}

export const HTMLRendered: React.FC<Props> = memo(({ emailPreview, emailReview, selectedNodeId, localCommentsCounter, setStyles, toggleCommentInput, getComments }) => {
  // https://www.npmjs.com/package/html-react-parser
  const htmlParsed = useMemo(() => {
    // Returns a react element by each domNode so we can add a onClick event to it
    if (emailReview && emailPreview) {
      let body = '';
      if (emailReview.status === REVIEW_STATUS.APPROVED || emailReview?.status === REVIEW_STATUS.CHANGES_REQUESTED) {
        body += emailReview.body;
        const styles = emailReview.styles as Record<string, any>;
        setStyles({
          editorColor: styles?.editorColor || '',
          fontColor: styles?.fontColor || '',
          borderWidth: styles?.borderWidth || '',
          borderColor: styles?.borderColor || '',
          borderRadius: styles?.borderRadius || '',
          backgroundColor: styles?.backgroundColor || '',
        });
      } else {
        body += emailPreview.body;
        const styles = emailPreview.styles as Record<string, any>;
        setStyles({
          editorColor: styles?.editorColor || '',
          fontColor: styles?.fontColor || '',
          borderWidth: styles?.borderWidth || '',
          borderColor: styles?.borderColor || '',
          borderRadius: styles?.borderRadius || '',
          backgroundColor: styles?.backgroundColor || '',
        });
      }

      return parse(body, {
        replace: (domNode, position) => {
          if (domNode instanceof Element && domNode.type === 'tag') {
            const uniqueId = `unique_id_${domNode.name}_${position}`;

            if (domNode.attribs.style) {
              const styleDict = parseStyleString(domNode.attribs.style);
              domNode.attribs.style = styleDict as any;
            }

            if (domNode.attribs.class) {
              domNode.attribs.className = domNode.attribs.class;
              delete domNode.attribs.class;
            }

            if (domNode.name === 'hr') {
              return <hr key={uniqueId} {...domNode.attribs} />;
            }

            const isSelected = selectedNodeId === uniqueId;
            const comments = getComments(uniqueId);

            const classNames = [];
            if (isSelected) classNames.push('commentNumber');

            return (
              <div
                onClick={() => toggleCommentInput(uniqueId)}
                className={classNames.join(' ')}
                style={{
                  display: 'flex',
                  position: 'relative',
                  backgroundColor: isSelected ? '#A5B4FC' : '',
                  cursor: 'pointer',
                }}
              >
                <MailberryNode
                  id={uniqueId}
                  domNode={domNode}
                />
                {(comments?.length > 0)
                  && (
                    <Button
                      color='indigo'
                      style={{
                        position: 'absolute',
                        right: 0,
                        height: '30px',
                      }}
                    >
                      {comments?.length}
                    </Button>
                  )}
              </div>
            );
          }
        },
      });
    }
  }, [emailPreview?.body, emailReview?.body, selectedNodeId, localCommentsCounter]);

  return (
    <Stack gap={0}>
      {htmlParsed}
    </Stack>
  );
});
