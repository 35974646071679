var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import styleObjectToCss from 'style-object-to-css-string';
import ButtonView from './ButtonView';
export var ReactEmailButton = Node.create({
    name: 'mailberrybutton',
    addOptions: function () {
        return {
            openOnClick: false,
            HTMLAttributes: {
                target: '_blank',
                rel: 'noopener noreferrer nofollow',
                href: '',
                description: 'Click here',
                align: 'CENTER',
                'data-bg-color': '#FFFFFF',
                'data-text-color': '#000000',
                width: '',
            },
        };
    },
    content: 'text*',
    group: 'block',
    marks: '_',
    atom: true,
    defining: true,
    draggable: true,
    addAttributes: function () {
        return {
            href: {
                default: null,
                parseHTML: function (element) { return element.getAttribute('href'); },
            },
            target: {
                default: this.options.HTMLAttributes.target,
            },
            description: {
                default: 'Click here',
                parseHTML: function (element) { return element.getAttribute('description'); },
            },
            align: {
                default: 'CENTER',
                parseHTML: function (element) { return element.getAttribute('align'); },
            },
            width: {
                default: 'auto',
                parseHTML: function (element) { return element.getAttribute('width'); },
                renderHTML: function (attributes) {
                    return {
                        width: attributes.width,
                    };
                },
            },
            'data-bg-color': {
                default: '#000000',
                renderHTML: function (attributes) {
                    return {
                        'data-bg-color': attributes['data-bg-color'],
                    };
                },
            },
            'data-text-color': {
                default: '#ffffff',
                renderHTML: function (attributes) {
                    return {
                        'data-text-color': attributes['data-text-color'],
                    };
                },
            },
            'data-button-styles': {
                default: styleObjectToCss({
                    padding: '15px 25px',
                    border: 'none',
                    borderRadius: '10px',
                }),
            },
            'data-text-styles': {
                default: styleObjectToCss({ fontSize: '18px', margin: '0', lineHeight: '28px' }),
            },
        };
    },
    parseHTML: function () {
        return [
            {
                tag: 'react-email-button',
            },
        ];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        var inlineStyles = HTMLAttributes['data-button-styles']
            + "background-color: ".concat(HTMLAttributes['data-bg-color'], " !important; color: ").concat(HTMLAttributes['data-text-color'], " !important; width:").concat(HTMLAttributes.width, ";")
            + (HTMLAttributes.width === '100%' ? 'box-sizing: border-box;' : '');
        var newAttributes = __assign(__assign({}, HTMLAttributes), { style: inlineStyles.trim() });
        return [
            'react-email-section',
            { style: styleObjectToCss({ padding: '12px 50px', textAlign: HTMLAttributes.align }), class: 'node-section' },
            ['react-email-button', mergeAttributes(this.options.HTMLAttributes, newAttributes), ['react-email-text', { style: HTMLAttributes['data-text-styles'] }, 0]],
        ];
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(ButtonView);
    },
    addCommands: function () {
        var _this = this;
        return {
            setMailberryButton: function (attributes) { return function (_a) {
                var editor = _a.editor, chain = _a.chain, commands = _a.commands;
                return commands.setNode(_this.name, attributes);
            }; },
        };
    },
});
