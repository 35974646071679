import { displayErrorNotification } from '@/components/display/notifications';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { ASSET_TYPE } from '@/sharedTypes';
import { Capacitor } from '@capacitor/core';
import { ActionIcon, Affix, Anchor, Box, Button, Grid, Group, Image, LoadingOverlay, Pagination, Space, Stack, Text, Transition } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES, MS_EXCEL_MIME_TYPE, MS_WORD_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useClickOutside } from '@mantine/hooks';
import { Asset } from '@prisma/client';
import { useAddAccountAsset, useDeleteAsset, useGetAllAssets, usePresignUrlPrivateResource, usePresignUrlPublicResource, useSignPrivateUrls, useUploadCSV } from '@services/api/assets';
import React, { useEffect, useRef, useState } from 'react';
import { FaRegTrashCan } from 'react-icons/fa6';
import { FiFile, FiFileText, FiVideo } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { TbUpload } from 'react-icons/tb';

export const getAssetType = (mimeType): ASSET_TYPE => {
  if (IMAGE_MIME_TYPE.includes(mimeType)) {
    return ASSET_TYPE.IMAGE;
  } else if ([MIME_TYPES.mp4, MIME_TYPES.webp].includes(mimeType)) {
    return ASSET_TYPE.VIDEO;
  } else if ([MIME_TYPES.csv, ...MS_EXCEL_MIME_TYPE].includes(mimeType)) {
    return ASSET_TYPE.CSV;
  } else if (
    [...MS_WORD_MIME_TYPE, ...PDF_MIME_TYPE].includes(mimeType)
  ) {
    return ASSET_TYPE.DOCUMENT;
  } else {
    return null;
  }
};

const getIconForFileType = (type: string) => {
  if (type === ASSET_TYPE.VIDEO) return FiVideo;
  if (type === ASSET_TYPE.CSV) return FiFileText;
  if (type === ASSET_TYPE.DOCUMENT) return FiFileText;
  return FiFile;
};

export default function Assets({}) {
  const presignPrivateUrlFiles = usePresignUrlPrivateResource();
  const presignPublicUrlFiles = usePresignUrlPublicResource();
  const uploadCsvMutate = useUploadCSV();
  const addAccountAsset = useAddAccountAsset();
  const getAllAssets = useGetAllAssets();
  const signPrivateUrls = useSignPrivateUrls();
  const deleteAssetById = useDeleteAsset();

  const isMobile = useCheckMobileScreen();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState<string[]>([]);
  const [deleConfirmation, setDeleteConfirmation] = useState<string>();
  const [refetch, setRefetch] = useState(0);
  const [openImage, setOpenImage] = useState<Asset | undefined>(undefined);
  const [assetList, setAssetList] = useState<Asset[]>([]);
  const [activePage, setPage] = useState(1);
  const openRef = useRef<() => void>(null);
  const refOpenImage = useClickOutside(() => setOpenImage(undefined));

  const handleUploadFile = async files => {
    setIsLoading(true);

    let count = 0;

    const uploadResource = async () => {
      const file = files[count];
      const isPublicResource = IMAGE_MIME_TYPE.includes(file.type);
      let presignedData;
      if (isPublicResource) {
        presignedData = await presignPublicUrlFiles.mutateAsync({ fileName: file.name, fileType: file.type, folder: 'ASSETS' });
      } else {
        presignedData = await presignPrivateUrlFiles.mutateAsync({ fileName: file.name, fileType: file.type, folder: 'ASSETS' });
      }
      const assetType = getAssetType(file.type);
      const data = presignedData.url;
      let assetKey = presignedData.assetKey;

      const formData = new FormData();

      Object.entries(data.fields as string[]).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append('file', file);

      fetch(data.url, {
        method: 'POST',
        referrer: '',
        mode: 'no-cors',
        body: formData,
      })
        .then(async res => {
          await addAccountAsset.mutateAsync(
            {
              baseUrl: data.url,
              key: assetKey,
              metadata: {
                'uploadedFrom': 'DEVICE',
              },
              type: getAssetType(file.type),
              bucketType: isPublicResource ? 'PUBLIC' : 'PRIVATE',
            },
          );
          if (assetType === ASSET_TYPE.CSV) {
            uploadCsvMutate.mutateAsync({ assetKey });
          }
        })
        .catch(error => {
          displayErrorNotification('error: ', error.message, 5000);
        }).finally(() => {
          count++;
          if (count < files.length) {
            uploadResource();
          } else {
            setRefetch(refetch + 1);
            setIsLoading(false);
          }
        });
    };

    await uploadResource();
  };

  useEffect(() => {
    getAllAssets.mutate(
      { folder: 'ASSETS' },
      {
        onSuccess: async res => {
          const originalList = res;
          const privateAssets = [];
          const privUrl = [];
          for (let i = 0; i < res.length; i++) {
            const asset = res[i];
            if (asset.bucketType === 'PRIVATE') {
              privateAssets[i] = asset;
              privUrl.push(asset.url);
            }
          }
          // Obtain signed urls for private assets
          const signedPrivResources = await signPrivateUrls.mutateAsync({ urls: privUrl });
          let count = 0;
          for (let i = 0; i < privateAssets.length; i++) {
            if (privateAssets[i]) {
              privateAssets[i].url = signedPrivResources[count];
              count++;
            }
          }
          // Replace private urls with signed urls
          for (let i = 0; i < originalList.length; i++) {
            if (originalList[i].bucketType === 'PRIVATE') {
              originalList[i] = privateAssets[i];
            }
          }
          setAssetList(originalList);
        },
      },
    );
  }, [refetch]);

  const showFileErrors = files => {
    console.log('rejected files', files);
    files.forEach(fileError => {
      const filePath = fileError.file.path;
      const errorMessages = fileError.errors.map(error => ` - ${error.code}, ${error.message} \n`);
      displayErrorNotification(`File: ${filePath} `, `Errors: ${errorMessages}`, 5000);
    });
  };

  const handleDeleteAsset = async (id: string) => {
    setDeleteConfirmation('');
    const newIsDeleting = [...isDeleting];
    newIsDeleting.push(id);
    setIsDeleting(newIsDeleting);
    await deleteAssetById.mutateAsync({ id });
    setAssetList(prevIsDeleting => prevIsDeleting.filter(asset => asset.id !== id));
    setIsDeleting(prevIsDeleting => prevIsDeleting.filter(assetId => assetId !== id));
  };

  type ChunkFunction = <T>(array: T[], size: number) => T[][];
  const chunk: ChunkFunction = (array, size) => {
    if (!array.length) {
      return [];
    }
    const head = array.slice(0, size);
    const tail = array.slice(size);
    return [head, ...chunk(tail, size)];
  };

  const paginatedData = chunk(
    assetList,
    !isMobile ? 9 : 12,
  );

  return (
    <Dropzone
      onDrop={handleUploadFile}
      onReject={showFileErrors}
      maxSize={5 * 1024 ** 2}
      accept={[
        MIME_TYPES.webp,
        MIME_TYPES.mp4,
        MIME_TYPES.csv,
        ...MS_WORD_MIME_TYPE,
        ...MS_EXCEL_MIME_TYPE,
        ...PDF_MIME_TYPE,
        ...IMAGE_MIME_TYPE,
      ]}
      openRef={openRef}
      loading={isLoading}
      activateOnClick={false}
      enablePointerEvents={true}
      preventDropOnDocument={true}
    >
      <Dropzone.Accept>
        <Stack align='center' gap='sm' justify='start' mah='100%' maw='100%' h='calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)))' bg='var(--mantine-color-gray-1)'>
          <Group justify='center' mt='md'>
            <Button size='xl' onClick={() => openRef.current?.()} disabled={isLoading}>Select files</Button>
          </Group>
          <Stack justify='center' align='center'>
            <Text size='xl' inline>
              Drag images here or click to select files
            </Text>
            <Text size='sm' c='dimmed' inline mt={7}>
              {Capacitor.isNativePlatform()
                ? 'Assets upload work best on desktop: open autopilot.mailberry.ai'
                : "Attach as many files as you'd like. Max file size: 5mb"}
            </Text>
          </Stack>
          <Stack>
            <TbUpload size={120} color='var(--mantine-color-gray-5)' style={{ marginTop: '2rem' }} />
          </Stack>
        </Stack>
      </Dropzone.Accept>

      <Dropzone.Idle>
        <Stack align='center' gap='sm' justify='start' mah='100%' maw='100%'>
          <Stack align='center' gap='sm' justify='start' mah='100%' maw='100%'>
            <Group justify='center' mt='md'>
              <Button size='xl' onClick={() => openRef.current?.()} disabled={isLoading}>Select files</Button>
            </Group>
            <Stack justify='center' align='center'>
              <Text size='xl' inline>
                Drag images here or click to select files
              </Text>
              <Text size='sm' c='dimmed' inline mt={7}>
                {Capacitor.isNativePlatform()
                  ? 'Assets upload work best on desktop: open autopilot.mailberry.ai'
                  : "Attach as many files as you'd like. Max file size: 5mb"}
              </Text>
            </Stack>
          </Stack>

          <Box px={`${isMobile ? '0' : '4em'}`} w='100%'>
            <Grid gutter='md' justify='flex-start' w='100%' px={16}>
              {paginatedData[activePage - 1]?.map((asset, k) => {
                const fileName = asset.bucketType === 'PRIVATE' ? asset.url.split('/').pop().match(/_([^?]*)\?/)[1] : '';
                return (
                  <Grid.Col span={{ base: 4, xs: 3 }} key={k}>
                    <Box
                      onClick={() => setOpenImage(asset)}
                      style={{
                        position: 'relative',
                        border: '2px solid var(--mantine-color-gray-4)',
                        zIndex: 10,
                        aspectRatio: '1',
                        width: '100%',
                      }}
                    >
                      <LoadingOverlay
                        visible={isDeleting.includes(String(asset.id))}
                        zIndex={1000}
                        overlayProps={{ radius: 'sm', blur: 2 }}
                      />
                      <Button
                        variant='outline'
                        onClick={e => {
                          e.stopPropagation();
                          setDeleteConfirmation(asset.id);
                        }}
                        p={0}
                        my={`${isMobile ? 0 : 10}`}
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          border: 'none',
                          color: 'gray',
                          zIndex: 50,
                        }}
                      >
                        <FaRegTrashCan
                          size={isMobile ? '20' : '26'}
                          style={{
                            margin: `${isMobile ? '5' : '20'}`,
                          }}
                        />
                      </Button>
                      {asset.type === ASSET_TYPE.IMAGE
                        ? (
                          <Image
                            w='100%'
                            h='100%'
                            m='auto'
                            p={isMobile ? 0 : 20}
                            fit='contain'
                            src={asset.cdnUrl || asset.url}
                            fallbackSrc='https://placehold.co/600x400?text=Placeholder'
                            style={{ cursor: 'pointer' }}
                          />
                        )
                        : (
                          <Group
                            h='100%'
                            w='auto'
                            m='auto'
                            p={isMobile ? 0 : 20}
                            style={{ overflow: 'hidden' }}
                            justify='center'
                          >
                            <Anchor
                              href={asset.url}
                              download={fileName}
                              aria-label={`Download ${fileName}`}
                            >
                              <Stack align='center'>
                                {React.createElement(getIconForFileType(asset.type), { size: isMobile ? '30px' : '80px', color: 'gray' })}
                                <Text size={`${isMobile ? '11px' : 'sm'}`} lineClamp={3} c='dark' style={{ wordBreak: 'break-all' }}>{fileName}</Text>
                              </Stack>
                            </Anchor>
                          </Group>
                        )}
                    </Box>
                  </Grid.Col>
                );
              })}
            </Grid>
          </Box>
          <Pagination total={paginatedData.length} value={activePage} onChange={setPage} size='xl' />
          <Space />

          {
            // Delete confirmation
          }
          {deleConfirmation && (
            <Affix zIndex={1000} position={{ top: 0, left: 0, right: 0, bottom: 0 }} withinPortal={false} bg='rgba(0, 0, 0, 0.5)'>
              <Transition
                duration={300}
                transition='scale'
                mounted={!!deleConfirmation}
              >
                {styles => (
                  <Stack justify='center' align='center' style={{ ...styles }}>
                    <Box
                      style={{
                        width: '400px',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '16px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <Text size='lg' style={{ marginBottom: '16px' }}>
                        Confirm delete?
                      </Text>
                      <Group justify='end'>
                        <Button
                          variant='outline'
                          color='gray'
                          style={{ marginRight: '8px' }}
                          onClick={() => setDeleteConfirmation('')}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='filled'
                          onClick={() => handleDeleteAsset(deleConfirmation)}
                        >
                          Delete
                        </Button>
                      </Group>
                    </Box>
                  </Stack>
                )}
              </Transition>
            </Affix>
          )}

          {
            // Open individial image on click
          }
          {openImage && openImage.type === 'IMAGE' && (
            <Affix zIndex={1000} position={{ top: 0, left: 0, right: 0, bottom: 0 }}>
              <Transition
                duration={300}
                transition='scale'
                mounted={openImage && openImage.type === 'IMAGE'}
              >
                {styles => (
                  <Group
                    justify='center'
                    style={{
                      // height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )',
                      marginTop: 'env(safe-area-inset-top)',
                      marginBottom: 'env(safe-area-inset-bottom)',
                      width: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      border: isMobile ? 'none' : '1px solid rgba(229, 231, 235, 0.4)',
                      ...styles,
                    }}
                    w='100%'
                  >
                    <Stack gap={0} bg='white' ref={refOpenImage} w='90%'>
                      <Group wrap='wrap' style={{ marginBottom: '0rem' }} align='center' justify='flex-end'>
                        <Text
                          style={{
                            textAlign: 'center',
                            margin: 18,
                            flex: 1,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          size={isMobile ? 'lg' : 'xl'}
                        >
                          {(openImage.url || openImage.cdnUrl).split('/').pop()}
                        </Text>
                        <ActionIcon size={isMobile ? 'lg' : 'xl'} onClick={() => setOpenImage(undefined)} m={isMobile ? 'lg' : 'xl'} c='dark' bg='transparent'>
                          <RxCross2 size={isMobile ? 'lg' : 'xl'} />
                        </ActionIcon>
                      </Group>
                      <Image
                        style={{
                          maxWidth: '90%',
                          maxHeight: '70vh',
                          width: 'auto',
                          height: 'auto',
                        }}
                        m='auto'
                        p={isMobile ? 0 : 20}
                        pb={20}
                        fit='contain'
                        src={openImage.cdnUrl || openImage.url}
                        fallbackSrc='https://placehold.co/600x400?text=Placeholder'
                      />
                    </Stack>
                  </Group>
                )}
              </Transition>
            </Affix>
          )}
          <Space />
        </Stack>
      </Dropzone.Idle>
    </Dropzone>
  );
}
