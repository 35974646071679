import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { displaySuccessNotification } from '@/components/display/notifications';
import { useGetEmailPreview } from '@/services/api/emailPreview';
import { useGetEmailReview, useUpdateEmailReview } from '@/services/api/emailReview';
import { EMAIL_EDITOR_TYPE, REVIEW_STATUS } from '@/sharedTypes';
import { EDITOR_TYPE } from '@/types/models';
import { useParams } from 'react-router-dom';
import EmailReviewCore, { EmailReviewProps } from './EmailReviewCore';
import ReactEmailReviewCore from './ReactEmailReviewCore';

const EmailReview = ({ onClose }: EmailReviewProps) => {
  // get the params from the url slug
  const { draftId } = useParams();

  const { data: emailReview, isLoading: isLoadingEmailReview } = useGetEmailReview(draftId);
  const { data: emailPreview, isLoading: isLoadingEmailPreview } = useGetEmailPreview(draftId);
  const updateEmailReview = useUpdateEmailReview();

  if (isLoadingEmailReview || isLoadingEmailPreview) return <MailberryLoader />;

  const handleApproveEmail = (emailReviewId: string, content: Record<string, any> = {}) => {
    const localComments = JSON.parse(localStorage.getItem('comments_' + emailReviewId)!);
    const previousComments = content?.comments;

    const comments = { ...previousComments, ...localComments };

    updateEmailReview.mutateAsync({ id: emailReviewId, status: REVIEW_STATUS.APPROVED, content: { comments } }).then(d => {
      localStorage.removeItem('comments_' + emailReviewId);
      if (d.id) {
        displaySuccessNotification('Review Sent', 'The email has been successfully approved', 5000);
        onClose && onClose();
      }
    });
  };

  const handleRequestChanges = (emailReviewId: string, content: Record<string, any> = {}) => {
    const localComments: (string | { content: string; xpath: string; position: { x: number; y: number; }; })[] = JSON.parse(localStorage.getItem('comments_' + emailReviewId)!);
    const previousComments: (string | { content: string; xpath: string; position: { x: number; y: number; }; })[] = content?.comments;

    const comments: (string | { content: string; xpath: string; position: { x: number; y: number; }; })[] = { ...previousComments, ...localComments };

    updateEmailReview.mutateAsync({ id: emailReviewId, status: REVIEW_STATUS.CHANGES_REQUESTED, content: { comments } }).then(d => {
      localStorage.removeItem('comments_' + emailReviewId);
      if (d.id) {
        displaySuccessNotification('Review Sent', 'The email has been sent back for changes', 5000);
        onClose && onClose();
      }
    });
  };

  return (
    <>
      {emailPreview.editor === EMAIL_EDITOR_TYPE.TIPTAP_REACTEMAIL
        ? (
          <ReactEmailReviewCore
            draftId={draftId}
            emailPreview={emailPreview}
            emailReview={emailReview}
            onApprove={handleApproveEmail}
            onRequestChanges={handleRequestChanges}
            isLoading={updateEmailReview.isLoading}
          />
        )
        : (
          <EmailReviewCore
            draftId={draftId}
            emailPreview={emailPreview}
            emailReview={emailReview}
            onApprove={handleApproveEmail}
            onRequestChanges={handleRequestChanges}
            isLoading={updateEmailReview.isLoading}
          />
        )}
    </>
  );
};

export default EmailReview;
