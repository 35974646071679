import { Box, CSSProperties, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { ArrowLeftIcon, Cross1Icon } from '@radix-ui/react-icons';

interface Props {
  comments: (string | { content: string; xpath: string; position: { x: number; y: number; }; })[];
  //  onClose: () => void;
  isMobile?: boolean;
  styles?: React.CSSProperties;
  withInput: boolean;
}

const CommentList: React.FC<Props> = ({ comments, isMobile, styles, withInput }) => {
  const _styles: CSSProperties = {
    backgroundColor: 'white',
  };
  if (withInput) {
    _styles.paddingBottom = '10px';
  }
  return (
    <Box
      px={10}
      pt={10}
      style={{
        zIndex: 102,
        ..._styles,
        ...styles,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          lineHeight: '1rem',
        }}
        size={isMobile ? 'lg' : 'xl'}
      >
        Comments
      </Text>

      <Stack
        id='comment-list'
        mt={10}
        gap={10}
        style={{
          overflowY: 'scroll',
          maxHeight: '50vh',
        }}
      >
        {comments.map((c: string | { content: string; xpath: string; position: { x: number; y: number; }; }, idx: number) => {
          return (
            <Box
              key={idx}
              style={{
                border: '1px solid rgb(206, 212, 218)',
                borderRadius: '4px',
              }}
              p={11.5}
            >
              <Text
                style={{
                  lineHeight: '25px',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {typeof c === 'string' ? c : c.content}
              </Text>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default CommentList;
