import { Tooltip } from '@mantine/core';
import parse, { DOMNode, domToReact, Element } from 'html-react-parser';
import React, { createElement, DOMAttributes, DOMElement, ReactElement, useState } from 'react';

type Props = {
  domNode: any;
  id: string;
};

export const MailberryNode: React.FC<Props> = ({ domNode, id }) => {
  const attributes = { ...domNode.attribs, id };

  // Handle mailberrybutton
  if (domNode.name === 'mailberrybutton') {
    return (
      <ButtonWithTooltip
        key={`0_${domNode.attribs.href}`}
        url={domNode.attribs.href}
      >
        {createElement(domNode.name, attributes, domNode.attribs.description)}
      </ButtonWithTooltip>
    );
  }

  const addTooltipToAnchor = (element: any, index: number) => (
    <LinkWithTooltip
      key={`${index}_${element.props.href}`}
      url={element.props.href}
    >
      {element.props.children}
    </LinkWithTooltip>
  );

  let children = domToReact((domNode as Element).children as DOMNode[]);

  if (Array.isArray(children)) {
    children = children.map((child, i) => child.type === 'a' ? addTooltipToAnchor(child, i) : child);
  } else if (typeof children !== 'string' && children?.type === 'a') {
    children = addTooltipToAnchor(children, 0);
  }

  return createElement(domNode.name, attributes, children);
};

const BaseTooltip = ({ url, children }) => (
  <Tooltip
    label={url}
    position='top'
    withArrow
    multiline
    maw={'350px'}
    events={{ hover: true, focus: true, touch: true }}
  >
    {children}
  </Tooltip>
);

const LinkWithTooltip = ({ url, children }) => (
  <BaseTooltip url={url}>
    <span
      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
      onClick={e => e.preventDefault()}
    >
      {children}
    </span>
  </BaseTooltip>
);

const ButtonWithTooltip = ({ url, children }) => (
  <BaseTooltip url={url}>
    {children}
  </BaseTooltip>
);
