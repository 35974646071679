import { calcAverage, formatToDayDate } from '@/lib/utils';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useTrackDemoEvent } from '@/services/api/demo/tracking';
import { Box, Card, Divider, Group, Skeleton, Text, Title } from '@mantine/core';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
// src/components/Reports.jsx
import { useGetAllDemoDrafts } from '@/services/api/demo/draft';
import AppState from '@/services/state/AppState';
import { Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export default function DemoReportsList({ withPagination = false }) {
  const appState = AppState.useContainer();
  const { isLoading, data: drafts } = useGetAllDemoDrafts(appState?.UI?.screen);
  // const { data: deliveries, isLoading: isLoadingDeliveries } = useGetDemoDeliveries();
  const navigate = useNavigate();

  const handleViewDetails = ({ draftId }) => {
    return navigate(`/demo/reports/${draftId}`);
  };

  const handleListReports = () => {
    navigate(`/demo/reports`);
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      <Title order={4} fw={600} pb={'md'}>
        Sent Campaigns
      </Title>
      <DemoReportList
        isLoading={isLoading}
        drafts={drafts?.data}
        onView={handleViewDetails}
      />
    </Stack>
  );
}

const DemoReportList = ({ isLoading, drafts, onView }) => {
  const calculateRates = (sendTo: number, open: number, click: number) => {
    const clickRate = calcAverage(open, click);
    const openRate = calcAverage(sendTo, open);
    return { clickRate, openRate };
  };

  const { currentStep, setCurrentStep, isOpen, setIsOpen } = useTour();
  const trackDemoEvent = useTrackDemoEvent();
  const appState = AppState.useContainer();

  useEffect(() => {
    if (currentStep === 10) {
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep }).catch(e => console.error(e));
    }
  }, [currentStep]);

  useEffect(() => {
    if (!isLoading && appState.userAndCustomer.isInTour && drafts?.length && currentStep === 10) {
      setCurrentStep(currentStep + 1);
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep + 1 }).catch(e => console.error(e));
      setTimeout(() => setIsOpen(true), 400);
    }
  }, [drafts?.length, isLoading]);

  return (
    <Stack className='tour-reports'>
      {isLoading
        ? (
          Array.from({ length: 5 }).map((_, index) => (
            <Card key={index} shadow='sm' p='lg' radius='md' withBorder>
              <Skeleton height={30} width='70%' mb='md' />
              <Skeleton height={20} width='90%' mb='md' />
              <Divider />
              <Skeleton height={20} width='50%' mb='md' />
              <Skeleton height={20} radius='md' width='100%' />
              <Skeleton height={20} width='50%' mt='md' />
              <Skeleton height={20} radius='md' width='100%' />
            </Card>
          ))
        )
        : drafts?.length === 0
        ? <MBEmptyGeneric />
        : (
          drafts?.map((draft, idx) => {
            const { clickRate, openRate } = calculateRates(draft.delivery.sendTo, draft.delivery.open, draft.delivery.click);
            const classname = idx === 0 ? '' : 'tour-report-delivery';
            return (
              <Card className={classname} key={draft.delivery.id} style={{ cursor: 'pointer' }} onClick={() => onView({ draftId: draft.id })} shadow='sm' p='lg' radius='md' withBorder>
                <Text fw={500} size='lg' lineClamp={2}>{draft.subject}</Text>
                <Text size='sm' c='dimmed'>{draft.preHeader}</Text>
                <Text size='sm' c='dimmed' mt='sm'>
                  {`Sent on ${formatToDayDate(draft.delivery.toBeSentAt)}`}
                </Text>
                <Divider mt='sm' mb='sm' />
                <Group justify='space-evenly'>
                  <Box ta={'center'}>
                    <Text size='sm' c='dimmed'>Open rate</Text>
                    <Text fw={700} size='xl'>{`${parseFloat(openRate.toFixed(2))}%`}</Text>
                  </Box>

                  <Box ta={'center'}>
                    <Text size='sm' c='dimmed'>Click rate</Text>
                    <Text fw={700} size='xl'>{`${parseFloat(clickRate.toFixed(2))}%`}</Text>
                  </Box>
                </Group>
              </Card>
            );
          })
        )}
    </Stack>
  );
};
