var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Container, Section } from '@react-email/components';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
export var DocumentView = function (_a) {
    var editor = _a.editor, node = _a.node, getPos = _a.getPos, selected = _a.selected;
    var customStyles = node.attrs.styles;
    var bodyStyles = __assign({}, customStyles.body);
    var containerStyles = __assign({}, customStyles.container);
    if (editor.storage.isMobile) {
        bodyStyles.padding = '0px';
        containerStyles.border = 'none';
        containerStyles.borderRadius = '0px';
        containerStyles.borderBottomLeftRadius = '10px';
        containerStyles.borderBottomRightRadius = '10px';
    }
    return (React.createElement(NodeViewWrapper, null,
        React.createElement("div", { className: 'document-body', style: bodyStyles },
            React.createElement(Container, { className: 'document-container', style: containerStyles },
                React.createElement(Section, { style: customStyles.section },
                    React.createElement(NodeViewContent, { as: 'div' }))),
            !editor.isEditable
                && (React.createElement("div", { style: { textAlign: 'center', padding: '14px 48px 40px' } },
                    React.createElement("p", { style: { display: 'inline', textAlign: 'center', fontSize: '14px', lineHeight: '24px' } }, "Powered by"),
                    React.createElement("a", { target: '_blank', rel: 'noreferrer', href: 'https://mailberry.ai/?utm_source=Email&utm_medium=Mailberry&utm_campaign=CustomersAreFrom' },
                        React.createElement("img", { width: '75', height: '18', style: { verticalAlign: 'text-bottom', display: 'inline-block', outline: 'none', marginLeft: '5px' }, src: 'http://d1q3o19jn6nxkf.cloudfront.net/email/mailberry-logo.png', alt: 'Mailberry logo' })))))));
};
