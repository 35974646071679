import { demoEmail } from '@/lib/constants';
import { EMAILS_VIEW_TABS } from '@/lib/types';
import { calcAverage, formatToDayDate } from '@/lib/utils';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetAllDrafts, useGetAllDraftsCount } from '@/services/api/drafts';
import { useGetAllLastReview } from '@/services/api/emailReview';
import { usePagination } from '@/services/hooks/usePagination';
import AppState from '@/services/state/AppState';
import { REVIEW_STATUS, Web } from '@/sharedTypes';
import { Group, Pagination, Stack } from '@mantine/core';
import { Box, Card, Divider, Skeleton, Text } from '@mantine/core';
import { formatDistanceToNow } from 'date-fns';
import { CiClock1 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { STATUS_COLORS } from '../common';

export default function DeliveriesList({ withPagination = false }) {
  const appState = AppState.useContainer();
  const navigate = useNavigate();

  // Get both reviews and sent campaigns
  const { data: reviews, isLoading: isLoadingReviews } = useGetAllLastReview();
  const { data: draftCount } = useGetAllDraftsCount({ status: EMAILS_VIEW_TABS.SENT }, [appState?.UI?.screen]);

  const { setPage, limit, offset, page, totalPages } = usePagination(5, draftCount?.total);
  const { data: sentCampaigns, isLoading: isLoadingDeliveries } = useGetAllDrafts(
    {
      status: EMAILS_VIEW_TABS.SENT,
      limit,
      offset,
    },
    [offset, limit, appState?.UI?.screen],
  );

  const handleViewDetails = ({ draftId }) => {
    navigate(`/reports/${draftId}`);
  };

  const handleViewReview = ({ draftId }) => {
    navigate(`/review/${draftId}`);
  };

  // Combine and limit the total items to 5
  let combinedItems: Array<
    | (Omit<Web.Response.Review, 'createdAt' | 'updatedAt'> & { type: 'review'; status: REVIEW_STATUS; })
    | (Web.Response.IDraftDelivery & { type: 'campaign'; })
  > = (sentCampaigns || [])?.map(campaign => ({ ...campaign, type: 'campaign' as const }));

  if (!withPagination) {
    combinedItems = [
      ...(reviews || []).map(review => ({
        ...review,
        type: 'review' as const,
        status: review.status as REVIEW_STATUS,
      })),
      ...(sentCampaigns || []).slice(0, Math.max(0, 5 - (reviews || []).length)).map(campaign => ({
        ...campaign,
        type: 'campaign' as const,
      })),
    ];
  }

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      <CombinedList
        isLoading={isLoadingDeliveries || isLoadingReviews}
        items={combinedItems}
        onViewCampaign={handleViewDetails}
        onViewReview={handleViewReview}
      />
      {withPagination && (
        <Group justify='center'>
          <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='black' />
        </Group>
      )}
    </Stack>
  );
}

interface CombinedListProps {
  isLoading: boolean;
  items: Array<
    | (Omit<Web.Response.Review, 'createdAt' | 'updatedAt'> & {
      type: 'review';
    })
    | (Web.Response.IDraftDelivery & { type: 'campaign'; })
  >;
  onViewCampaign: (params: { draftId: string; }) => void;
  onViewReview: (params: { draftId: string; }) => void;
}

const CombinedList = ({ isLoading, items, onViewCampaign, onViewReview }: CombinedListProps) => {
  const calculateRates = (sendTo: number, open: number, click: number) => {
    const clickRate = calcAverage(open, click);
    const openRate = calcAverage(sendTo, open);
    return { clickRate, openRate };
  };
  const appState = AppState.useContainer();

  if (isLoading) {
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => (
          <Card key={index} shadow='sm' p='lg' radius='md' withBorder>
            <Skeleton height={30} width='70%' mb='md' />
            <Skeleton height={20} width='90%' mb='md' />
            <Divider />
            <Skeleton height={20} width='50%' mb='md' />
            <Skeleton height={20} radius='md' width='100%' />
          </Card>
        ))}
      </>
    );
  }

  if (!items?.length) {
    return <MBEmptyGeneric />;
  }

  return (
    <>
      {items.map(item => {
        if (item.type === 'review') {
          const review = item as (Omit<Web.Response.Review, 'createdAt' | 'updatedAt'> & {
            type: 'review';
            createdAt: string;
            updatedAt: string;
            scheduledTo: string | null;
          });
          return (
            <Card
              style={{ cursor: 'pointer' }}
              key={review.id}
              shadow='sm'
              p='lg'
              radius='md'
              withBorder
              onClick={() => onViewReview({ draftId: review.draftId })}
            >
              <Stack gap={4}>
                <Text fw={500} size='lg'>
                  {review.subject || 'No Subject'}
                  <span style={{ color: '#777' }}>{review.preHeader && ` - ${review.preHeader}`}</span>
                </Text>
                {review.status === REVIEW_STATUS.APPROVED && (
                  <Group wrap='nowrap'>
                    <CiClock1 />
                    <Text display={'inline'} size='sm' c='dimmed'>
                      {review.scheduledTo ? `Scheduled for ${formatToDayDate(review.scheduledTo)}` : 'Not yet scheduled'}
                    </Text>
                  </Group>
                )}
                <Text size='lg' style={{ color: STATUS_COLORS[review.status] }} ta='center'>
                  {review.status === 'READY_FOR_REVIEW' && 'AWAITING YOUR REVIEW'}
                  {review.status !== 'READY_FOR_REVIEW'
                    && review.status.charAt(0).toUpperCase() + review.status.slice(1).replaceAll('_', ' ')}
                </Text>
              </Stack>
            </Card>
          );
        } else {
          const draft = item as Web.Response.IDraftDelivery & { type: 'campaign'; };
          const { clickRate, openRate } = calculateRates(
            draft.delivery.sendTo,
            draft.delivery.open,
            draft.delivery.click,
          );

          return (
            <Card
              key={draft.delivery.id}
              style={{ cursor: 'pointer' }}
              onClick={() => onViewCampaign({ draftId: draft.id })}
              shadow='sm'
              p='lg'
              radius='md'
              withBorder
            >
              <Text fw={500} size='lg'>
                {item.subject || 'No Subject'}
                <span style={{ color: '#777' }}>{item.preHeader && ` - ${item.preHeader}`}</span>
              </Text>
              <Text size='sm' c='dimmed' mt='sm'>
                {`Sent ${formatDistanceToNow(new Date(draft.delivery.toBeSentAt), { addSuffix: true })}`}
              </Text>
              <Divider mt='sm' mb='sm' />
              <Group justify='space-evenly'>
                <Box ta={'center'}>
                  <Text size='sm' c='dimmed'>Open rate</Text>
                  <Text fw={700} size='xl'>{appState.signupAndLogin.email === demoEmail ? '56%' : `${parseFloat(openRate.toFixed(2))}%`}</Text>
                </Box>
                <Box ta={'center'}>
                  <Text size='sm' c='dimmed'>Click rate</Text>
                  <Text fw={700} size='xl'>{appState.signupAndLogin.email === demoEmail ? '40%' : `${parseFloat(clickRate.toFixed(2))}%`}</Text>
                </Box>
              </Group>
            </Card>
          );
        }
      })}
    </>
  );
};
