import { Box } from '@mantine/core';
import React from 'react';

interface EmailPreviewProps {
  htmlContent: string;
  styles?: {
    backgroundColor: string;
    // Add other style properties as needed
  };
  editor?: string;
  appState?: any;
}

export const DesktopPreviewWrapper: React.FC<EmailPreviewProps> = ({
  htmlContent,
  styles = { backgroundColor: '#ffffff' },
  editor,
  appState,
}) => {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      style={{
        backgroundColor: styles.backgroundColor,
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
      }}
    />
  );
};

export const MobileEmailPreviewWithoutActions: React.FC<EmailPreviewProps> = ({
  htmlContent,
  styles = { backgroundColor: '#ffffff' },
  editor,
}) => {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      style={{
        backgroundColor: styles.backgroundColor,
        maxWidth: '375px',
        margin: '0 auto',
        padding: '16px',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
      }}
    />
  );
};
