import MailberryPaper from '@components/display/MailberryPaper';
import { REPORT_VIEW_TABS } from '@lib/types';
import MailberryPageWrapper from '@mailberry/PageWrapper';
import { Box, Container } from '@mantine/core';
import AppState from '@services/state/AppState';

const DeliveryReportStats = ({ children, stylesChildren, tabs = Object.keys(REPORT_VIEW_TABS), ...props }) => {
  const appState = AppState.useContainer();

  return (
    <MailberryPageWrapper
      title={props.showHeader ? `Email Statss` : ''}
      tabs={tabs}
      selectedTab={appState.UI.reportMenuTab}
      setSelectedTab={appState.UI.setReportMenuTab}
      showNavigateBackButton={props.showHeader}
    >
      <MailberryPageWrapper.MainAction></MailberryPageWrapper.MainAction>
      {children.map((c, ix) => {
        return (
          <MailberryPageWrapper.Tab key={`MailberryPageWrapperTab_${tabs[ix]}`} value={tabs[ix]}>
            <RenderReport stylesChildren={stylesChildren[ix]}>{c}</RenderReport>
          </MailberryPageWrapper.Tab>
        );
      })}
    </MailberryPageWrapper>
  );
};

export default DeliveryReportStats;

function RenderReport({ children, stylesChildren }) {
  if (stylesChildren.border) {
    return (
      <Container size={'xl'}>
        <MailberryPaper m={'3em 0em'} p={0}>
          <Box pos={'relative'}>{children}</Box>
        </MailberryPaper>
      </Container>
    );
  } else {
    return (
      <Box
        w={'100%'}
        h={'100%'}
        style={{
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    );
  }
}
