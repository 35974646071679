import React from 'react';
interface MBDragProps {
  size: number;
  color?: string;
}

const MBDrag = ({ size, color = '#A0A0A0' }: MBDragProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='9' y='5' width='2' height='3' fill={color} />
      <rect x='13' y='5' width='2' height='3' fill={color} />
      <rect x='9' y='10' width='2' height='3' fill={color} />
      <rect x='13' y='10' width='2' height='3' fill={color} />
      <rect x='9' y='15' width='2' height='3' fill={color} />
      <rect x='13' y='15' width='2' height='3' fill={color} />
    </svg>
  );
};

export default MBDrag;
