import { DemoDraft, Draft } from '@prisma/client';
import { RefObject } from 'react';
import { DesktopEmailPreview, MobileEmailPreview } from './EmailPreview';

interface CodeEditorPreviewProps {
  showDesktopVersion: boolean;
  isMobile: boolean;
  styles: {
    editorColor: string;
    fontColor: string;
    borderRadius: string;
    borderWidth: string;
    borderColor: string;
  };
  emailReview: {
    id: string;
    draft: Draft | DemoDraft;
    content?: {
      comments?: Record<string, { content: string; xpath: string; position: { x: number; y: number; }; }[]>;
    };
  };
  iframeRef: RefObject<any>;
}

const CodeEditorPreview = ({
  showDesktopVersion,
  isMobile,
  styles,
  emailReview,
  iframeRef,
}: CodeEditorPreviewProps) => {
  const handleIframeLoad = (comments: Record<string, any>) => {
    const iframe = iframeRef.current;
    const script = `
      // Function to get XPath of an element
      function getXPath(element) {
        if (element.id !== '') {
          return '//*[@id="' + element.id + '"]';
        }
        
        if (element === document.body) {
          return '/html/body';
        }

        let ix = 0;
        const siblings = element.parentNode.childNodes;
        
        for (let i = 0; i < siblings.length; i++) {
          const sibling = siblings[i];
          if (sibling === element) {
            const pathIndex = ix + 1;
            const path = getXPath(element.parentNode) + '/' + element.tagName.toLowerCase() + '[' + pathIndex + ']';
            return path;
          }
          
          if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
            ix++;
          }
        }
      }

      // Add comment count indicators to elements
      function addCommentCounters(comments) {
        // Remove any existing counters first
        document.querySelectorAll('.comment-counter').forEach(el => el.remove());
        
        // Add counters for each xpath that has comments
        Object.entries(comments).forEach(([xpath, commentsList]) => {
          try {
            const element = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
            
            if (element) {
              const rect = element.getBoundingClientRect();
              const counter = document.createElement('div');
              counter.className = 'comment-counter';
              counter.textContent = commentsList.length;
              counter.style.cssText = \`
                font-family: __PT_Sans_94ebfd, __PT_Sans_Fallback_94ebfd;
                position: absolute;
                top: \${rect.top}px;
                left: \${rect.right - 40}px;
                background-color: #3B82F6;
                color: white;
                z-index: 1000;
                pointer-events: none;
                height: 30px;
                max-height: 30px;
                padding: 0px 16px;
                border-radius: 4px;
                line-height: 30px;
                font-size: 16px;
              \`;
              
              document.body.appendChild(counter);
            }
          } catch (e) {
            console.error('Error adding counter for xpath:', xpath, e);
          }
        });
      }

      // Add initial comment counters
      addCommentCounters(${JSON.stringify(comments)});

      // Update counter positions on scroll
      document.addEventListener('scroll', () => {
        // Remove existing counters
        document.querySelectorAll('.comment-counter').forEach(el => el.remove());
        // Re-add counters with updated positions
        addCommentCounters(${JSON.stringify(comments)});
      }, { passive: true });

      document.body.addEventListener('click', (e) => {
        const rect = e.target.getBoundingClientRect();
        const xpath = getXPath(e.target);
        
        const elementInfo = {
          tagName: e.target.tagName,
          className: e.target.className,
          id: e.target.id,
          text: e.target.textContent.slice(0, 50),
          xpath: xpath
        };
        
        const position = {
          x: rect.left + rect.width,
          y: rect.top
        };
        
        window.parent.postMessage({ 
          type: 'elementClicked',
          elementInfo,
          position,
          xpath
        }, '*');
        
        e.preventDefault();
      });
    `;

    // Inject the counter styles into iframe head
    const style = document.createElement('style');
    style.textContent = `
      .comment-counter {
        transition: all 0.2s ease;
      }
    `;
    iframe.contentDocument.head.appendChild(style);

    (iframe.contentWindow as any).eval(script);
  };

  const getIframeLoadHandler = () => {
    return () => {
      const localComments = JSON.parse(localStorage.getItem('comments_' + emailReview?.id) || 'null');
      const allComments = { ...(emailReview?.content?.comments || {}) };

      // Merge local comments with existing comments
      if (localComments) {
        Object.entries(localComments).forEach(([xpath, comments]) => {
          if (!allComments[xpath]) {
            allComments[xpath] = [];
          }
          allComments[xpath].push(...(comments as { content: string; xpath: string; position: { x: number; y: number; }; }[]));
        });
      }

      handleIframeLoad(allComments);
    };
  };

  return (
    <div
      className={(!isMobile && showDesktopVersion ? 'editor-preview-desktop' : 'editor-preview-mobile') + ' '}
      style={{
        backgroundColor: styles.editorColor,
        color: styles.fontColor,
        borderRadius: showDesktopVersion && !isMobile ? styles.borderRadius : '',
        border: styles.borderWidth && showDesktopVersion && !isMobile ? `${styles.borderWidth}px solid ${styles.borderColor}` : '',
      }}
    >
      {showDesktopVersion && !isMobile
        ? (
          <DesktopEmailPreview
            state={emailReview.draft}
            continueEditing={null}
            ref={iframeRef}
            onLoad={getIframeLoadHandler()}
          />
        )
        : (
          <MobileEmailPreview
            state={emailReview.draft}
            continueEditing={null}
            ref={iframeRef}
            onLoad={getIframeLoadHandler()}
          />
        )}
    </div>
  );
};

export default CodeEditorPreview;
