import { DEFAULT_REPORT_VIEW_TAB } from '@/lib/constants';
import PersistentStorage from '@/services/storage';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

export enum SCREENS {
  OVERVIEW = 'overview',
  CHAT = 'chat',
  ASSETS = 'assets',
  REPORTS = 'reports',
}

export const defaultScreen = SCREENS.OVERVIEW;

export function useAppState() {
  const [session, loaded] = useLiveQuery(
    async () => {
      let _session = await PersistentStorage.getItem();
      return [_session, true];
    },
    [],
    [null, false],
  );

  const [isLoadingToken, setIsLoadingToken] = useState(true);

  const [email, setEmail] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isDemo, setIsDemo] = useState<boolean>(false);

  useEffect(() => {
    if (loaded) {
      if (session) {
        setAccessToken(session.accessToken);
        setEmail(session.email);
        setIsDemo(session.isDemo);
      } else {
        console.log('not session found');
      }
      setIsLoadingToken(false);
    }
  }, [session, loaded]);

  const [userId, setUserId] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notificationsSubscription, setNotificationsSubscription] = useState<Record<string, any> | null>(null);
  const [isInTour, setIsInTour] = useState<boolean>(false);

  const signupAndLogin = {
    isLoadingToken,
    email,
    setEmail,
    accessToken,
    setAccessToken,
    isAuthenticated: Boolean(accessToken),
    setIsLoadingToken,
    isDemo,
    setIsDemo,
  };

  const userAndCustomer = {
    userId,
    setUserId,
    customerId,
    setCustomerId,
    isLoading,
    setIsLoading,
    notificationsSubscription,
    setNotificationsSubscription,
    isInTour,
    setIsInTour,
  };

  const [reportMenuTab, setReportMenuTab] = useState<string>(DEFAULT_REPORT_VIEW_TAB);
  const [screen, setScreen] = useState<SCREENS>(defaultScreen);
  const UI = {
    reportMenuTab,
    setReportMenuTab,
    screen,
    setScreen,
  };

  function resetAll() {
    setAccessToken(null);
    setEmail(null);
    setUserId(null);
    setCustomerId(null);
    setIsLoading(false);
    setReportMenuTab(DEFAULT_REPORT_VIEW_TAB);
    setIsDemo(false);
    setIsInTour(false);
  }

  const [platform, setPlatform] = useState<'ios' | 'android' | 'web'>('web');

  const installed = {
    platform,
    setPlatform,
  };

  return {
    signupAndLogin,
    userAndCustomer,
    resetAll,
    UI,
    installed,
  };
}

const AppState = createContainer(useAppState);

export default AppState;
