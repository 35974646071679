var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MBDrag from '@assets/icons/MBDrag';
import MBEdit from '@assets/icons/MBEdit';
import MBResize from '@assets/icons/MBResize';
import { Box, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Img, Link, Section } from '@react-email/components';
import IdeateState from '@services/state/IdeateState';
import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import styleToJs from 'style-to-js';
var ImageResizeView = function (_a) {
    var _b, _c, _d, _e;
    var selected = _a.selected, editor = _a.editor, props = __rest(_a, ["selected", "editor"]);
    var ideateState = IdeateState.useContainer();
    var _f = (_b = props === null || props === void 0 ? void 0 : props.node) === null || _b === void 0 ? void 0 : _b.attrs, height = _f.height, width = _f.width, src = _f.src, alt = _f.alt;
    var _g = useHover(), hovered = _g.hovered, ref = _g.ref;
    var handler = function (mouseDownEvent) {
        var _a;
        var parent = mouseDownEvent.target.closest('.image-resizer-node');
        var image = (_a = parent === null || parent === void 0 ? void 0 : parent.querySelector('img.postimage')) !== null && _a !== void 0 ? _a : null;
        if (image === null)
            return;
        var startSize = { x: image.clientWidth, y: image.clientHeight };
        var startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
        function onMouseMove(mouseMoveEvent) {
            var ratio = startSize.x / startSize.y;
            var width = startSize.x - startPosition.x + mouseMoveEvent.pageX;
            // Max width constraint
            if (width > 756) {
                width = 756;
            }
            // Dynamic increment based on image width
            var increment = 5;
            // Round to nearest increment
            width = Math.round(width / increment) * increment;
            // Calculate height maintaining aspect ratio
            var height = width / ratio;
            // Ensure minimum dimensions
            if (height <= 0 || isNaN(height)) {
                height = 0;
            }
            if (width <= 0) {
                width = 0;
            }
            // Round height to match increment
            height = Math.round(height / increment) * increment;
            props.updateAttributes({
                width: width,
                height: height,
            });
        }
        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
        }
        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    };
    var handleClickButton = function () {
        ideateState.setAddImageModal(true);
    };
    var imageHasLinkUrl = ((_c = props === null || props === void 0 ? void 0 : props.node) === null || _c === void 0 ? void 0 : _c.attrs.linkurl) && ((_d = props === null || props === void 0 ? void 0 : props.node) === null || _d === void 0 ? void 0 : _d.attrs.linkurl) !== '';
    var imageStyles = {};
    if (String(props.node.attrs['data-roundedcorners']) === 'true') {
        imageStyles.borderRadius = '12px';
    }
    if (String(props.node.attrs['data-border']) === 'true') {
        imageStyles.border = '2px solid black';
    }
    if (props.node.attrs['align'] === 'center') {
        imageStyles.margin = 'auto';
    }
    else if (props.node.attrs['align'] === 'left') {
        imageStyles.marginRight = 'auto';
    }
    else if (props.node.attrs['align'] === 'right') {
        imageStyles.marginLeft = 'auto';
    }
    var sectionStyles = styleToJs(props.node.attrs['data-section-styles']);
    return (React.createElement(NodeViewWrapper, { className: 'image-resizer-node', ref: ref, style: {
            display: 'flex',
            position: 'relative',
        } },
        React.createElement(Box, { className: 'node-actions' },
            React.createElement(Group, { w: 64, mr: 16, gap: 0, align: 'left', style: {
                    visibility: hovered ? 'visible' : 'hidden',
                } },
                React.createElement(Group, { gap: 8 },
                    React.createElement(Box, { h: 24, onClick: handleClickButton, style: { cursor: 'pointer' } },
                        React.createElement(MBEdit, { size: 24, color: '#A0A0A0' })),
                    React.createElement(Box, { h: 24, "data-drag-handle": true, style: { cursor: 'grab' } },
                        React.createElement(MBDrag, { size: 24, color: '#A0A0A0' }))))),
        React.createElement(Section, { className: "".concat(selected && ideateState.editorIsFocused ? ' tiptap-image-view-selected' : ''), style: sectionStyles },
            imageHasLinkUrl
                ? (React.createElement(Link, { href: (_e = props === null || props === void 0 ? void 0 : props.node) === null || _e === void 0 ? void 0 : _e.attrs.linkurl, onClick: function (e) { return e.preventDefault(); } },
                    React.createElement(Img, { style: imageStyles, height: height, width: width, src: src, alt: alt || props.node.attrs.title, className: 'postimage link', onClick: handleClickButton })))
                : React.createElement(Img, { style: imageStyles, height: height, width: width, src: src, alt: alt || props.node.attrs.title, className: 'postimage link', onClick: handleClickButton }),
            React.createElement(Box, { onMouseDown: handler, style: {
                    display: hovered ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 40,
                    height: 40,
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    backgroundColor: '#FFF',
                    border: '1px solid #E7E7E7',
                    borderRadius: '4px',
                    boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
                    cursor: 'grab',
                    opacity: editor.isEditable ? 1 : 0
                } },
                React.createElement(MBResize, { size: 24 })))));
};
export default ImageResizeView;
