var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MBDrag from '@assets/icons/MBDrag';
import MBEdit from '@assets/icons/MBEdit';
import { Box, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Button, Section, Text } from '@react-email/components';
import IdeateState from '@services/state/IdeateState';
import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import styleToJs from 'style-to-js';
var ButtonView = function (_a) {
    var editor = _a.editor, node = _a.node, getPos = _a.getPos, selected = _a.selected;
    var _b = useHover(), hovered = _b.hovered, ref = _b.ref;
    var ideateState = IdeateState.useContainer();
    var handleClickButton = function (e) {
        e.preventDefault();
        editor.commands.setNodeSelection(getPos());
        ideateState.setAddButtonModal(true);
    };
    var sectionStyles = {
        textAlign: node.attrs.align,
        padding: '12px 50px',
    };
    if (editor.storage.isMobile) {
        sectionStyles.padding = '12px 20px';
    }
    return (React.createElement(NodeViewWrapper, { ref: ref, className: "tiptap-button-view ".concat(selected && ideateState.editorIsFocused ? ' tiptap-button-view-selected' : ''), style: {
            display: 'flex',
            position: 'relative',
        } },
        React.createElement(Box, { className: 'node-actions' },
            React.createElement(Group, { w: 80, gap: 0, align: 'flex-start', style: {
                    visibility: hovered ? 'visible' : 'hidden',
                } },
                React.createElement(Group, { gap: 8 },
                    React.createElement(Box, { h: 24, onClick: handleClickButton, style: { cursor: 'pointer' } },
                        React.createElement(MBEdit, { size: 24, color: '#A0A0A0' })),
                    React.createElement(Box, { h: 24, "data-drag-handle": true, style: { cursor: 'grab' } },
                        React.createElement(MBDrag, { size: 24, color: '#A0A0A0' }))))),
        React.createElement(Section, { style: sectionStyles },
            React.createElement(Button, __assign({}, node === null || node === void 0 ? void 0 : node.attrs, { contentEditable: false, style: __assign(__assign({}, styleToJs(node.attrs['data-button-styles'])), { backgroundColor: node.attrs['data-bg-color'], color: node.attrs['data-text-color'], width: node.attrs['width'], boxSizing: 'border-box' }), onClick: handleClickButton }),
                React.createElement(Text, { style: __assign(__assign({}, styleToJs(node.attrs['data-text-styles'])), { color: node.attrs['data-text-color'] }) }, node === null || node === void 0 ? void 0 : node.attrs.description)))));
};
export default ButtonView;
