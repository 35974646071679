import { genericAuthRequest } from '@/services/api/base';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { GetTokenOptions } from 'firebase/messaging';

import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
let log = console.log.bind(console);

export const initNotifications = async (accessToken: string, actionPerformedListenerFn: (PushNotificationSchema) => void) => {
  // First, remove any existing listeners to avoid duplicates
  await PushNotifications.removeAllListeners();

  // Set up all listeners BEFORE requesting permissions
  PushNotifications.addListener('registration', async (token: Token) => {
    log('Capacitor registration success');
    try {
      // Get the FCM token instead of using Capacitor's token
      const fcmToken = await FirebaseMessaging.getToken();
      log('FCM Token:', fcmToken.token);

      await upsertSubscriptionOnBackend(accessToken, fcmToken.token);
      log('Token successfully saved to backend');
    } catch (error) {
      log('Failed to save token to backend:', error);
    }
  });

  PushNotifications.addListener('registrationError', (error: any) => {
    log('Push notification registration error:', error);
  });

  PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
    log('pushNotificationReceived');
  });

  PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
    log('pushNotificationActionPerformed', action);
    actionPerformedListenerFn(action.notification);
  });

  // Add a small delay to ensure listeners are properly set up
  await new Promise(resolve => setTimeout(resolve, 1000));

  try {
    log('Starting permission request...');
    const permissionResult = await PushNotifications.requestPermissions();
    log('Permission result:', permissionResult);

    if (permissionResult.receive === 'granted') {
      log('Attempting to register...');
      try {
        const token = await PushNotifications.register();
        log('Register call completed', token);
      } catch (registerError) {
        log('Registration failed:', registerError);
      }
    } else {
      log('Push notification permission was denied:', permissionResult);
      return;
    }
  } catch (error) {
    log('Error during permission request or registration:', error);
  }
};

// Deletes firebase token on Android and unregister APNS on iOS
export const stopNotifications = async (accessToken: string) => {
  PushNotifications.unregister();
  await unsubscribeUserFromPushService(accessToken);
};

const unsubscribeUserFromPushService = async (accessToken: string) => {
  try {
    log('DELETE FROM DB .User is not subscribed');
    await genericAuthRequest(accessToken, 'DELETE', '/notifications');
  } catch (error) {
    log('Update Subscription failed', error);
  }
};

const upsertSubscriptionOnBackend = async (
  accessToken: string,
  token: string,
) => {
  try {
    await genericAuthRequest(accessToken, 'POST', '/notifications', { subscription: token });
  } catch (error) {
    log('Update Subscription failed', error);
  }
};
