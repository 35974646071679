import { displayErrorNotification } from '@/components/display/notifications';
import MailberryTable from '@/mailberry/MailberryTable';
import { useExportDeliverySubscribers, useGetDeliverySubscribers, useGetDeliverySubscribersCount } from '@/services/api/delivery';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import { BULK_ACTIONS, demoEmail } from '@lib/constants';
import { DELIVERY_SUBSCRIBER_FILTERS } from '@lib/types';
import { exportSubscribersCSV, getClientTimezone } from '@lib/utils';
import MBEmptyUnsubscribers from '@mailberry/Empty/MBEmptyUnsubscribers';
import { MBTableSkeleton } from '@mailberry/Table/Skeleton/MBTableSkeleton';
import { Box, Button, Checkbox, Group, Loader, Pagination, ScrollArea, Select, Space, Table, Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { usePagination } from '@services/hooks/usePagination';
import AppState from '@services/state/AppState';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fakeRecipients, shuffleArray } from './Recipients';

export const UnsubscribedWrapper = ({ delivery, isLoadingDraft }) => {
  const exportDeliverySubscribers = useExportDeliverySubscribers();
  const { isLoading: isLoadingCount, data: subscribersCount } = useGetDeliverySubscribersCount(delivery?.id, DELIVERY_SUBSCRIBER_FILTERS.UNSUBSCRIBED);

  const { totalPages, page, setPage, limit, offset } = usePagination(10, subscribersCount?.total);

  const { isLoading, data: deliverySubscribers } = useGetDeliverySubscribers(delivery?.id, limit, offset, DELIVERY_SUBSCRIBER_FILTERS.UNSUBSCRIBED);

  const pagination = { totalPages, page, setPage, limit, offset };

  return (
    <Unsubscribed
      exportMutation={exportDeliverySubscribers}
      delivery={delivery}
      subscribersCount={subscribersCount}
      deliverySubscribers={deliverySubscribers}
      isLoading={isLoadingCount || isLoading || isLoadingDraft}
      pagination={pagination}
    >
    </Unsubscribed>
  );
};

export default UnsubscribedWrapper;

export function Unsubscribed({ exportMutation, delivery, subscribersCount, deliverySubscribers, isLoading, pagination }) {
  const { totalPages, page, setPage } = pagination;
  const timeZone = getClientTimezone();

  const [subscribers, _setSubscribers] = useState([]);

  function setSubscribers(d) {
    if (d?.length) {
      _setSubscribers(
        d.map(i => ({
          id: i.id,
          email: (
            <Link
              to={'#'}
              style={{
                textDecoration: 'none',
                color: 'black',
              }}
            >
              {i.email}
            </Link>
          ),
          sentToSGAt: formatDateToUserTimezone(i.sentToSGAt, timeZone),
        })),
      );
    }
  }
  const appState = AppState.useContainer();
  useEffect(() => {
    if (appState.signupAndLogin.email === demoEmail) {
      console.log(deliverySubscribers);
      return setSubscribers(shuffleArray(fakeRecipients));
    }

    setSubscribers(deliverySubscribers);
  }, [deliverySubscribers]);

  if (isLoading) {
    return (
      <Box>
        <ScrollArea style={{ height: 'calc(80vh - 200px)' }}>
          <MBTableSkeleton title={'Unsubscribes'} manySelects={0} checkbox rows={[{ label: 'Email' }, { label: 'Unsubscribed At' }]} />;
        </ScrollArea>
      </Box>
    );
  }

  return (
    <Box>
      <ScrollArea>
        <Title order={2} m={16}>{appState.signupAndLogin.email === demoEmail ? 104 : subscribersCount?.total} Unsubscribes</Title>
        {subscribers.length
          ? (
            <MailberryTable
              headers={['Subscriber', 'Unsubscribed At']}
              tableData={subscribers.map(s => ({ email: s.email, sentToSGAt: s.sentToSGAt }))}
              total={subscribersCount?.total}
            />
          )
          : null}

        {!subscribers.length && <MBEmptyUnsubscribers />}
        <Space h={24} />
        <Group justify='center'>
          <Pagination
            display={totalPages > 1 ? 'flex' : 'none'}
            value={page}
            onChange={setPage}
            total={totalPages}
            defaultValue={1}
            color='mbblue'
          />
        </Group>
      </ScrollArea>
    </Box>
  );
}
