import { formatDate } from '@/lib/utils';
import MBEmptySubscribers from '@/mailberry/Empty/MBEmptySubscribers';
import MailberryTable from '@/mailberry/MailberryTable';
import { useGetAllSubscribers, useGetAllSubscribersCount } from '@/services/api/subscriber';
import { usePagination } from '@/services/hooks/usePagination';
import { SUBSCRIBER_STATUS } from '@/sharedTypes';
import { ActionIcon, Box, Group, Loader, Pagination, Space, TextInput, Title } from '@mantine/core';
import { IconFilter, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Subscriber {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  createdAt: string;
}

interface Segment {
  id: string;
  name: string;
  description?: string;
}

const SegmentSubscribers = () => {
  const { segmentId } = useParams();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const { data: subscribersTotal, isLoading: isLoadingTotal } = useGetAllSubscribersCount({
    segmentIds: segmentId ? [segmentId] : [],
    status: [SUBSCRIBER_STATUS.ACTIVE],
    ...(filterValue ? { filterEmail: filterValue } : {}),
  });

  const { totalPages, page, setPage, limit, offset } = usePagination(25, subscribersTotal?.total);

  const { isLoading: isLoadingSubscribers, data: subscribers } = useGetAllSubscribers({
    segmentIds: segmentId ? [segmentId] : [],
    status: [SUBSCRIBER_STATUS.ACTIVE],
    limit: limit,
    offset: offset,
    ...(filterValue ? { filterEmail: filterValue } : {}),
  });

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Group justify='space-between' align='center' m={16}>
        <Title order={2}>{subscribersTotal?.total ?? 0} Subscribers</Title>
        <Group gap='xs' wrap='nowrap'>
          {showFilter && (
            <TextInput
              placeholder='Filter by email'
              value={filterValue}
              onChange={e => setFilterValue(e.target.value)}
              size='md'
              maw={400}
              rightSection={filterValue && (
                <ActionIcon variant='subtle' onClick={() => setFilterValue('')}>
                  <IconX size={16} />
                </ActionIcon>
              )}
            />
          )}
          <ActionIcon
            variant={showFilter ? 'filled' : 'subtle'}
            onClick={() => setShowFilter(!showFilter)}
            color={showFilter ? 'mbblue' : 'gray'}
            size='lg'
          >
            <IconFilter size={20} />
          </ActionIcon>
        </Group>
      </Group>
      {(isLoadingSubscribers || isLoadingTotal)
        ? (
          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Loader />
          </Box>
        )
        : subscribers?.length
        ? (
          <MailberryTable
            headers={['Subscriber', 'Created at']}
            tableData={subscribers.map(s => ({
              email: s.email,
              createdAt: formatDate(s.createdAt),
            }))}
            total={subscribersTotal?.total}
            onRowClick={email => navigate(`/subscriber/${email}`)}
          />
        )
        : <MBEmptySubscribers />}
      <Space h={24} />
      <Group justify='center'>
        <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
      </Group>
    </Box>
  );
};

export default SegmentSubscribers;
