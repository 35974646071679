import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetSequence, useGetSequenceSteps } from '@/services/api/sequences';
import { SEQUENCE_TYPE } from '@/sharedTypes';
import { Badge, Container, Group, Loader, Stack, Text, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { LiveSequenceBase } from './LiveSequenceWrapper';

export default function SequenceDetail() {
  const { sequenceId } = useParams<{ sequenceId: string; }>();

  const { data: sequence, isLoading } = useGetSequence(
    { sequenceId },
  );
  const { data: steps, isLoading: stepsIsLoading } = useGetSequenceSteps({ sequenceId }, { enabled: !!sequenceId });

  if (isLoading) {
    return (
      <Container size='lg' py='xl'>
        <Stack align='center' justify='center' style={{ minHeight: '50vh' }}>
          <Loader size='xl' />
          <Text>Loading sequence details...</Text>
        </Stack>
      </Container>
    );
  }

  if (!sequence) {
    return <MBEmptyGeneric />;
  }
  return (
    <Stack gap='lg' style={{ display: 'flex' }}>
      <Group justify='space-between' pt={20} px={8}>
        <Title order={1}>
          {sequence.name}
        </Title>
        {!sequence.enabled && (
          <Badge color='red' variant='light' size='lg'>
            Disabled
          </Badge>
        )}
      </Group>

      {steps && (
        <LiveSequenceBase
          steps={steps}
          sequence={{ ...sequence, triggerType: '' }}
          sequenceType={SEQUENCE_TYPE.SEGMENT}
          segmentName={sequence.name}
        />
      )}
    </Stack>
  );
}
