import { demoEmail } from '@/lib/constants';
import { SUBSCRIBER_STATUS } from '@/lib/types';
import { useGetAverageOpenRate } from '@/services/api/account';
import { useGetAllSubscribersCount } from '@/services/api/subscriber';
import AppState from '@/services/state/AppState';
import { BehaviorRule, FieldRule, SEGMENT_ORIGIN } from '@/sharedTypes';
import { Container, Group, Loader, Stack, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useGetAllSegmentsWithCount } from '../../../services/api/segments';
import { MBAudienceOption } from './Options/MBAudienceOption';
export default function SegmentsPage() {
  const { data: segments, isLoading, error } = useGetAllSegmentsWithCount(Object.keys(SEGMENT_ORIGIN) as SEGMENT_ORIGIN[]);
  const { data: subscribersTotal, isLoading: isLoadingTotal } = useGetAllSubscribersCount({
    segmentIds: [],
    status: [SUBSCRIBER_STATUS.ACTIVE],
  });
  const { data: averageOpenRate } = useGetAverageOpenRate();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Container size='lg' py='xl'>
        <Group justify='center' my='xl'>
          <Loader size='lg' />
        </Group>
      </Container>
    );
  }
  const appState = AppState.useContainer();
  return (
    <Container py='xl' w={'100%'} style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Group justify='space-between' mb='xl'>
        <Title order={1}>Audience</Title>
      </Group>

      <Stack gap='md' w={'100%'} style={{ alignItems: 'center' }}>
        <MBAudienceOption
          title={'All Subscribers'}
          selected={segments && segments.length === 0}
          onClick={() => {
            navigate('/subscribers');
          }}
          averageOpenRate={appState.signupAndLogin.email === demoEmail ? 56 : (Math.round(averageOpenRate?.averageOpenRate || 0))}
          subscriberCount={appState.signupAndLogin.email === demoEmail ? 113656 : (subscribersTotal?.total || '-')}
        />

        {segments?.map((segment, i) => (
          <MBAudienceOption
            key={`segmentwithcount${i}`}
            title={segment.name}
            selected={segments?.some(s => s.id === segment.id)}
            onClick={() => {
              navigate('/subscribers/' + segment.id);
            }}
            averageOpenRate={Math.round(segment.averageOpenRate)}
            subscriberCount={segment.count}
            fieldRule={segment.fieldRule as FieldRule[]}
            behaviorRule={segment.behaviorRule as BehaviorRule[]}
            parentSegments={segment.parentSegments as { id: string; name: string; origin: SEGMENT_ORIGIN; }[]}
            origin={segment.origin as SEGMENT_ORIGIN}
          />
        ))}
      </Stack>
    </Container>
  );
}
