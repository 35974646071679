var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MBDrag from '@assets/icons/MBDrag';
import MBEdit from '@assets/icons/MBEdit';
import { Box, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Hr, Section } from '@react-email/components';
import IdeateState from '@services/state/IdeateState';
import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import styleToJs from 'style-to-js';
var DividerView = function (_a) {
    var editor = _a.editor, getPos = _a.getPos, selected = _a.selected, props = __rest(_a, ["editor", "getPos", "selected"]);
    var ideateState = IdeateState.useContainer();
    var _b = useHover(), hovered = _b.hovered, ref = _b.ref;
    var handleClickButton = function () {
        editor.commands.setNodeSelection(getPos());
        ideateState.setAddDividerModal(true);
    };
    var sectionStyles = __assign({}, styleToJs(props.node.attrs['data-section-styles']));
    if (editor.storage.isMobile) {
        sectionStyles.padding = '12px 20px';
    }
    return (React.createElement(NodeViewWrapper, { ref: ref, style: {
            display: 'flex',
            position: 'relative',
        } },
        React.createElement(Box, { className: 'node-actions' },
            React.createElement(Group, { w: 80, gap: 0, align: 'left', style: {
                    visibility: hovered ? 'visible' : 'hidden',
                } },
                React.createElement(Group, { gap: 8 },
                    React.createElement(Box, { h: 24, onClick: handleClickButton, style: { cursor: 'pointer' } },
                        React.createElement(MBEdit, { size: 24, color: '#A0A0A0' })),
                    React.createElement(Box, { h: 24, "data-drag-handle": true, style: { cursor: 'grab' } },
                        React.createElement(MBDrag, { size: 24, color: '#A0A0A0' }))))),
        React.createElement(Section, { style: sectionStyles },
            React.createElement(Hr, { onClick: handleClickButton, className: "".concat(selected && ideateState.editorIsFocused ? 'tiptap-image-view-selected' : ''), style: styleToJs(props.node.attrs['data-hr-styles']) }))));
};
export default DividerView;
