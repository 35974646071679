import { DELIVERY_STATUS, Web } from '@/sharedTypes';
import { DELIVERY_SUBSCRIBER_FILTERS, IPaginationRequest, IQueryKey } from '@lib/types';
import { UseQueryOptions } from '@tanstack/react-query';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, useMailberryMutate, useMailberryQuery } from './base';

export function useGetDeliveryLinks(id: string) {
  return useMailberryQuery<Web.Response.ClickedLink[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/${id}/links`);
    },
    queryKey: [id],
    options: { refetchInterval: 10000 },
  });
}

export function useGetDeliverySubscribersWhoClickedALink(deliveryLinkId: string, limit: number, offset: number) {
  return useMailberryQuery<Web.Response.SubscriberWhoClickedALink[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/links/${deliveryLinkId}/clicks`, { limit, offset });
    },
    queryKey: ['link', deliveryLinkId, limit, offset],
    options: { refetchInterval: 30000 },
  });
}

export function useGetDeliverySubscribersWhoClickedALinkCount(deliveryLinkId: string) {
  return useMailberryQuery<Web.Response.CountTotal>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/links/${deliveryLinkId}/clicks/count`);
    },
    queryKey: ['count', deliveryLinkId],
    options: { refetchInterval: 30000 },
  });
}

export function useGetDeliverySubscribers(id: string, limit: number, offset: number, filterBy?: DELIVERY_SUBSCRIBER_FILTERS) {
  return useMailberryQuery<Web.Response.DeliverySubscriber[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/${id}/subscribers`, { filterBy, limit, offset });
    },
    queryKey: [id, filterBy, offset],
    options: { refetchInterval: 10000 },
  });
}

export function useExportDeliverySubscribers(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<any, Web.Request.ExportDeliverySubscribers>({
    f: (accessToken, userData) => {
      const { filterBy, limit, offset, selectedIds } = userData;
      return genericAuthRequest(accessToken, 'post', `/delivery/${userData.id}/export/subscribers?filterBy=${filterBy}`, {
        limit,
        offset,
        selectedIds,
      });
    },
    options: { onSuccess, onError },
    reloadTokenType: ReloadTokenType.Draft,
  });
}

export function useGetDeliverySubscribersCount(id: string, filterBy?: DELIVERY_SUBSCRIBER_FILTERS) {
  return useMailberryQuery<Web.Response.CountTotal>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/${id}/subscribers/count`, { filterBy });
    },
    queryKey: [id, filterBy],
    options: { refetchInterval: 30000 },
  });
}

type GetScheduleDeliveries = {
  segmentName: string;
};

export function useGetScheduleDeliveries(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<Web.Response.DraftDelivery[], GetScheduleDeliveries>({
    f: (accessToken, userData) => {
      const { segmentName } = userData;
      return genericAuthRequest(accessToken, 'get', `/delivery/schedule${segmentName ? '?segmentName=' + segmentName : ''}`);
    },
    options: { onSuccess, onError },
    reloadTokenType: ReloadTokenType.UI,
  });
}

// export function useGetDeliveries(userData: {status:DELIVERY_STATUS} & IPaginationRequest, queryKey: IQueryKey) {
//     const { reloadTokens } = ReloadTokens.useContainer();
//     return useMailberryQuery<Web.Response.GetAllDeliveries[]>({
//     f: accessToken => {
//       return genericAuthRequest(accessToken, 'get', `/delivery`, userData);
//     },
//     queryKey: [reloadTokens[ReloadTokenType.Draft], ...queryKey],
//     options: { refetchInterval: 10000 },
//   });
// }

// export function useGetAllDeliveriesCount(userData:  {status:DELIVERY_STATUS} & IPaginationRequest, queryKey: IQueryKey) {
//   const { reloadTokens } = ReloadTokens.useContainer();
//   return useMailberryQuery<Web.Response.CountTotal>({
//     f: accessToken => {
//       return genericAuthRequest(accessToken, 'get', `/delivery/total`, userData);
//     },
//     queryKey: [reloadTokens[ReloadTokenType.Draft], ...queryKey],
//     options: { refetchInterval: 10000 },
//   });
// }
