import { demoEmail } from '@/lib/constants';
import { MBStatsItem } from '@/mailberry/MBStatsItem';
import AppState from '@/services/state/AppState';
import MBClicked from '@assets/icons/MBClicked';
import MBOpen from '@assets/icons/MBOpen';
import MBRecipients from '@assets/icons/MBRecipients';
import MBSentTo from '@assets/icons/MBSentTo';
import MBUnsubscriber from '@assets/icons/MBUnsubscriber';
import { Group, Space } from '@mantine/core';

export function OverviewStats({ delivery, clickRate, openRate }) {
  const appState = AppState.useContainer();

  return (
    <>
      <Space h={20} />
      <Group justify='center'>
        <MBStatsItem icon={<MBRecipients size={24} />} title={'Recipients'} value={appState.signupAndLogin.email === demoEmail ? 112824 : delivery?.sendTo} color={'mbneutrals.9'} />
      </Group>
      <Space h={10} />
      <Group className='tour-report-stats' justify='center' gap={48}>
        {/* {delivery?.alreadySent && <MBStatsItem icon={<MBSentTo size={24} />} title={'Sent to'} value={delivery?.alreadySent} color={'black'} />} */}
        <MBStatsItem icon={<MBOpen size={24} />} title={'Open Rate'} value={appState.signupAndLogin.email === demoEmail ? '56%' : `${openRate}%`} color={'black'} />
        <MBStatsItem icon={<MBClicked size={24} />} title={'Click Rate'} value={appState.signupAndLogin.email === demoEmail ? '40%' : `${clickRate}%`} color={'black'} />
      </Group>
      <Space h={10} />
      <Group justify='center'>
        <MBStatsItem icon={<MBUnsubscriber size={24} />} title={'Unsubscribers'} value={appState.signupAndLogin.email === demoEmail ? 104 : delivery?.unsubscribe} color={'mbneutrals.9'} />
      </Group>
      <Space h={20} />
    </>
  );
}
