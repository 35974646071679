import { buildBehaviorRulesSentence, buildFieldRulesSentence, buildSubscriberOriginSentence } from '@lib/utils';
import { Text } from '@mantine/core';
import AppState from '@services/state/AppState';

const SegmentCompositionSentence = ({ parentSegments, fieldRule, behaviorRule }) => {
  const appState = AppState.useContainer();
  return (
    <>
      {parentSegments && (
        <Text fw={700} lh={1}>
          {parentSegments.length > 0
            ? buildSubscriberOriginSentence(parentSegments)
            : 'All subscribers'}
        </Text>
      )}
      {fieldRule?.length > 0 && <Text fw={700} lh={1}>{buildFieldRulesSentence(fieldRule)}</Text>}
      {behaviorRule?.length > 0 && (
        <Text fw={700} lh={1}>
          {buildBehaviorRulesSentence(behaviorRule)}
        </Text>
      )}
    </>
  );
};
export default SegmentCompositionSentence;
