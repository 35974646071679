import { Group, Table } from '@mantine/core';
import React from 'react';
import styles from './MailberryTable.module.css'; // Import the styles

interface MailberryTableProps {
  headers: string[];
  tableData: any[];
  total: number;
  onRowClick?: (id: string) => void;
}

export default function MailberryTable({
  headers,
  tableData,
  total,
  onRowClick,
}: MailberryTableProps) {
  return (
    <Table horizontalSpacing='xl' verticalSpacing='md' highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          {headers.map((header, id) => {
            return (
              <Table.Th key={`header-${id}-${header}`}>
                <Group gap={5}>
                  {header}
                </Group>
              </Table.Th>
            );
          })}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {tableData?.length
          ? (
            tableData.map(dataItem => (
              <Table.Tr
                key={dataItem.email}
                className={styles.tableRow}
                onClick={() => onRowClick?.(dataItem.email)}
                style={{ cursor: onRowClick ? 'pointer' : 'default' }}
              >
                {Object.keys(dataItem).map((field, index) => {
                  return (
                    <Table.Td key={`cell-${index}-${dataItem[field]}`} className={styles.rowItem}>
                      {dataItem[field]}
                    </Table.Td>
                  );
                })}
              </Table.Tr>
            ))
          )
          : (
            <Table.Tr>
              <Table.Td colSpan={headers.length} style={{ textAlign: 'center' }}>
                No content available
              </Table.Td>
            </Table.Tr>
          )}
      </Table.Tbody>
    </Table>
  );
}
