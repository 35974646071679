import { useGetDemoAccount, useRestartDemo } from '@/services/api/demo/account';
import ReloadTokens, { ReloadTokenType } from '@/services/state/ReloadTokensState';
import { Box, Button, Stack } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import SectionTitle from '@/components/common/SectionTitle';
import AccountInfo from '../AccountInfo';
import DownloadAppBanner from '../DownloadAppBanner';
import OverallStats from '../OverallStats';
import DemoDeliveriesList from './DemoDeliveriesList';

export default function DemoDashboard() {
  const { data, isLoading: isLoadingUser } = useGetDemoAccount();

  const navigate = useNavigate();
  const restartDemo = useRestartDemo();
  const { setReloadToken } = ReloadTokens.useContainer();

  const resetDemo = () => {
    restartDemo.mutateAsync({})
      .then(() => {
        setReloadToken(ReloadTokenType.Review);
        localStorage.removeItem('tourCompleted');
        navigate('/demo');
      });
  };
  return (
    <Box h={'100%'} w={'100%'} pos='relative'>
      <Stack gap={0}>
        <AccountInfo account={data?.data} isLoading={isLoadingUser} isDemo />
        <OverallStats account={data?.data} isLoading={isLoadingUser} />
        <SectionTitle title='Campaigns' />
        <DemoDeliveriesList loginCount={data?.data.loginCount} />
        {process.env.NODE_ENV !== 'production' && <Button onClick={resetDemo}>reset demo</Button>}
        <DownloadAppBanner isAuthenticated />
      </Stack>
    </Box>
  );
}
