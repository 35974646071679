import { SEGMENT_ORIGIN } from '@/sharedTypes';
import { Web } from '@/sharedTypes';
import { Segment } from '@prisma/client';
import { UseQueryOptions } from '@tanstack/react-query';
import { genericAuthRequest, useMailberryQuery } from './base';

export function useGetSegments() {
  return useMailberryQuery<Segment[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', '/segment');
    },
    queryKey: ['segments'],
    options: { refetchInterval: 10000 },
  });
}

export function useDeleteSegment(segmentId: string) {
  return useMailberryQuery<void>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'delete', `/segments/${segmentId}`);
    },
    queryKey: [segmentId, 'delete'],
    options: { enabled: false },
  });
}

export function useGetAllSegmentsWithCount(origins?: SEGMENT_ORIGIN[], options?: UseQueryOptions) {
  return useMailberryQuery<Web.Response.SegmentWithCount[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/segment/getAllWithCount`, {
        origins,
      });
    },
    queryKey: [origins.join('')],
    options: { refetchInterval: 30000 },
  });
}
