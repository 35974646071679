import { SUBSCRIBER_STATUS } from '@/lib/types';
import { useGetSubscriber } from '@/services/api/subscriber';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import { getClientTimezone } from '@lib/utils';
import { Badge, Container, Group, Stack, Text, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';

export default function SubscriberDetail() {
  const { email } = useParams();
  const { data: subscriber, isLoading } = useGetSubscriber(email);
  const timeZone = getClientTimezone();

  if (isLoading) {
    return (
      <Container size='lg' py='xl' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Stack>
          <Title order={2}>Loading subscriber details...</Title>
        </Stack>
      </Container>
    );
  }

  if (!subscriber) {
    return (
      <Container size='lg' py='xl' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Stack>
          <Title order={2}>Subscriber not found</Title>
          <Text>No subscriber found with email: {email}</Text>
        </Stack>
      </Container>
    );
  }

  return (
    <Container size='lg' py='xl' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack>
        <Group justify='space-between'>
          <Title order={2}>Subscriber Details</Title>
          <Badge
            color={subscriber.status === SUBSCRIBER_STATUS.ACTIVE
              ? 'mbgreen'
              : subscriber.status === SUBSCRIBER_STATUS.UNSUBSCRIBED
              ? 'mbneutrals'
              : subscriber.status === SUBSCRIBER_STATUS.BOUNCED
              ? 'mbneutrals'
              : subscriber.status === SUBSCRIBER_STATUS.SPAM
              ? 'mbamber'
              : 'mbneutrals'}
          >
            {subscriber.status}
          </Badge>
        </Group>

        <Stack gap='md'>
          <Group>
            <Text fw={700}>Email:</Text>
            <Text>{subscriber.fields.email}</Text>
          </Group>

          <Group>
            <Text fw={700}>Name:</Text>
            <Text>{subscriber.fields.name}</Text>
          </Group>

          <Group>
            <Text fw={700}>Created At:</Text>
            <Text>{formatDateToUserTimezone(subscriber.createdAt, timeZone)}</Text>
          </Group>

          {/* Display all other fields from subscriber.fields */}
          {Object.entries(subscriber.fields)
            .filter(([key]) => !['email', 'name', 'createdAt'].includes(key))
            .map(([key, value]) => (
              <Group key={key}>
                <Text fw={700}>{key.charAt(0).toUpperCase() + key.slice(1)}:</Text>
                <Text>{value}</Text>
              </Group>
            ))}
        </Stack>
      </Stack>
    </Container>
  );
}
