interface ChevronRightProps {
  size?: number;
}

export default function ChevronRight({ size = 32 }: ChevronRightProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24'>
      <path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m9 6l6 6l-6 6' />
    </svg>
  );
}
