import { demoEmail } from '@/lib/constants';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetAllSequences } from '@/services/api/sequences';
import AppState from '@/services/state/AppState';
import { Web } from '@/sharedTypes';
import { Badge, Card, Divider, Group, Skeleton, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export default function SequencesList() {
  const appState = AppState.useContainer();

  // Get both reviews and sent campaigns
  const { data: sequences, isLoading: isLoadingSequences } = useGetAllSequences(
    {},
    [appState?.UI?.screen],
  );

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      <CombinedList
        isLoading={isLoadingSequences}
        items={sequences?.sequences}
      />
    </Stack>
  );
}

interface CombinedListProps {
  isLoading: boolean;
  items: Array<Web.Response.Sequence>;
}

const CombinedList = ({ isLoading, items }: CombinedListProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => (
          <Card key={index} shadow='sm' p='lg' radius='md' withBorder>
            <Skeleton height={30} width='70%' mb='md' />
            <Skeleton height={20} width='90%' mb='md' />
            <Divider />
            <Skeleton height={20} width='50%' mb='md' />
            <Skeleton height={20} radius='md' width='100%' />
          </Card>
        ))}
      </>
    );
  }

  if (!items?.length) {
    return <MBEmptyGeneric />;
  }
  const appState = AppState.useContainer();

  return (
    <>
      {items.map((item: Web.Response.Sequence) => {
        return (
          <Card
            key={item.id}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/sequence/${item.id}`)}
            shadow='sm'
            p='lg'
            radius='md'
            withBorder
          >
            <Group justify='space-between' mb='xs'>
              <Text fw={500} size='lg'>
                {item.name}
              </Text>
              {!item.enabled && (
                <Badge color='red' variant='light'>
                  Disabled
                </Badge>
              )}
            </Group>
            <Divider mt='sm' mb='sm' />
            <Group justify='space-evenly'>
              <Stack align='center' gap={0}>
                <Text size='sm' c='dimmed'>Steps</Text>
                <Text fw={700} size='xl'>{item.stepsCount || 0}</Text>
              </Stack>
              <Stack align='center' gap={0}>
                <Text size='sm' c='dimmed'>Subscribers</Text>
                <Text fw={700} size='xl'>{fakeStats(item.name, item.subscribersCount, appState.signupAndLogin.email) || 0}</Text>
              </Stack>
            </Group>
          </Card>
        );
      })}
    </>
  );
};

export const fakeStats = (name, realSubsCount, email) => {
  if (email !== demoEmail) {
    return realSubsCount;
  }
  if (name === 'Welcome Flow') {
    return 16490;
  }
  if (name === 'Post Purchase') {
    return 82323;
  }
  if (name === 'Cart Abandonment') {
    return 64377;
  }
  return realSubsCount;
};
