import { Stack } from '@mantine/core';
import { CSSProperties } from 'react';
import CommentInput from './CommentInput';
import CommentList from './CommentList';

interface CommentProps {
  isMobile: boolean;
  showInput: boolean;
  showCommentList: boolean;
  comments: (string | { content: string; xpath: string; position: { x: number; y: number; }; })[];
  onClose: () => void;
  onAddComment: (content: string, xpath: string, position: { x: number; y: number; }) => void;
  topPosition: number;
  xpath?: string;
  position?: { x: number; y: number; };
}
const Comments = ({ isMobile, showInput, showCommentList, comments, onClose, onAddComment, topPosition, xpath, position }: CommentProps) => {
  const styles: CSSProperties = {};

  if (isMobile) {
    styles.width = '100%';
    styles.bottom = '0';
    styles.position = 'absolute';
    styles.backgroundColor = 'white';
  } else {
    styles.width = '400px';
    styles.right = '5%';
    styles.position = 'fixed';
    styles.top = `${topPosition}px`;
  }
  return (
    <Stack
      gap={0}
      style={{ boxShadow: '0 0 24px rgba(0,0,0,0.1)', zIndex: 102, ...styles }}
    >
      {showCommentList
        && <CommentList comments={comments} withInput={!showInput} isMobile={isMobile} />}

      <CommentInput
        onClose={onClose}
        handleAddComment={onAddComment}
        isMobile={isMobile}
        xpath={xpath}
        position={position}
      />
    </Stack>
  );
};

export default Comments;
