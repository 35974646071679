import MBMonitor from '@/assets/icons/MBMonitor';
import MBSmartphone from '@/assets/icons/MBSmartphone';
import { parseStyleString } from '@/lib/utils';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import AppState from '@/services/state/AppState';
import { EMAIL_EDITOR_TYPE, reactEmailFooter, replaceTemplateTags, REVIEW_STATUS } from '@/sharedTypes';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { Draft, Review } from '@prisma/client';
import parse, { Element } from 'html-react-parser';
import { useEffect, useMemo, useRef, useState } from 'react';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import Comments from './Comments';
import { CommentsContainer } from './hooks/useComments';
import { MailberryNode } from './MailberryNode';
import ReviewFooter from './ReviewFooter';
import TiptapReactEmailEditor, { addCommentCounters } from './TiptapReactEmail/TiptapReactEmailEditor';

interface ReactEmailReviewCore {
  draftId: string;
  emailReview: Review & { businessAddress: string; editor: EMAIL_EDITOR_TYPE; draft: Draft; };
  emailPreview: Draft;
  onApprove: (emailReviewId: string, content?: Record<string, any>) => void;
  onRequestChanges: (emailReviewId: string, content?: Record<string, any>) => void;
  isLoading: boolean;
}

const ReactEmailReviewCore = ({
  draftId,
  emailReview,
  emailPreview,
  onApprove,
  onRequestChanges,
  isLoading,
}: ReactEmailReviewCore) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const containerRef = useRef<HTMLDivElement>(null);

  const comments = CommentsContainer.useContainer();

  const isMobile = useCheckMobileScreen();
  const [showDesktopVersion, setShowDesktopVersion] = useState(true);

  // exchange messages with tiptap editor
  useEffect(() => {
    const handleMessage = event => {
      const { type, elementInfo, position, xpath } = event.data;
      if (type === 'elementClicked') {
        comments.setCommentPosition(position);
        comments.setXpath(xpath);
        comments.setShowCommentsBox(true);
        comments.toggleCommentInput(elementInfo?.xpath);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // force reload htmlParsed memo  (comments count bubbles)
  useEffect(() => {
    const localComments: null | (string | { content: string; xpath: string; position: { x: number; y: number; }; })[] = JSON.parse(localStorage.getItem('comments_' + emailReview?.id) || 'null');
    if (localComments) {
      comments.setLocalCommentsCounter(prev => prev + 1);
    }
  }, [emailReview?.id]);

  // verify draft has changued and discard old comments
  useEffect(() => {
    if (emailPreview?.id && emailReview?.id && comments.localCommentsCounter !== 0) {
      const bodyLength = emailPreview.body.length;

      const previousBodyLength = localStorage.getItem('bodyLength_' + draftId + '_' + emailReview?.id);

      if (previousBodyLength) {
        // compare
        if (Number(previousBodyLength) !== bodyLength) {
          localStorage.clear();
          comments.setLocalCommentsCounter(prev => 0);
        }
      }
      localStorage.setItem('bodyLength_' + draftId + '_' + emailReview?.id, String(bodyLength));
    }
  }, [emailPreview?.id, emailReview?.id, comments.localCommentsCounter]);

  useEffect(() => {
    if (comments.selectedNodeId && isMobile) {
      setTimeout(() => {
        scrollToSelectedNode(comments.selectedNodeId);
      }, 100);
    }
  }, [isKeyboardOpen, comments.selectedNodeId]);

  const scrollToSelectedNode = (nodeId: string, behavior: ScrollBehavior = 'smooth') => {
    const selectedElement = document.getElementById(nodeId);
    const containerScroll = containerRef.current.parentElement;
    if (selectedElement && containerScroll) {
      const elementRect = selectedElement.getBoundingClientRect();
      const containerRect = containerScroll.getBoundingClientRect();

      const offsetTop = (elementRect.top - (containerRect.height * 0.2)) + containerScroll.scrollTop;
      // const viewportHeight = window.innerHeight;
      // const additionalOffset = isKeyboardOpen ? viewportHeight * 0.4 : 0;

      containerScroll.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const subjectAndPreheaderParsed = useMemo(() => {
    if (emailReview && emailPreview) {
      const formatStringToHtml = (key: string, value: string) => `<span><strong>${key}</strong> ${value}</span>`;

      let body = '';
      if (emailReview.status === REVIEW_STATUS.APPROVED || emailReview?.status === REVIEW_STATUS.CHANGES_REQUESTED) {
        body = formatStringToHtml('Subject line : ', emailReview.subject);

        if (emailReview.preHeader) {
          body += formatStringToHtml('Preview text : ', emailReview.preHeader || '');
        }
      } else {
        body = formatStringToHtml('Subject line : ', emailPreview.subject);
        if (emailPreview.preHeader) {
          body += formatStringToHtml('Preview text : ', emailPreview.preHeader || '');
        }
      }

      body += !isMobile ? '<hr style="margin: 0.5em 0em 1em 0em">' : '<hr>';

      return parse(body, {
        replace: (domNode, position) => {
          if (domNode instanceof Element && domNode.type === 'tag') {
            const uniqueId = `unique_id_${domNode.name}_${position}`;

            if (domNode.attribs.style) {
              const styleDict = parseStyleString(domNode.attribs.style);
              domNode.attribs.style = styleDict as any;
            }

            if (domNode.attribs.class) {
              domNode.attribs.className = domNode.attribs.class;
              delete domNode.attribs.class;
            }

            if (domNode.name === 'hr') {
              return <hr key={uniqueId} {...domNode.attribs} />;
            }

            const isSelected = comments.selectedNodeId === uniqueId;
            const nodeComments = comments.getComments(uniqueId);

            return (
              <div
                onClick={() => comments.toggleCommentInput(uniqueId)}
                className={!isSelected ? 'commentNumber' : ''}
                style={{
                  display: 'flex',
                  position: 'relative',
                  backgroundColor: isSelected ? '#A5B4FC' : '',
                }}
              >
                <MailberryNode
                  id={uniqueId}
                  domNode={domNode}
                />
                {(nodeComments?.length > 0)
                  && (
                    <Button
                      style={{
                        position: 'absolute',
                        right: 0,
                        height: '30px',
                      }}
                    >
                      {nodeComments?.length}
                    </Button>
                  )}
              </div>
            );
          }
        },
      });
    }
  }, [emailReview?.subject, emailReview?.preHeader, emailPreview?.subject, emailPreview?.preHeader, comments.selectedNodeId, comments.localCommentsCounter]);

  if (!emailReview || !emailPreview) {
    return (
      <Group justify='center' style={{ width: '100%' }}>
        <Text size='lg'>Review not found!</Text>
      </Group>
    );
  }

  const footer = reactEmailFooter(emailReview.businessAddress, false);
  const completeEmail = replaceTemplateTags(emailPreview.body, { 'template-footer': footer });

  const tiptapRef = useRef<HTMLDivElement>(null);

  const getIframeLoadHandler = (handleIframeLoad: (c: Record<string, any>) => void) => {
    return () => {
      const localComments = JSON.parse(localStorage.getItem('comments_' + emailReview?.id) || 'null');
      const allComments: Record<
        string,
        Array<{
          content: string;
          xpath: string;
          position: { x: number; y: number; };
        }>
      > = { ...(emailReview?.content as { comments?: Record<string, any[]>; })?.comments || {} };
      // Merge local comments with existing comments
      if (localComments) {
        Object.entries(localComments).forEach(([xpath, comments]) => {
          if (!allComments[xpath]) {
            allComments[xpath] = [];
          }
          allComments[xpath].push(...(comments as { content: string; xpath: string; position: { x: number; y: number; }; }[]));
        });
      }

      handleIframeLoad(allComments);
    };
  };
  return (
    <Box
      style={{
        height: '100%',
      }}
      p={isMobile ? 0 : 4}
      ref={containerRef}
    >
      <Stack
        align='center'
        gap={10}
        w='100%'
      >
        <Group
          justify='center'
          display={isMobile ? 'none' : 'flex'}
        >
          <Button
            onClick={() => {
              setShowDesktopVersion(true);
            }}
            variant={showDesktopVersion ? 'light' : 'white'}
          >
            <MBMonitor size={24} color={showDesktopVersion ? '#3B82F6' : '#6B7280'} />
          </Button>
          <Button
            onClick={() => {
              setShowDesktopVersion(false);
            }}
            variant={!showDesktopVersion ? 'light' : 'white'}
          >
            <MBSmartphone size={24} color={!showDesktopVersion ? '#3B82F6' : '#6B7280'} />
          </Button>
        </Group>

        <Box
          style={{
            width: isMobile ? '100%' : showDesktopVersion ? '760px' : '390px',
            zIndex: 1,
            marginBottom: 48,
          }}
        >
          <Box fz={'xl'} p={'8px 16px'}>
            <Stack gap={0}>
              {subjectAndPreheaderParsed}
            </Stack>
          </Box>
          <Box>
            <TiptapReactEmailEditor
              defaultValue={completeEmail}
              isMobile={isMobile || !showDesktopVersion}
              onLoad={getIframeLoadHandler}
              ref={tiptapRef}
            />
          </Box>
        </Box>
      </Stack>

      <ReviewFooter
        emailReview={emailReview}
        isMobile={isMobile}
        showDesktopVersion={showDesktopVersion}
        localCommentsCounter={comments.localCommentsCounter}
        isLoading={isLoading}
        onRequestChanges={onRequestChanges}
        onApprove={onApprove}
      />

      {comments.showCommentsBox
        && (
          <Comments
            isMobile={isMobile}
            showInput={emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW}
            showCommentList={comments.selectedNodeComments?.length !== 0}
            comments={comments.selectedNodeComments}
            onClose={comments.resetSelection}
            onAddComment={(content, xpath, position) => {
              comments.handleAddComment(content, xpath, position, comments => addCommentCounters(comments, tiptapRef));
            }}
            topPosition={containerRef.current?.parentElement.parentElement.parentElement.parentElement.scrollTop || 0 + 100}
            xpath={comments.xpath}
            position={comments.commentPosition}
          />
        )}
    </Box>
  );
};

const WrappedEmailReviewCore = (props: ReactEmailReviewCore) => (
  <CommentsContainer.Provider initialState={{ emailReview: props.emailReview }}>
    <ReactEmailReviewCore {...props} />
  </CommentsContainer.Provider>
);

export default WrappedEmailReviewCore;
