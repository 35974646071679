import { calcAverage } from '@/lib/utils';
import { Box, Group, SemiCircleProgress, Stack, Text, Title } from '@mantine/core';

export function OverviewStats({ delivery }) {
  const openRate = calcAverage(delivery.sendTo, delivery.open);
  const clickRate = calcAverage(delivery.sendTo, delivery.click);

  return (
    <Stack my={12}>
      <Title
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
        ta={'center'}
      >
        {delivery.subject}
      </Title>
      <Group className='tour-report-stats' justify='center' gap={24}>
        <Box>
          <SemiCircleProgress
            fillDirection='left-to-right'
            orientation='up'
            filledSegmentColor='green'
            size={150}
            thickness={12}
            value={openRate}
            label='Opened'
            styles={() => ({
              label: {
                fontSize: '18px',
              },
            })}
          />
          <Text size='48px' fw={700} ta={'center'}>{delivery.open}</Text>
        </Box>
        <Box>
          <SemiCircleProgress
            fillDirection='left-to-right'
            orientation='up'
            filledSegmentColor='green'
            size={150}
            thickness={12}
            value={clickRate}
            label='Clicked'
            styles={() => ({
              label: {
                fontSize: '18px',
              },
            })}
          />
          <Text size='48px' fw={700} ta='center'>{delivery.click}</Text>
        </Box>
      </Group>

      <Group justify='center' gap={24}>
        <Box w={150}>
          <Text size={'lg'} ta={'center'}>Unsubscribed</Text>
          <Text
            size='48px'
            fw={700}
            ta={'center'}
          >
            {delivery.unsubscribe}
          </Text>
        </Box>
        <Box w={150}>
          <Text size={'lg'} ta={'center'}>Bounced</Text>
          <Text
            size='48px'
            fw={700}
            ta={'center'}
          >
            {delivery.bounced}
          </Text>
        </Box>
      </Group>

      {
        /* <Group justify='center' gap={24}>
        <Box>
          <Text size={'lg'} ta={'center'}> Campaigns revenue</Text>
          <Text size="48px" fw={700} ta={'center'}> {delivery.unsubscribe}$</Text>
        </Box>
      </Group> */
      }
    </Stack>
  );
}
