import { Box } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { IconClock } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import classes from './StepVerticalLine.module.css';

const _1day = 1440;

export function VerticalLine() {
  return (
    <div className={classes.container}>
      <div className={classes.verticalLine} />
    </div>
  );
}

export function EmailStepDelayTime({ liveMode, delayInMinutes, sequenceStepId, centerDelayTime = true, hideClockIcon = false, onSave = null }) {
  const _days = Math.floor(delayInMinutes / _1day);
  const _hours = (delayInMinutes % _1day) / 60;

  useEffect(() => {
    setDays(_days);
    setHours(_hours);
  }, [delayInMinutes]);

  const [editTimeEnabled, toggleEditTimeEnabled] = useToggle([false, true]);
  const [days, setDays] = useState(_days);
  const [hours, setHours] = useState(_hours);
  const [loading, setLoading] = useState(false);
  // const state = SequenceComposeState.useContainer();

  const parsedDelay = () => {
    if (days === 0 && hours === 0) {
      return 'Send immediately';
    }

    let r = `Send after `;
    if (days > 0) {
      if (days === 1) {
        r = `${r} ${days} day`;
      } else {
        r = `${r} ${days} days`;
      }

      if (hours > 0) {
        r = `${r} and ${hours} hours`;
      }
      return r;
    } else if (days === 0) {
      if (hours === 1) {
        r = `${r} ${hours} hour`;
      } else {
        r = `${r} ${hours} hours`;
      }
      return r;
    }
  };
  const delay = parsedDelay();

  return (
    <Box
      className={classes.delayContainer}
      h={38}
      style={{ cursor: !liveMode ? 'pointer' : 'inherit', opacity: loading ? 0.7 : 1 }}
      onClick={() => !liveMode && toggleEditTimeEnabled()}
    >
      {!hideClockIcon && (
        <div className={classes.circle}>
          <IconClock size={24} color={'#888'} />
        </div>
      )}
      <div className={classes.m5}>
        <div className={classes.m7}>{delay}</div>
      </div>
    </Box>
  );
}

export function StepVerticalLine({ type = 'solid', isLongLine = false }) {
  return (
    <Box className={classes.verticalLine} h={64}>
      <Box
        className={classes.verticalLine}
        style={{
          '&::before': {
            borderLeftStyle: type,
          },
        }}
      >
        {` `}
      </Box>
    </Box>
  );
}
