var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MBDrag from '@assets/icons/MBDrag';
import MBEdit from '@assets/icons/MBEdit';
import { Box, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Section } from '@react-email/components';
import IdeateState from '@services/state/IdeateState';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
export var FeaturedBoxView = function (_a) {
    var _b, _c, _d;
    var editor = _a.editor, node = _a.node, getPos = _a.getPos, selected = _a.selected;
    var ideateState = IdeateState.useContainer();
    var _e = useHover(), hovered = _e.hovered, ref = _e.ref;
    var handleClickFeaturedBoxComponent = function () {
        // This command doesn't show the "text cursor" so keep it in mind
        // editor.commands.setNodeSelection(getPos());
        // ideateState.setAddFeaturedBoxModal(true);
    };
    var handleEditFeaturedBoxComponent = function () {
        ideateState.setAddFeaturedBoxModal(true);
    };
    var sectionStyles = {
        padding: '12px 50px',
    };
    if (editor.storage.isMobile) {
        sectionStyles.padding = '12px 20px';
    }
    return (React.createElement(NodeViewWrapper, { ref: ref, className: "tiptap-featured-box-view ".concat(selected && ideateState.editorIsFocused ? ' tiptap-featured-box-view-selected' : '') },
        React.createElement(Group, { pos: 'relative', gap: 0, wrap: 'nowrap' },
            React.createElement(Box, { className: 'node-actions' },
                React.createElement(Group, { gap: 0, align: 'left', style: {
                        visibility: hovered ? 'visible' : 'hidden',
                    } },
                    React.createElement(Group, { gap: 8 },
                        React.createElement(Box, { h: 24, onClick: handleEditFeaturedBoxComponent, style: { cursor: 'pointer' } },
                            React.createElement(MBEdit, { size: 24, color: '#A0A0A0' })),
                        React.createElement(Box, { h: 24, "data-drag-handle": true, style: { cursor: 'grab' } },
                            React.createElement(MBDrag, { size: 24, color: '#A0A0A0' }))))),
            React.createElement(Section, { style: sectionStyles },
                React.createElement(Section, __assign({}, node === null || node === void 0 ? void 0 : node.attrs, { onClick: handleClickFeaturedBoxComponent, style: {
                        borderRadius: ((_b = node === null || node === void 0 ? void 0 : node.attrs) === null || _b === void 0 ? void 0 : _b['data-rounded-corners']) ? '12px' : 0,
                        backgroundColor: (_c = node === null || node === void 0 ? void 0 : node.attrs) === null || _c === void 0 ? void 0 : _c['data-bg-color'],
                        color: (_d = node === null || node === void 0 ? void 0 : node.attrs) === null || _d === void 0 ? void 0 : _d['data-text-color'],
                    } }),
                    React.createElement(NodeViewContent, { as: 'div' }))))));
};
