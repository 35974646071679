import TabsMenu from '@components/screens/TabsMenu';
import DemoTabsMenu from '@components/screens/TabsMenu/DemoTabsMenu';
import { ActionIcon, Box, Group, Image, Stack } from '@mantine/core';
import { ArrowLeftIcon } from 'lucide-react';
import { useState } from 'react';
import { FaGear } from 'react-icons/fa6';
import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import AppState from '../../../services/state/AppState';
import classes from './Navbar.module.scss';

interface INavbar {
  showBackArrow: boolean;
  isMobileView: boolean;
  logoRedirect?: string;
}

export const Navbar = ({ showBackArrow, isMobileView, logoRedirect = '/' }: INavbar) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [previousPath, setPreviousPath] = useState('');
  const hasHistory = navigationType !== 'POP';
  const appState = AppState.useContainer();

  const handleOnBack = () => {
    navigate(-1);
  };

  const handleOpenSettings = () => {
    setPreviousPath(location.pathname);
    if (location.pathname.startsWith('/settings')) {
      if (previousPath) {
        setPreviousPath('');
        navigate(previousPath);
      } else {
        navigate('/');
      }
    } else {
      const redirectTo = appState.signupAndLogin.isDemo ? '/demo/settings' : '/settings';
      navigate(redirectTo);
    }
  };

  const hideNavBar = location.pathname === '/admin-chat' || (location.pathname.startsWith('/review') && !appState.signupAndLogin.accessToken);
  if (hideNavBar) return <></>;

  if (isMobileView) {
    return (
      <Group
        h={'rem(40px)'}
        w='100%'
        justify='space-between'
        align='center'
        className={classes.navbar}
      >
        {showBackArrow
          ? (
            <>
              <ActionIcon pos={'absolute'} onClick={handleOnBack} color='#ffffff' style={{ cursor: 'pointer' }}>
                <ArrowLeftIcon color='#111827' style={{ width: 24, height: 24 }} />
              </ActionIcon>
              <Box w={28} style={{ flexGrow: 1 }}></Box>
            </>
          )
          : <Box w={28} style={{ flexGrow: 1 }}></Box>}
        <Group>
          <Link to={logoRedirect} style={{ alignSelf: 'flex-start' }}>
            <Image src='/assets/mailberry-logo-form.svg' alt='Mailberry logo' h={'30px'} w={'auto'} my={'4px'} />
          </Link>
          {/* <Button onClick={() => navigate('/admin-chat')}>Admin chat</Button> */}
        </Group>
        {appState.signupAndLogin.accessToken
          && (
            <Stack
              gap={0}
              style={{
                flex: 1,
                alignItems: 'end',
              }}
            >
              <Group style={{ cursor: 'pointer', borderRadius: 5, padding: '0 0.5em' }} onClick={handleOpenSettings} h={'100%'}>
                <FaGear size={20} />
              </Group>
            </Stack>
          )}
      </Group>
    );
  } else {
    return (
      <Group
        h={'rem(70px)'}
        w='100%'
        justify='space-between'
        align='center'
        className={classes.navbar}
      >
        <Group style={{ flex: 1 }}>
          {showBackArrow
            ? (
              <ActionIcon onClick={handleOnBack} color='#ffffff' style={{ cursor: 'pointer' }}>
                <ArrowLeftIcon color='#111827' style={{ width: 24, height: 24 }} />
              </ActionIcon>
            )
            : null}
          <Link to={'/'} style={{ alignSelf: 'flex-start', height: 'rem(50px)' }}>
            <Image src='/assets/mailberry-logo-form.svg' alt='Mailberry logo' h={'100%'} w={'auto'} />
          </Link>
        </Group>

        {appState.signupAndLogin.isDemo ? <DemoTabsMenu /> : appState.signupAndLogin.accessToken && <TabsMenu />}
        {appState.signupAndLogin.accessToken
          && (
            <Stack
              gap={0}
              style={{
                flex: 1,
                alignItems: 'end',
              }}
            >
              <Group style={{ cursor: 'pointer', borderRadius: 5, padding: '0 0.5em' }} onClick={handleOpenSettings} h={'100%'}>
                <FaGear size={20} />
              </Group>
            </Stack>
          )}
      </Group>
    );
  }
};
