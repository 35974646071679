import { REVIEW_STATUS } from '@/sharedTypes';
import { Button, Group } from '@mantine/core';
import { DemoReview, Review } from '@prisma/client';

interface ReviewFooterProps {
  emailReview: Review | DemoReview;
  isMobile: boolean;
  showDesktopVersion: boolean;
  localCommentsCounter: number;
  isLoading: boolean;
  isOpen?: boolean;
  currentStep?: number;
  onRequestChanges: (emailReviewId: string, content?: Record<string, any>) => void;
  onApprove: (emailReviewId: string, content?: Record<string, any>) => void;
}

const ReviewFooter = ({
  emailReview,
  isMobile,
  showDesktopVersion,
  localCommentsCounter,
  isLoading,
  isOpen,
  currentStep,
  onRequestChanges,
  onApprove,
}: ReviewFooterProps) => {
  return (
    <Group
      justify='center'
      w={'100%'}
      py={10}
      px={!isMobile && showDesktopVersion ? '24px' : '16px'}
      style={{
        maxWidth: 992,
        position: 'fixed',
        bottom: 'env(safe-area-inset-bottom)',
        backgroundColor: 'white',
        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 100,
      }}
    >
      {emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW
        ? (
          <Group style={{ maxWidth: 728 }} w={'100%'} grow>
            <Button
              className={'tour-third-step'}
              onClick={() => onRequestChanges(emailReview?.id, emailReview?.content as Record<string, any>)}
              w={250}
              style={{ height: '3rem', fontSize: 18 }}
              color={'orange'}
              disabled={!localCommentsCounter}
              loading={isLoading}
            >
              Request changes
            </Button>
            <Button
              w={250}
              className={'tour-fifth-step'}
              onClick={() => onApprove(emailReview?.id, emailReview?.content as Record<string, any>)}
              style={{ height: '3rem', fontSize: 18 }}
              color={'green'}
              disabled={localCommentsCounter > 0 || (isOpen && currentStep === 2) || (isOpen && currentStep === 6)}
              loading={isLoading}
            >
              Approve email as is
            </Button>
          </Group>
        )
        : (
          <Group style={{ maxWidth: 728 }} w={'100%'} grow>
            <Button
              w={250}
              className={'tour-fifth-step'}
              onClick={() => onApprove(emailReview?.id)}
              style={{ height: '3rem', fontSize: 18 }}
              color={'green'}
              disabled={true}
            >
              {emailReview.status.charAt(0).toUpperCase() + emailReview.status.slice(1).toLocaleLowerCase().replaceAll('_', ' ')}
            </Button>
          </Group>
        )}
    </Group>
  );
};

export default ReviewFooter;
