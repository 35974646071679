import { formatEnumMemberName } from '@lib/utils';
import { Box, Flex, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconPlayerPlay } from '@tabler/icons-react';
import classes from './SequenceMetricTriggerCard.module.css';

type SequenceMetricTriggerCardProps = {
  name: string;
};

const SequenceMetricTriggerCard = ({ name }: SequenceMetricTriggerCardProps) => {
  return (
    <Box className={classes.cardContainer}>
      <Flex>
        <Stack className={classes.descriptionContainer} gap={0}>
          <Flex align={'center'}>
            <Box px={16}>
              <ThemeIcon size={'xl'} radius={'md'}>
                <IconPlayerPlay fill='#fff' />
              </ThemeIcon>
            </Box>
            <Stack style={{ flex: 1, borderLeft: '1px solid #727EF2', overflow: 'hidden' }} gap={0} py={16} px={12}>
              <Text span fw={400} color='mbdefault.3' style={{ fontSize: 14 }}>Metric Trigger</Text>
              <Text span fw={700} size={'md'} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{formatEnumMemberName(name)}</Text>
            </Stack>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  );
};

export default SequenceMetricTriggerCard;
