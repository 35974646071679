const createMockIdeateState = () => {
  return {
    useContainer: () => ({
      editorIsFocused: false,
      setAddButtonModal: (value: boolean) => {},
      setAddDividerModal: (value: boolean) => {},
      setAddFeaturedBoxModal: (value: boolean) => {},
      setAddImageModal: (value: boolean) => {},
    }),
  };
};

const IdeateState = createMockIdeateState();
export default IdeateState;
