import { EMAIL_EDITOR_TYPE } from '@/sharedTypes';
import AutoHeightIframe from '@components/common/AutoHeightIframe';
import { DEFAULT_BUSINESS_ADDRESS } from '@lib/constants';
import { Box, Flex, Group, Stack, TypographyStylesProvider } from '@mantine/core';
import AppState from '@services/state/AppState';
import { forwardRef } from 'react';

export function EmailAddressAndUnsubscribe({ businessMailAddress, styles }) {
  return (
    <div className='footer' style={{ color: styles?.fontColor ? styles.fontColor : '#000000' }}>
      <span style={{ color: '#0F69FF', textDecoration: 'underline' }}>{(businessMailAddress?.length > 0 && businessMailAddress) || DEFAULT_BUSINESS_ADDRESS}</span>
      <br />
      No longer want to receive these emails? <a>Unsubscribe</a>
    </div>
  );
}

export function EmailCreatedWithMailberry({ styles }) {
  return (
    <div className='footer' style={{ color: styles?.fontColor ? styles.fontColor : '#000000' }}>
      Powered by{' '}
      <a target={'_blank'} rel='noreferrer' href='https://mailberry.ai'>
        <img
          width='75'
          height='18'
          style={{ width: 75, height: 18, verticalAlign: 'text-bottom', display: 'inline-block', marginLeft: 5 }}
          src='http://d1q3o19jn6nxkf.cloudfront.net/email/mailberry-logo.png'
          alt='Mailberry logo'
        />
      </a>
    </div>
  );
}

export function EmailPreviewFooter({ businessMailAddress, styles }: { businessMailAddress: string; styles?: Record<string, any>; }) {
  const fontColor = styles?.fontColor ? styles.fontColor : '#000000';

  return (
    <div className='footer' style={{ color: fontColor }}>
      <span style={{ color: '#0F69FF', textDecoration: 'underline' }}>{(businessMailAddress?.length > 0 && businessMailAddress) || DEFAULT_BUSINESS_ADDRESS}</span>
      <br />
      No longer want to receive these emails? <a>Unsubscribe</a>
      <br />
      Powered by{' '}
      <a target={'_blank'} rel='noreferrer' href='https://mailberry.ai'>
        <img
          width='75'
          height='18'
          style={{ verticalAlign: 'text-bottom', display: 'inline-block', marginLeft: 5 }}
          src='http://d1q3o19jn6nxkf.cloudfront.net/email/mailberry-logo.png'
          alt='Mailberry logo'
        />
      </a>
    </div>
  );
}

interface MobilePreviewWrapperProps {
  htmlContent: string;
  appState: any;
  editor: string;
  allowBorder?: boolean;
  styles: any;
  onLoad?: () => void;
}

export const MobilePreviewWrapper = forwardRef<HTMLIFrameElement, MobilePreviewWrapperProps>(({ htmlContent, editor, allowBorder = true, styles, onLoad }, ref) => {
  const appState = AppState.useContainer();
  const { backgroundColor, borderColor, borderRadius, borderWidth, fontColor, editorColor } = styles;
  if (editor === EMAIL_EDITOR_TYPE.TIPTAP) {
    return (
      <Box
        h={'80%'}
        w={375}
        style={{
          border: allowBorder && '2px solid #CFCFCF',
          borderRadius: '12px',
          fontSize: '14px',
          textAlign: 'left',
          overflow: 'auto',
        }}
      >
        <Box
          w={'100%'}
          style={{
            // border: allowBorder && `${borderWidth}px solid ${borderColor}`,
            // borderRadius: `${borderRadius}px`,
            // fontSize: '14px',
            // textAlign: 'left',
            // overflow: 'auto',
            // backgroundColor: backgroundColor,
            zIndex: -3,
          }}
        >
          <Box
            className={'editor-preview-mobile'}
            style={{
              backgroundColor: editorColor,
              // border: `${borderWidth}px solid ${borderColor}`,
              border: 'none !important',
              // borderRadius: `${borderRadius}px`,
              fontSize: '14px',
              textAlign: 'left',
            }}
          >
            <TypographyStylesProvider>
              <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 24, color: fontColor }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </TypographyStylesProvider>
          </Box>
          <EmailCreatedWithMailberry styles={styles} />
        </Box>
      </Box>
    );
  }
  if (editor === EMAIL_EDITOR_TYPE.CODE) {
    return (
      <Box
        h={'80%'}
        w={375}
        style={{
          border: allowBorder && '2px solid #CFCFCF',
          borderRadius: '12px',
          fontSize: '14px',
          textAlign: 'left',
          overflow: 'auto',
        }}
      >
        <AutoHeightIframe htmlContent={htmlContent} ref={ref} onLoad={onLoad} />
      </Box>
    );
  }
});

interface MobileEmailPreviewProps {
  state: {
    body: string;
    editor: string;
    styles: any;
  };
  continueEditing?: () => void;
  sendTestButtonOnTheSide?: boolean;
  ref?: any;
  onLoad?: () => void;
}

const MobileEmailPreview = forwardRef<HTMLIFrameElement, MobileEmailPreviewProps>(({ state, continueEditing, sendTestButtonOnTheSide = false, onLoad }, ref) => {
  const appState = AppState.useContainer();
  return (
    <Group
      style={{
        zIndex: 100,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0,
      }}
    >
      <Stack>
        <MobilePreviewWrapper htmlContent={state.body} appState={appState} editor={state.editor} styles={state.styles} onLoad={onLoad} ref={ref} />
      </Stack>
    </Group>
  );
});

export { MobileEmailPreview };

export function MobileEmailPreviewWithoutActions({ htmlContent, editor, styles, ref, onLoad }) {
  const appState = AppState.useContainer();

  const mobilePreviewStyles = {};
  mobilePreviewStyles['scale'] = '0.8';
  mobilePreviewStyles['transformOrigin'] = 'top';
  mobilePreviewStyles['borderRadius'] = 40;
  mobilePreviewStyles['boxShadow'] = '0px 0px 24px rgba(0, 0, 0, 0.1)';
  mobilePreviewStyles['height'] = 779;
  mobilePreviewStyles['width'] = 423;

  return (
    <Group
      style={{
        zIndex: 100,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0,
      }}
    >
      <Stack>
        <Box bg={'white'} style={mobilePreviewStyles}>
          <Stack gap={24} align='center' justify='flex-start' h={'100%'}>
            <Group mt={24}>
              <div style={{ width: 48, height: 8, borderRadius: 4, border: '2px solid #CFCFCF' }}></div>
              <div style={{ width: 8, height: 8, borderRadius: 4, border: '2px solid #CFCFCF' }}></div>
            </Group>
            <MobilePreviewWrapper htmlContent={htmlContent} appState={appState} editor={editor} styles={styles} ref={ref} onLoad={onLoad} />
            <Group>
              <div style={{ width: 48, height: 48, borderRadius: 99, border: '2px solid #CFCFCF' }} />
            </Group>
          </Stack>
        </Box>
      </Stack>
    </Group>
  );
}

interface DesktopEmailPreviewProps {
  state: {
    body: string;
    editor: string;
    styles: any;
  };
  continueEditing?: () => void;
  horizontalOrientation?: boolean;
  onLoad?: () => void;
}

export const DesktopEmailPreview = forwardRef<HTMLIFrameElement, DesktopEmailPreviewProps>(({ state, continueEditing, horizontalOrientation = true, onLoad }, ref) => {
  const { backgroundColor } = state.styles;

  const { editorColor, fontColor, borderWidth, borderColor, borderRadius } = state.styles;

  return (
    <Box pb={40} style={{ position: 'relative', zIndex: 500 }}>
      <Flex
        bg={'white'}
        direction={horizontalOrientation ? 'row' : 'column'}
        align={horizontalOrientation ? 'flex-start' : 'center'}
        justify={horizontalOrientation ? 'center' : 'flex-start'}
        gap={24}
        h={'100%'}
        wrap={'nowrap'}
      >
        <Box px={16} py={24} style={{ backgroundColor, display: 'flex', justifyContent: 'center' }}>
          {state.editor === EMAIL_EDITOR_TYPE.TIPTAP
            && (
              <Box>
                <Box
                  w={760}
                  style={{
                    border: `${borderWidth}px solid ${borderColor}`,
                    borderRadius: `${borderRadius}px`,
                    fontSize: '14px',
                    textAlign: 'left',
                    overflow: 'auto',
                    zIndex: 1,
                  }}
                >
                  <Box className={'editor-preview-desktop'} style={{ backgroundColor: editorColor }}>
                    <TypographyStylesProvider>
                      <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 14, paddingBottom: 24, color: fontColor }} dangerouslySetInnerHTML={{ __html: state.body }} />
                    </TypographyStylesProvider>
                  </Box>
                </Box>
                <EmailCreatedWithMailberry styles={state.styles} />
              </Box>
            )}
          {state.editor === EMAIL_EDITOR_TYPE.CODE
            && (
              <Box
                w={760}
                style={{
                  overflow: 'auto',
                  zIndex: 1,
                  marginBottom: 80,
                }}
              >
                <AutoHeightIframe htmlContent={state.body} ref={ref} onLoad={onLoad} />
              </Box>
            )}
        </Box>
      </Flex>
    </Box>
  );
});
