'use client';
import NonSSRWrapper from '@/components/NonSSRWrapper';
import { NotificationProvider } from '@/components/notifications/NotificationProvider';
import StateProviders from '@/services/state/StateProviders';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Main from './Main';

import { StatusBar, Style } from '@capacitor/status-bar';
import { colorsTuple, createTheme, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { useEffect } from 'react';

import '@/styles/app.css';
import '@/styles/editor.scss';
import '@/styles/normalize.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@reactour/popover/dist/index.css';

type ColorArray = [string, string, string, string, string, string, string, string, string, string];

const MBNeutrals: ColorArray = [
  '#FFFFFF',
  '#F3F3F3',
  '#E7E7E7',
  '#DBDBDB',
  '#CFCFCF',
  '#BFBFBF', // default border color
  '#A0A0A0',
  '#707070',
  '#414141',
  '#1D1D1D',
];

const MBDefault: ColorArray = ['#EAEAEA', '#D5D5D5', '#C2C2C2', '#B0B0B0', '#A0A0A0', '#909090', '#828282', '#757575', '#696969', '#5E5E5E'];

const MBBlueberry: ColorArray = ['#EEE9FE', '#D1C9EC', '#727EF2', '#2F4586', '#2B3554', '#2B3554', '#2B3554', '#2B3554', '#2B3554', '#2B3554'];

const MBBlue: ColorArray = ['#E5F7FF', '#8FDEFF', '#55C8FA', '#26A2CD', '#0F556D', '#0F556D', '#0F556D', '#0F556D', '#0F556D', '#0F556D'];

const MBRed: ColorArray = ['#FFECEC', '#FEA4A4', '#ED5959', '#BD3535', '#801515', '#801515', '#801515', '#801515', '#801515', '#801515'];

const MBGreen: ColorArray = ['#E4FDE4', '#9FDE9D', '#70C56E', '#368834', '#135111', '#135111', '#135111', '#135111', '#135111', '#135111'];

const MBAmber: ColorArray = ['#FFF0D2', '#FED685', '#EFBA51', '#C08309', '#704A00', '#704A00', '#704A00', '#704A00', '#704A00', '#704A00'];

const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    'brand-green': colorsTuple(Array.from({ length: 10 }, (_, index) => '#7ECB7C')),
    'brand-violet': colorsTuple(Array.from({ length: 10 }, (_, index) => '#D1C9EC')),
    'brand-purple': colorsTuple(Array.from({ length: 10 }, (_, index) => '#7981D0')),
    'brand-deep-purple': colorsTuple(Array.from({ length: 10 }, (_, index) => '#24335F')),
    'brand-black': colorsTuple(Array.from({ length: 10 }, (_, index) => '#152228')),
    mbdefault: MBDefault,
    mbblueberry: MBBlueberry,
    mbneutrals: MBNeutrals,
    mbblue: MBBlue,
    mbred: MBRed,
    mbgreen: MBGreen,
    mbamber: MBAmber,
  },
});
const queryClient = new QueryClient();

export default function Page() {
  const configureStatusBar = async () => {
    try {
      await StatusBar.show();
      await StatusBar.setBackgroundColor({ color: '#ffffff' });
      await StatusBar.setStyle({ style: Style.Light });
      await StatusBar.setOverlaysWebView({ overlay: true });
    } catch (err) {
      console.warn('Error configuring status bar:', err);
    }
  };

  useEffect(() => {
    configureStatusBar();
  }, []);

  return (
    <NonSSRWrapper>
      <StateProviders>
        <QueryClientProvider client={queryClient}>
          <MantineProvider theme={theme}>
            <ModalsProvider>
              <NotificationProvider>
                <Notifications position='top-right' limit={5} />
                <Main />
              </NotificationProvider>
            </ModalsProvider>
          </MantineProvider>
        </QueryClientProvider>
      </StateProviders>
    </NonSSRWrapper>
  );
}
