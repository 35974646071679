'use client';
import { Web } from '@/sharedTypes';
import { EMAILS_VIEW_TABS, IQueryKey } from '@lib/types';
import { SequenceDelivery, SequenceStep } from '@prisma/client';
import { MutationOptions, UseQueryOptions } from '@tanstack/react-query';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, RuntimeBackend, useMailberryMutate, useMailberryQuery } from './base';

type IGetAllDraftsRequest = {
  status: EMAILS_VIEW_TABS;
};

export function useGetAllSequences(userData: {}, queryKey: IQueryKey) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<{ sequences: (Web.Response.Sequence & { stepsCount: number; subscribersCount: number; })[]; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/autopilot/sequences`, userData, RuntimeBackend);
    },
    queryKey: [reloadTokens[ReloadTokenType.Draft], ...queryKey],
    options: { refetchInterval: 10000 },
  });
}

export function useGetSequence(userData: { sequenceId: string; }) {
  return useMailberryQuery<Web.Response.Sequence>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/sequences/${userData.sequenceId}`);
    },
    queryKey: [userData.sequenceId],
    options: { refetchInterval: 10000 },
  });
}

export type StepsWithDeliveries = (Omit<SequenceStep, 'sequenceDeliveryId'> & { delivery: SequenceDelivery; })[];

export function useGetSequenceSteps(userData: { sequenceId: string; }, options: UseQueryOptions) {
  return useMailberryQuery<StepsWithDeliveries>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/sequences/${userData.sequenceId}/steps`);
    },
    queryKey: [userData.sequenceId],
    options: { refetchInterval: 100000, ...options },
  });
}

export function useGetAllDraftsCount(userData: IGetAllDraftsRequest, queryKey: IQueryKey) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<Web.Response.CountTotal>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/draft/total`, userData);
    },
    queryKey: [reloadTokens[ReloadTokenType.Draft], ...queryKey],
    options: { refetchInterval: 10000 },
  });
}

export function useGetDraftById(draftId: string) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<Web.Response.IDraftDelivery>({
    f: accessToken => {
      // TODO: Remove this by using the enabled option.
      if (draftId) {
        return genericAuthRequest(accessToken, 'get', `/draft/one/${draftId}`);
      }
    },
    queryKey: [reloadTokens[ReloadTokenType.Draft], draftId],
    options: { refetchInterval: 10000 },
  });
}

export function useGetDraftByIdMutate(options: MutationOptions = {}) {
  return useMailberryMutate<Web.Response.Draft, { draftId: string; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'get', `/draft/one/${userData.draftId}`);
    },
    options: { ...options },
  });
}

// export function useCreateDraft(options: MutationOptions = {}, customErrorHandlers?) {
//   return useMailberryMutate<Web.Response.Draft, Web.Request.CreateDraft>({
//     f: (accessToken, userData): any => {
//       return genericAuthRequest(accessToken, 'post', `/draft/`, userData);
//     },
//     options,
//     customErrorHandlers,
//     reloadTokenType: ReloadTokenType.Draft,
//   });
// }

// export function useUpdateDraft(options: MutationOptions = {}) {
//   return useMailberryMutate<Web.Response.Draft, Web.Request.UpdateDraft>({
//     f: (accessToken, userData): any => {
//       return genericAuthRequest(accessToken, 'put', `/draft/`, userData);
//     },
//     options,
//     reloadTokenType: ReloadTokenType.Draft,
//   });
// }

// export function useDestroyDraft(options?: MutationOptions) {
//   return useMailberryMutate<Web.Response.Draft, Web.Request.DestroyDraft>({
//     f: (accessToken, userData) => {
//       const id = userData.id;
//       delete userData.id;
//       return genericAuthRequest(accessToken, 'delete', `/draft/${id}`);
//     },
//     options,
//     reloadTokenType: ReloadTokenType.Draft,
//   });
// }

// export function useDuplicateDraft(options?: MutationOptions) {
//   return useMailberryMutate<Web.Response.Draft, Web.Request.DuplicateDraft>({
//     f: (accessToken, userData) => {
//       const draftId = userData.draftId;
//       delete userData.draftId;
//       return genericAuthRequest(accessToken, 'post', `/draft/duplicate/${draftId}`);
//     },
//     options,
//     reloadTokenType: ReloadTokenType.Draft,
//   });
// }

// export function useUpdateCampaignName(options?: MutationOptions) {
//   return useMailberryMutate<Web.Response.Draft, Web.Request.UpdateDraftCampaignName>({
//     f: (accessToken, userData) => {
//       const draftId = userData.draftId;
//       delete userData.draftId;
//       return genericAuthRequest(accessToken, 'patch', `/draft/updateCampaignName/${draftId}`, userData);
//     },
//     options,
//     reloadTokenType: ReloadTokenType.Draft,
//   });
// }
