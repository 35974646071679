import { demoEmail } from '@/lib/constants';
import AppState from '@/services/state/AppState';
import MBClicked from '@assets/icons/MBClicked';
import MBOpen from '@assets/icons/MBOpen';
import MBRecipients from '@assets/icons/MBRecipients';
import MBUnsubscriber from '@assets/icons/MBUnsubscriber';
import { Box, Flex, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { clsx } from 'clsx';
import classes from './SequenceEmailLiveCard.module.css';
import { EmailStepDelayTime, VerticalLine } from './StepVerticalLine';

const SequenceEmailLiveCard = ({ subject, sequenceStepId, delayInMinutes, isEmailSelected = false, onClick, delivery, liveMode = false }) => {
  const centerStats = delivery.click === 0 && delivery.unsubscribe === 0 && delivery.open === 0;
  const appState = AppState.useContainer();

  const isDemo = appState.signupAndLogin.email === demoEmail;

  const subjectStats = {
    'Welcome to Rare Beauty!': {
      delivered: 16490,
      open: 14673,
      click: 8567,
      unsubscribe: 10,
    },
    'Here is your gift': {
      delivered: 16490,
      open: 12983,
      click: 7543,
      unsubscribe: 5,
    },
    'You left your beaty in the cart :(': {
      delivered: 64377,
      open: 24988,
      click: 18676,
      unsubscribe: 120,
    },
    'We thought you mind like these minis': {
      delivered: 82323,
      open: 34987,
      click: 23987,
      unsubscribe: 140,
    },
  };

  return (
    <Stack gap={0}>
      <VerticalLine />
      <Box className={clsx(classes.cardContainer, isEmailSelected && classes.isActive)} onClick={onClick}>
        <Box style={{ borderBottom: '1px solid #E0E0E0' }}>
          <EmailStepDelayTime sequenceStepId={sequenceStepId} liveMode={liveMode} delayInMinutes={delayInMinutes} />
        </Box>
        <Flex w={'100%'}>
          <Box className={classes.iconContainer}>
            <ThemeIcon size={'xl'} radius={'md'} color={isEmailSelected ? 'mbgreen.2' : 'mbdefault.2'}>
              <IconMail size={24} color={'#999'} />
            </ThemeIcon>
          </Box>
          <Stack style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }} gap={0} justify='center'>
            <Box className={classes.descriptionContainer}>
              <Text span fw={700} size={'md'} className={classes.subject}>{subject}</Text>
            </Box>
          </Stack>
        </Flex>
        <Box style={{ width: '100%', borderTop: '1px solid #E0E0E0' }}>
          <Flex mx={32} my={8} justify={centerStats ? 'center' : 'space-between'}>
            <Tooltip label='Delivered'>
              <Flex gap={4}>
                <MBRecipients color={isEmailSelected ? '#70C56E' : '#C2C2C2'} size={20} />
                <Text color={isEmailSelected ? 'mbgreen.2' : 'mbdefault.3'} fz={14}>{isDemo ? subjectStats[subject].delivered : delivery.delivered}</Text>
              </Flex>
            </Tooltip>
            {delivery.open > 0 && (
              <Tooltip label='Open'>
                <Flex gap={4}>
                  <MBOpen stroke={isEmailSelected ? '#70C56E' : '#C2C2C2'} size={20} />
                  <Text color={isEmailSelected ? 'mbgreen.2' : 'mbdefault.3'} fz={14}>{isDemo ? subjectStats[subject].open : delivery.open}</Text>
                </Flex>
              </Tooltip>
            )}
            {delivery.click > 0 && (
              <Tooltip label='Clicks'>
                <Flex gap={4}>
                  <MBClicked stroke={isEmailSelected ? '#70C56E' : '#C2C2C2'} size={20} />
                  <Text color={isEmailSelected ? 'mbgreen.2' : 'mbdefault.3'} fz={14}>{isDemo ? subjectStats[subject].click : delivery.click}</Text>
                </Flex>
              </Tooltip>
            )}
            {delivery.unsubscribe > 0 && (
              <Tooltip label='Unsubscribed'>
                <Flex gap={4}>
                  <MBUnsubscriber stroke={isEmailSelected ? '#70C56E' : '#C2C2C2'} size={20} />
                  <Text color={isEmailSelected ? 'mbgreen.2' : 'mbdefault.3'} fz={14}>{isDemo ? subjectStats[subject].unsubscribe : delivery.unsubscribe}</Text>
                </Flex>
              </Tooltip>
            )}
          </Flex>
        </Box>
      </Box>
    </Stack>
  );
};

export default SequenceEmailLiveCard;
