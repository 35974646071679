import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { displaySuccessNotification } from '@/components/display/notifications';
import { useGetDemoDraft } from '@/services/api/demo/draft';
import { useGetDemoReview, useUpdateDemoReview } from '@/services/api/demo/review';
import { EMAIL_EDITOR_TYPE, REVIEW_STATUS } from '@/sharedTypes';
import { useTour } from '@reactour/tour';
import { useNavigate, useParams } from 'react-router-dom';
import EmailReviewCore, { EmailReviewProps } from './EmailReviewCore';

export const DemoEmailReview = ({ standalonePage }: EmailReviewProps) => {
  // get the query params from the url slug
  const { draftId } = useParams();
  const navigate = useNavigate();

  const { data: demoReview, isLoading: isLoadingEmailReview } = useGetDemoReview(draftId);
  const { data: demoDraft, isLoading: isLoadingEmailPreview } = useGetDemoDraft(draftId);
  const updateDemoReview = useUpdateDemoReview();

  if (isLoadingEmailReview || isLoadingEmailPreview) return <MailberryLoader />;

  const handleApproveEmail = (emailReviewId: string) => {
    updateDemoReview.mutateAsync({ id: emailReviewId, status: REVIEW_STATUS.APPROVED }).then(d => {
      if (d.message === 'OK') {
        displaySuccessNotification('Review Sent', 'The email has been successfully approved', 5000);
        return navigate('/demo/chat');
      }
    });
  };

  const handleRequestChangues = (emailReviewId: string, content: Record<string, any> = {}) => {
    const localComments = JSON.parse(localStorage.getItem('comments_' + emailReviewId)!);
    const previousComments = content.comments;

    const comments = { ...previousComments, ...localComments };

    updateDemoReview.mutateAsync({ id: emailReviewId, status: REVIEW_STATUS.CHANGES_REQUESTED, content: { comments } }).then(d => {
      localStorage.removeItem('comments_' + emailReviewId);
      if (d.message === 'OK') {
        displaySuccessNotification('Review Sent', 'The email has been sent back for changes', 5000);
        return navigate('/demo/');
      }
    });
  };

  return (
    <>
      <EmailReviewCore
        draftId={draftId}
        emailPreview={demoDraft?.data}
        emailReview={{
          ...demoReview?.data,
          editor: EMAIL_EDITOR_TYPE.TIPTAP,
          draft: demoDraft?.data,
        }}
        onApprove={handleApproveEmail}
        onRequestChanges={handleRequestChangues}
        isLoading={updateDemoReview.isLoading}
      />
    </>
  );
};
