import { calcAverage, formatToDayDate } from '@/lib/utils';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { IDraftDelivery, useGetDemoDeliveries } from '@/services/api/demo/delivery';
import { useGetAllLastDemoReview } from '@/services/api/demo/review';
import { useTrackDemoEvent } from '@/services/api/demo/tracking';
import AppState from '@/services/state/AppState';
import { REVIEW_STATUS } from '@/sharedTypes';
import { Anchor, Box, Button, Card, Divider, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import { useTour } from '@reactour/tour';
import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { CiClock1 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { STATUS_COLORS } from '../common';

interface CombinedListProps {
  loginCount: number;
}

export default function DemoDeliveriesList({ loginCount }) {
  const { data: reviewData, isLoading: isLoadingReviews } = useGetAllLastDemoReview();
  const { data: reportData, isLoading: isLoadingReports } = useGetDemoDeliveries();
  const navigate = useNavigate();
  const { currentStep, setCurrentStep, setIsOpen } = useTour();
  const trackDemoEvent = useTrackDemoEvent();
  const appState = AppState.useContainer();

  const isLoading = isLoadingReviews || isLoadingReports;

  // Combine and sort reviews and reports
  const _combinedCampaigns = reviewData?.data.map(r => {
    const rd = reportData?.data?.find(d => d.demoDraftId === r.demoDraftId);
    if (rd) {
      return {
        ...r,
        ...rd,
        status: null,
      };
    } else {
      return {
        ...r,
      };
    }
  }) || [];

  const combinedCampaigns = _combinedCampaigns
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .slice(0, 5); // Limit to 5 items

  useEffect(() => {
    const tourCompleted = localStorage.getItem('tourCompleted');
    if (loginCount === 1 && combinedCampaigns.length && !tourCompleted && tourCompleted !== '1') {
      setIsOpen(true);
    }

    if (currentStep === 13) {
      appState.userAndCustomer.setIsInTour(false);
    }

    if (appState.userAndCustomer.isInTour && combinedCampaigns.length && currentStep === 4) {
      setCurrentStep(currentStep + 1);
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep + 1 }).catch(e => console.error(e));
    }

    if (appState.userAndCustomer.isInTour && combinedCampaigns.length && currentStep === 7) {
      setCurrentStep(currentStep + 1);
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep + 1 }).catch(e => console.error(e));
    }
  }, [combinedCampaigns.length, loginCount]);

  useEffect(() => {
    if (appState.userAndCustomer.isInTour && currentStep === 9) {
      setCurrentStep(currentStep + 1);
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep + 1 }).catch(e => console.error(e));
      setIsOpen(true);
    }

    if (appState.userAndCustomer.isInTour && currentStep === 11) {
      localStorage.removeItem('tourCompleted');
      setCurrentStep(0);
      setIsOpen(true);
    }
  }, [currentStep]);

  const onClickView = item => {
    // If item has demoDraftId it's a review, otherwise it's a report
    if (item.status) {
      navigate(`/demo/review/${item.demoDraftId}`);
    } else {
      navigate(`/demo/reports/${item.demoDraftId}`);
    }
  };

  const calculateRates = (sendTo: number, open: number, click: number) => {
    const clickRate = calcAverage(open, click);
    const openRate = calcAverage(sendTo, open);
    return { clickRate, openRate };
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      {isLoading
        ? (
          Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} height={100} radius='md' animate />)
        )
        : (
          <Stack gap={0}>
            <Stack gap='md'>
              {combinedCampaigns.length === 0 ? <MBEmptyGeneric /> : (
                <>
                  {combinedCampaigns.map((item, i) => {
                    const className = i === 0 ? `tour-first-step tour-fourth-step` : '';
                    if (item.status) {
                      // Render review item
                      return (
                        <Card
                          className={className}
                          style={{ cursor: 'pointer' }}
                          key={item.id}
                          shadow='sm'
                          p='lg'
                          radius='md'
                          withBorder
                          onClick={() => onClickView(item)}
                        >
                          <Stack gap={4}>
                            <Text fw={500} size='lg'>
                              {item.subject || 'No Subject'}
                              <span style={{ color: '#777' }}>{item.preHeader && ` - ${item.preHeader}`}</span>
                            </Text>
                            {item.status === REVIEW_STATUS.APPROVED
                              && (
                                <Group wrap='nowrap'>
                                  {/* <CiClock1 /> */}
                                  {/* <Text display={'inline'} size='sm' c='dimmed'>{item.scheduledTo ? `Scheduled for ${formatToDayDate(item.scheduledTo)}` : 'Not yet scheduled'}</Text> */}
                                </Group>
                              )}
                            <Text size='lg' style={{ color: STATUS_COLORS[item.status] }} ta='center'>
                              {item.status === 'READY_FOR_REVIEW' && 'AWAITING YOUR REVIEW'}
                              {item.status !== 'READY_FOR_REVIEW' && item.status.charAt(0).toUpperCase() + item.status.slice(1).replaceAll('_', ' ')}
                            </Text>
                          </Stack>
                        </Card>
                      );
                    } else {
                      // Render sent campaign item
                      const draft = item as IDraftDelivery;

                      const { clickRate, openRate } = calculateRates(
                        draft.sendTo,
                        draft.open,
                        draft.click,
                      );

                      return (
                        <Card
                          key={draft.id}
                          style={{ cursor: 'pointer' }}
                          onClick={() => onClickView(draft)}
                          shadow='sm'
                          p='lg'
                          radius='md'
                          withBorder
                        >
                          <Text fw={500} size='lg'>
                            {item.subject || 'No Subject'}
                            <span style={{ color: '#777' }}>{item.preHeader && ` - ${item.preHeader}`}</span>
                          </Text>
                          <Text size='sm' c='dimmed' mt='sm'>
                            {`Sent ${formatDistanceToNow(new Date(draft.toBeSentAt), { addSuffix: true })}`}
                          </Text>
                          <Divider mt='sm' mb='sm' />
                          <Group justify='space-evenly'>
                            <Box ta={'center'}>
                              <Text size='sm' c='dimmed'>Open rate</Text>
                              <Text fw={700} size='xl'>{`${parseFloat(openRate.toFixed(2))}%`}</Text>
                            </Box>
                            <Box ta={'center'}>
                              <Text size='sm' c='dimmed'>Click rate</Text>
                              <Text fw={700} size='xl'>{`${parseFloat(clickRate.toFixed(2))}%`}</Text>
                            </Box>
                          </Group>
                        </Card>
                      );
                    }
                  })}
                </>
              )}
            </Stack>
          </Stack>
        )}
    </Stack>
  );
}
