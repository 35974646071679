import { genericAuthRequest, RuntimeBackend, useMailberryQuery } from '../base';

export interface IDraftDelivery {
  bounced: number;
  click: number;
  customerDemoId: string;
  delivered: number;
  demoDraftId: string;
  id: string;
  open: number;
  sendTo: number;
  toBeSentAt: string;
  unsubscribe: number;
}

export function useGetDemoDeliveries() {
  return useMailberryQuery<{ data: IDraftDelivery[]; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/deliveries`, {}, RuntimeBackend);
    },
    options: { refetchInterval: 30000 },
  });
}
