import { log } from '@/app/CapacitorWrapper';
import { useEffect, useState } from 'react';

const useDetectWebPlatform = () => {
  const [platform, setPlatform] = useState('unknown');

  const userAgent = navigator.userAgent;

  useEffect(() => {
    const detectPlatform = () => {
      console.log('USER AGENT', userAgent);
      log('USER AGENT', userAgent);

      if (/android/i.test(userAgent)) {
        return 'android';
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios';
      } else if (/Windows|Macintosh|Linux/i.test(userAgent)) {
        return 'desktop';
      } else {
        return 'unknown';
      }
    };

    setPlatform(detectPlatform());
  }, [userAgent]);

  return platform;
};

export default useDetectWebPlatform;
