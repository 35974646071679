import { DemoReview, Review } from '@prisma/client';
import { useState } from 'react';
import { createContainer } from 'unstated-next';

interface Comment {
  content: string;
  xpath: string;
  position: { x: number; y: number; };
}

interface UseCommentsProps {
  emailReview?: (Review | DemoReview) & {
    id: string;
    content: {
      comments?: Record<string, Comment[]>;
      [key: string]: any;
    };
  };
}

function useComments({ emailReview }: UseCommentsProps = {}) {
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [selectedNodeComments, setSelectedNodeComments] = useState<Comment[]>([]);
  const [showCommentsBox, setShowCommentsBox] = useState(false);
  const [localCommentsCounter, setLocalCommentsCounter] = useState(0);
  const [commentPosition, setCommentPosition] = useState({ x: 0, y: 0 });
  const [xpath, setXpath] = useState('');
  const resetSelection = () => {
    setSelectedNodeId(null);
    setShowCommentsBox(false);
    setTimeout(() => {
      setSelectedNodeComments([]);
    }, 300);
  };

  const getComments = (nodeId: string): { content: string; xpath: string; position: { x: number; y: number; }; }[] => {
    let comments: { content: string; xpath: string; position: { x: number; y: number; }; }[] = [];
    const content = emailReview?.content as Record<string, any>;
    if (content.comments) {
      comments = content.comments[nodeId];
    }

    const parsedComments: null | (string | { content: string; xpath: string; position: { x: number; y: number; }; })[] = JSON.parse(localStorage.getItem('comments_' + emailReview?.id) || 'null');
    // Convert any string comments to Comment objects
    if (parsedComments && Array.isArray(parsedComments)) {
      parsedComments.forEach((comment, index) => {
        if (typeof comment === 'string') {
          parsedComments[index] = {
            content: comment,
            xpath: '',
            position: { x: 0, y: 0 },
          };
        }
      });
    }
    if (parsedComments) {
      const currentNodeComments = parsedComments[nodeId];
      if (currentNodeComments) {
        comments.push(...currentNodeComments);
      }
    }

    return comments;
  };

  const toggleCommentInput = (nodeId?: string, isOpen?: boolean, currentStep?: number) => {
    if (isOpen && currentStep !== 0) return;
    if (!nodeId) {
      resetSelection();
      return;
    }

    const comments = getComments(nodeId) || [];

    setSelectedNodeId(prevNodeId => {
      if (nodeId === prevNodeId) {
        setShowCommentsBox(false);
        setSelectedNodeComments([]);
        return null;
      } else {
        setShowCommentsBox(true);
        setSelectedNodeComments(comments as Comment[]);
        return nodeId;
      }
    });
  };

  const handleAddComment = async (content: string, xpath: string, position: { x: number; y: number; }, onAddComment?: (a: any) => void) => {
    if (selectedNodeId && emailReview?.id) {
      const localComments = JSON.parse(localStorage.getItem('comments_' + emailReview.id)!) || {};

      if (!localComments[selectedNodeId]) {
        localComments[selectedNodeId] = [];
      }

      localComments[selectedNodeId].push({ content, xpath, position });
      localStorage.setItem('comments_' + emailReview.id, JSON.stringify(localComments));
      setLocalCommentsCounter(prev => prev + 1);

      // Update counters in iframe immediately
      const allComments = { ...(emailReview?.content?.comments || {}) };
      // Merge local comments with existing comments
      Object.entries(localComments).forEach(([xpath, comments]) => {
        if (!allComments[xpath]) {
          allComments[xpath] = [];
        }
        allComments[xpath].push(...(comments as Comment[]));
      });
      onAddComment(allComments);
    }
  };

  return {
    selectedNodeId,
    setSelectedNodeId,
    selectedNodeComments,
    showCommentsBox,
    setShowCommentsBox,
    localCommentsCounter,
    setLocalCommentsCounter,
    commentPosition,
    setCommentPosition,
    xpath,
    setXpath,
    resetSelection,
    getComments,
    toggleCommentInput,
    handleAddComment,
    setSelectedNodeComments,
  };
}

export const CommentsContainer = createContainer(useComments);
export default useComments;
