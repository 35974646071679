var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import styleObjectToCss from 'style-object-to-css-string';
import { FeaturedBoxView } from './FeaturedBoxView';
export var ReactEmailFeaturedBox = Node.create({
    name: 'mailberryfeaturedbox',
    content: 'block*',
    group: 'block',
    marks: '_',
    draggable: true,
    atom: false,
    selectable: true,
    defining: true,
    addOptions: function () {
        return {
            HTMLAttributes: {
                'data-bg-color': '#fffbe0',
                'data-text-color': '#242424',
                'data-rounded-corners': true,
                'data-box-styles': 'padding: 10px 18px; margin: 12px 0;',
                class: 'mailberry-featured-box',
            },
        };
    },
    addAttributes: function () {
        return {
            class: {
                default: this.options.HTMLAttributes.class,
            },
            'data-rounded-corners': {
                default: 'true',
                renderHTML: function (attributes) {
                    return {
                        'data-rounded-corners': attributes['data-rounded-corners'],
                    };
                },
            },
            'data-bg-color': {
                default: '#000000',
                renderHTML: function (attributes) {
                    return {
                        'data-bg-color': attributes['data-bg-color'],
                    };
                },
            },
            'data-text-color': {
                default: '#ffffff',
                renderHTML: function (attributes) {
                    return {
                        'data-text-color': attributes['data-text-color'],
                    };
                },
            },
            'data-box-styles': {
                default: 'padding: 10px 18px; margin: 12px 0;',
                renderHTML: function (attributes) {
                    return {
                        'data-box-styles': attributes['data-box-styles'],
                    };
                },
            },
        };
    },
    parseHTML: function () {
        return [
            {
                tag: 'react-email-section-featured-box',
            },
        ];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        var inlineStyles = HTMLAttributes['data-box-styles']
            + "background-color: ".concat(HTMLAttributes['data-bg-color'], ";color: ").concat(HTMLAttributes['data-text-color'], ";border-radius: ").concat(HTMLAttributes['data-rounded-corners'] ? '12px' : '0px', ";");
        var newAttributes = __assign(__assign({}, HTMLAttributes), { style: inlineStyles.trim() });
        return ['react-email-section', { style: styleObjectToCss({ padding: '12px 50px' }), class: 'node-section' }, ['react-email-section-featured-box', newAttributes, 0]];
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(FeaturedBoxView);
    },
    addCommands: function () {
        var _this = this;
        return {
            setMailberryFeaturedBox: function (attributes) { return function (_a) {
                var editor = _a.editor, chain = _a.chain, commands = _a.commands;
                return commands.insertContent({
                    type: _this.name,
                    attrs: attributes,
                    content: [
                        {
                            type: 'paragraph',
                            content: [
                                {
                                    type: 'text',
                                    text: 'Type something here',
                                },
                            ],
                        },
                    ],
                });
            }; },
        };
    },
});
