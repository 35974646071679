import { demoEmail } from '@/lib/constants';
import { getClientTimezone } from '@/lib/utils';
import { MBTableSkeleton } from '@/mailberry/Table/Skeleton/MBTableSkeleton';
import AppState from '@/services/state/AppState';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import { DELIVERY_SUBSCRIBER_FILTERS } from '@lib/types';
import MBEmptyOpens from '@mailberry/Empty/MBEmptyOpens';
import MailberryTable from '@mailberry/MailberryTable';
import { Box, Group, Pagination, ScrollArea, Space, Title } from '@mantine/core';
import { useExportDeliverySubscribers, useGetDeliverySubscribers, useGetDeliverySubscribersCount } from '@services/api/delivery';
import { usePagination } from '@services/hooks/usePagination';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fakeRecipients, shuffleArray } from './Recipients';
export const OpenWrapper = ({ delivery, isLoadingDraft }) => {
  const exportDeliverySubscribers = useExportDeliverySubscribers();
  const { isLoading: isLoadingCount, data: subscribersCount } = useGetDeliverySubscribersCount(delivery?.id, DELIVERY_SUBSCRIBER_FILTERS.OPENED);
  const { totalPages, page, setPage, limit, offset } = usePagination(10, subscribersCount?.total);
  const { isLoading, data: deliverySubscribers } = useGetDeliverySubscribers(delivery?.id, limit, offset, DELIVERY_SUBSCRIBER_FILTERS.OPENED);
  const pagination = { totalPages, page, setPage, limit, offset };
  return (
    <Open
      exportMutation={exportDeliverySubscribers}
      delivery={delivery}
      subscribersCount={subscribersCount}
      deliverySubscribers={deliverySubscribers}
      isLoading={isLoadingCount || isLoading || isLoadingDraft}
      pagination={pagination}
    >
    </Open>
  );
};
export default OpenWrapper;
export function Open({ exportMutation, delivery, subscribersCount, deliverySubscribers, isLoading, pagination }) {
  const { totalPages, page, setPage } = pagination;
  const [subscribers, _setSubscribers] = useState([]);
  const timeZone = getClientTimezone();

  function setSubscribers(d) {
    if (d?.length) {
      _setSubscribers(
        d.map(i => ({
          id: i.id,
          email: (
            <Link
              to={'#'}
              style={{
                textDecoration: 'none',
                color: 'black',
              }}
            >
              {i.email}
            </Link>
          ),
          sentToSGAt: formatDateToUserTimezone(i.sentToSGAt, timeZone),
        })),
      );
    }
  }
  const appState = AppState.useContainer();
  useEffect(() => {
    if (appState.signupAndLogin.email === demoEmail) {
      console.log(deliverySubscribers);
      return setSubscribers(shuffleArray([...fakeRecipients, ...fakeRecipients, ...fakeRecipients]));
    }

    setSubscribers(deliverySubscribers);
  }, [deliverySubscribers]);

  if (isLoading) {
    return (
      <Box>
        <ScrollArea style={{ height: 'calc(80vh - 200px)' }}>
          <MBTableSkeleton title={'Opens'} manySelects={0} checkbox rows={[{ label: 'Email' }, { label: 'Opened At' }]} />;
        </ScrollArea>
      </Box>
    );
  }

  return (
    <Box>
      <ScrollArea>
        <Title order={2} m={16}>{appState.signupAndLogin.email === demoEmail ? 63181 : subscribersCount?.total} Opens</Title>
        {subscribers.length
          ? (
            <MailberryTable
              headers={['Subscriber', 'Opened At']}
              tableData={subscribers.map(s => ({ email: s.email, sentToSGAt: s.sentToSGAt }))}
              total={subscribersCount?.total}
            />
          )
          : null}

        {!subscribers.length && <MBEmptyOpens />}
        <Space h={24} />
        <Group justify='center'>
          <Pagination
            display={totalPages > 1 ? 'flex' : 'none'}
            value={page}
            onChange={setPage}
            total={totalPages}
            defaultValue={1}
            color='mbblue'
          />
        </Group>
      </ScrollArea>
    </Box>
  );
}
