import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { useSendMessageAdmin } from '@/services/api/admin';
import { useGetEmailPreview } from '@/services/api/emailPreview';
import { useGetEmailReview } from '@/services/api/emailReview';
import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Card, Group, Stack, Text, Textarea } from '@mantine/core';
import { PaperPlaneIcon, Pencil2Icon } from '@radix-ui/react-icons';
import { useEffect, useState } from 'react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

type MoveMessagesToRead = (sentMessage: ChatMessage, messagesToMove?: ChatMessage[]) => void;

// EmailPreviewFloatingComponent
const EmailPreviewFloatingComponent = (
  { extractedData, moveMessagesToRead, onClose, authId, setFunctionParams }: {
    extractedData: string | null;
    moveMessagesToRead: MoveMessagesToRead;
    onClose: () => void;
    authId: string;
    setFunctionParams: any;
  },
) => {
  const {
    data: previewData,
    isLoading: isLoadingPreview,
    isError: isErrorPreview,
  } = useGetEmailPreview(extractedData ?? '');
  const { data: reviewData, isLoading: isLoadingReview, isError: isErrorReview } = useGetEmailReview(extractedData ?? '');

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const sendMessageAdmin = useSendMessageAdmin();

  const sendMessageReview = async () => {
    if (previewData) {
      const message = {
        type: 'REVIEW',
        subject: previewData.subject,
        preHeader: previewData.preHeader,
        text: '',
        authId: authId,
        createdBy: MESSAGE_AUTHOR.MB_PERSONNEL,
        createdByIdentity: 'unknown',
        metadata: { subject: previewData.subject, preHeader: previewData.preHeader, id: previewData.id },
        token,
      };
      const sentMessage = await sendMessageAdmin.mutateAsync(message);

      moveMessagesToRead(sentMessage);
    }
  };

  const sendReviewMessage = () => {
    if (previewData) {
      sendMessageReview();
      onClose();
    }
  };

  useEffect(() => {
    if (setFunctionParams) {
      setFunctionParams({ sendReviewMessage });
    }
  }, [previewData]);

  return (
    <Card shadow='lg' p='xs' radius='md' style={{ width: '90%', margin: '0 auto 8px' }} h={'10dvh'}>
      <Group justify='center' align='center' h={'100%'}>
        {(previewData && reviewData) && (
          <Group justify='center'>
            <Group mr='md'>
              <Pencil2Icon style={{ width: 32, height: 32, color: '#6B7280' }} />
            </Group>
            <Stack style={{ width: '60%' }} gap={0}>
              <Text size='xs' c='gray' style={{ whiteSpace: 'nowrap' }}>
                {/* Add the date here */}
              </Text>
              <Text size='lg' fw={500} lineClamp={1}>
                Send Review
              </Text>
              <Text size='md' c='gray' lineClamp={2}>
                {previewData.subject}
              </Text>
            </Stack>
          </Group>
        )}
        <MailberryLoader visible={isLoadingPreview || isLoadingReview} />
        {(isErrorPreview || isErrorReview) && <Stack>Error loading preview</Stack>}
      </Group>
    </Card>
  );
};

// Configuration object
const floatingComponents = [
  {
    regex: new RegExp(
      `^\\s*${process.env.NEXT_PUBLIC_NODE_ENV == 'development' ? 'http://' + process.env.NEXT_PUBLIC_DOMAIN + ':3010' : 'https://' + process.env.NEXT_PUBLIC_DOMAIN}/review/([^\\s]+)\\s*$`,
    ),
    component: EmailPreviewFloatingComponent,
    defaultProps: {},
  },
  // Add more configurations for different regular expressions and components
];

interface ChatInputProps {
  onSend: (message?: string) => void;
  moveMessagesToRead: MoveMessagesToRead;
  sendingOneMsgAtm: boolean;
  authId: string | null;
  isMobile: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSend, moveMessagesToRead, sendingOneMsgAtm, authId, isMobile }) => {
  const [showFloatingComponent, setShowFloatingComponent] = useState(false);
  const [extractedData, setExtractedData] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [currentComponentIndex, setCurrentComponentIndex] = useState<number | null>(null);
  const [functionParams, setFunctionParams] = useState<any>(null);

  const formSchema = z.object({
    message: z.string().min(1).max(5000),
  });

  const { control, handleSubmit, watch, reset } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
  });

  const [debouncedMessage, setDebouncedMessage] = useState('');

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedMessage(watch('message'));
    }, 300); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [watch('message')]);

  useEffect(() => {
    const matchedConfig = floatingComponents.find(config => config.regex.test(debouncedMessage));

    if (matchedConfig) {
      const match = matchedConfig.regex.exec(debouncedMessage);
      const extractedData = match ? match[1] : null;
      setExtractedData(extractedData);
      setShowFloatingComponent(true);
      setIsVisible(true);
      setCurrentComponentIndex(floatingComponents.indexOf(matchedConfig));
    } else {
      setShowFloatingComponent(false);
      setTimeout(() => {
        setExtractedData(null);
        setIsVisible(false);
        setCurrentComponentIndex(null);
      }, 100);
    }
  }, [debouncedMessage]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (extractedData && functionParams && currentComponentIndex !== null) {
      functionParams.sendReviewMessage();
    } else {
      onSend(values.message);
    }
    reset();
  };

  const handleClose = () => {
    setShowFloatingComponent(false);
    setTimeout(() => {
      setIsVisible(false);
    }, 100);
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          bottom: 50,
          transition: 'all 300ms ease-in-out',
          transform: showFloatingComponent ? 'translateY(0)' : 'translateY(16px)',
          opacity: showFloatingComponent ? 1 : 0,
          width: '100%',
        }}
      >
        {(isVisible && authId && currentComponentIndex !== null) && (
          <>
            {React.createElement(floatingComponents[currentComponentIndex].component, {
              extractedData,
              moveMessagesToRead,
              onClose: handleClose,
              authId,
              setFunctionParams,
              ...floatingComponents[currentComponentIndex].defaultProps,
            })}
          </>
        )}
      </Box>
      <Group style={{ zIndex: 100 }} align='center' justify='center' p={isMobile ? '0.4em 0' : '0 0 1em 0'} w={isMobile ? '100%' : '50%'} bg={isMobile ? 'white' : 'transparent'}>
        <div style={{ width: '100%', height: '100%' }}>
          <form>
            <Controller
              control={control}
              name='message'
              render={({ field }) => (
                <Group style={{ width: '100%', height: '100%' }} align='center' justify='center' gap={'0.5em'}>
                  <Textarea
                    autoComplete='off'
                    w='85%'
                    placeholder='Type a message'
                    radius='30px'
                    size={isMobile ? 'sm' : 'lg'}
                    autosize
                    minRows={1}
                    maxRows={4}
                    onKeyDown={e => {
                      if (e.ctrlKey && e.key === 'Enter') {
                        return sendingOneMsgAtm ? null : handleSubmit(onSubmit)();
                      }
                    }}
                    {...field}
                  />
                  <Button
                    size={isMobile ? 'sm' : 'lg'}
                    radius='50%'
                    style={{ width: 'auto', aspectRatio: '1/1', padding: 0 }}
                    leftSection={''}
                    onClick={() => sendingOneMsgAtm ? null : handleSubmit(onSubmit)()}
                  >
                    <PaperPlaneIcon
                      style={{ width: 'auto', height: '70%', color: 'white', margin: 'auto', padding: '2px' }}
                    />
                  </Button>
                </Group>
              )}
            />
          </form>
        </div>
      </Group>
    </>
  );
};

export default ChatInput;
