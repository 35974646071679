import { demoEmail } from '@/lib/constants';
import AppState from '@/services/state/AppState';
import { Avatar, Group, Image, Skeleton, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

export default function AccountInfo({ account, isLoading, isDemo = false }) {
  const logoUrl = isDemo ? 'https://assets.mailberry.ai/v2/nike.png' : account?.logoUrl;
  const appState = AppState.useContainer();
  if (isLoading || !account) {
    return (
      <Group align='center' gap='xl' justify='start' m={'lg'}>
        <Skeleton circle height={130} width={130} />
        <Stack gap={0}>
          <Skeleton height={22} width={200} mb='xs' />
          <Skeleton height={30} width={120} />
        </Stack>
      </Group>
    );
  }
  return (
    <Group align='center' gap='xl' justify='start' wrap='nowrap' m={'lg'}>
      <Avatar
        src={logoUrl || ''}
        alt='Mailberry Logo'
        size={130}
        radius={65}
        styles={() => ({
          image: { objectFit: 'contain', padding: '10px' },
        })}
      />
      <Stack gap={0}>
        <Title order={3} fw={400}>
          {account.brandName}
        </Title>
        <Link to='/segments' style={{ textDecoration: 'none', color: 'inherit' }}>
          <Stack gap={0} style={{ cursor: 'pointer' }}>
            <Title order={2}>{appState.signupAndLogin.email === demoEmail ? 113656 : account.activeSubscribers}</Title>
            <Text size={'1em'}>Active subscribers</Text>
          </Stack>
        </Link>
      </Stack>
    </Group>
  );
}
