import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { useDeletionAccount } from '@/services/api/account';
import { useAuth } from '@/services/hooks/useAuth';
import AppState from '@/services/state/AppState';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { closeAllModals, closeModal, modals } from '@mantine/modals';
import { useState } from 'react';
import { FaRegImage, FaShopify } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function Settings({ styles }) {
  const navigate = useNavigate();
  const deletionAccount = useDeletionAccount();
  const { isAuthenticated, signOut } = useAuth();
  const appState = AppState.useContainer();
  const [isLoading, setIsLoading] = useState(false);

  const handleSignOut = async () => {
    await signOut(closeAllModals);
  };

  const askForDeletion = async () => {
    setIsLoading(true);
    await deletionAccount.mutateAsync({});
    setIsLoading(false);
    return;
  };

  const handleOpenAssets = () => {
    navigate('/settings/assets');
  };

  const handleOpenShopify = () => {
    navigate('/settings/shopify');
  };
  const openModal = () =>
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          Your account will be scheduled for <Text span inherit c='orange'>deletion</Text>, you will receive an email when this happens
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onCancel: () => console.log('Cancel'),
      onConfirm: askForDeletion,
    });

  return (
    <Stack gap='xl' h={'100%'} w={'100%'} align='center' justify='start' styles={{ ...styles }}>
      <MailberryLoader visible={appState.userAndCustomer.isLoading} />

      <Group style={{ cursor: 'pointer', borderRadius: 5, padding: 10 }}>
        {!appState.signupAndLogin.isDemo && (
          <>
            <Button variant='light' size='lg' fullWidth onClick={handleOpenAssets} c={isAuthenticated ? 'black' : 'gray'}>
              <Box p={10}>
                <FaRegImage />
              </Box>
              Assets
            </Button>

            <Button variant='light' size='lg' fullWidth onClick={handleOpenShopify} c={isAuthenticated ? 'black' : 'gray'}>
              <Box p={10}>
                <FaShopify />
              </Box>
              Shopify
            </Button>
          </>
        )}

        <Button variant='light' size='lg' fullWidth onClick={handleSignOut} c={isAuthenticated ? 'red' : 'gray'}>
          Sign out
        </Button>

        {!appState.signupAndLogin.isDemo
          && (
            <Button fullWidth variant='light' type='submit' loading={isLoading} size='lg' onClick={openModal} c={isAuthenticated ? 'red' : 'gray'}>
              Delete my account
            </Button>
          )}
      </Group>
    </Stack>
  );
}
