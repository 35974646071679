import { useAuth } from '@/services/hooks/useAuth';
import AppState from '@/services/state/AppState';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: React.ReactNode; }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const appState = AppState.useContainer();
  const [searchParams, setSearchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');

  const verifyAndSetToken = async () => {
    if (accessToken && !auth.isAuthenticated && !auth.isLoading) {
      auth.setAccessToken(accessToken);
    }
  };

  useEffect(() => {
    verifyAndSetToken();
  }, [accessToken, auth.isLoading]);

  useEffect(() => {
    if (
      auth
      && !auth.isAuthenticated
      && !auth.isLoading
      && !appState.signupAndLogin.isLoadingToken
    ) {
      auth.signOut();
    }

    if (
      auth
      && !auth.isLoading
      && !appState.signupAndLogin.isLoadingToken
      && appState.signupAndLogin.isDemo
    ) {
      navigate('/demo');
    }
  }, [
    auth?.isAuthenticated,
    auth?.isLoading,
    appState.signupAndLogin.isLoadingToken,
  ]);

  if (auth?.isAuthenticated && !appState.signupAndLogin.isDemo) {
    return <>{children}</>;
  }

  return null;
};

export default ProtectedRoute;
