import { filterObjectsById, sortMessagesAndRemoveDuplicates } from '@/lib/utils';
import { useGetNewMessagesAdmin, useGetReadMessagesAdminInfiniteQuery, useMarkAsReadAdmin, useSendMessageAdmin } from '@/services/api/admin';
import useInView from '@/services/hooks/useInView';
import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { useEffect, useRef, useState } from 'react';

import MessagesList from '@/components/screens/Chat/MessagesList';
import { ActionIcon, Avatar, Box, Group, Stack, Text } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import ChatInput from '../Chat/ChatInput';

const ChatBox = ({ currentChat, onExit, authId, num }: { currentChat: Record<string, any>; onExit: () => void; authId: string; num: number; }) => {
  const { data: readMessagesPages, isFetchingNextPage, fetchNextPage, isLoading } = useGetReadMessagesAdminInfiniteQuery(20, currentChat.authId, {
    getNextPageParam: (lastPage: any) => {
      if (lastPage && !lastPage.page) return;
      // no more pages
      if (Number(lastPage.page) === lastPage.totalPages) return;

      return Number(lastPage.page) + 1;
    },
  });
  const sendMessage = useSendMessageAdmin();
  const markAsRead = useMarkAsReadAdmin();

  const [readMessages, setReadMessages] = useState<any>([]);
  const [newMessages, setNewMessages] = useState<any>([]);
  const [showNewMessages, setShowNewMessages] = useState<boolean>(true);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);
  const [sendingOneMsgAtm, setSendingOneMsgAtm] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { data: newMessagesServer } = useGetNewMessagesAdmin(authId, lastMessageId, num, { enabled: !!lastMessageId });

  const updatePastMessages = (pastMessages: any) => {
    const filtered = [...filterObjectsById(readMessages, pastMessages), ...readMessages];
    const sorted = filtered.sort((a: any, b: any) => {
      return new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime();
    });
    setReadMessages(sorted);
  };

  const addReadMessagesState = (messages: ChatMessage[]) => {
    const sortedAndUnique = sortMessagesAndRemoveDuplicates([...readMessages, ...messages]);
    setReadMessages(sortedAndUnique);
  };

  useEffect(() => {
    if (readMessagesPages && readMessagesPages.pages) {
      if (!readMessages.length) {
        const messages = readMessagesPages.pages.flatMap(e => e.messages);
        setLastMessageId(messages[0]?.id);
        setReadMessages(messages);
      } else {
        updatePastMessages(readMessagesPages.pages.slice(1).flatMap(e => e.messages));
      }

      if (readMessagesPages?.pages[0].totalPages < 1 && !(newMessages?.length)) {
        setLastMessageId('all');
      }
    }
  }, [readMessagesPages]);

  // Update new messages from server
  useEffect(() => {
    if (newMessagesServer) {
      const excludeAdminUser = newMessagesServer.filter(msg => msg.createdBy !== MESSAGE_AUTHOR.MB_PERSONNEL);
      if (excludeAdminUser.length) {
        if (isBottomInView) {
          // Mark all messages as read
          markAsRead.mutate({});
        }

        if (showNewMessages) {
          setNewMessages(excludeAdminUser);
          setShowNewMessages(false);
        } else {
          // Add the new messages to state
          addReadMessagesState([...newMessages, ...excludeAdminUser]);
          setNewMessages([]);
        }

        // Use the last message as the anchor for the newest message
        setLastMessageId(excludeAdminUser[0].id);
      }
    }
  }, [newMessagesServer]);

  const moveMessagesToRead = (sentMessage: ChatMessage, messagesToMove?: ChatMessage[]) => {
    if (!messagesToMove) messagesToMove = [...readMessages];
    // Add sent msg
    messagesToMove.unshift(sentMessage);

    if (newMessages?.length) {
      messagesToMove = sortMessagesAndRemoveDuplicates([...messagesToMove, ...newMessages]);
      setNewMessages([]);
    }
    setReadMessages(messagesToMove);
  };

  /**
   * Handles the submission of a chat message.
   *
   * This function performs the following steps:
   * 1. Checks if a message is provided and is not empty after trimming whitespace.
   * 2. Temporarily sets a flag to indicate a message is being sent.
   * 3. Creates a temporary message object to display in the message list.
   * 4. Updates the message lists by moving new messages to read messages if any exist.
   * 5. Adds the temporary message to the read messages list.
   * 6. Sends the message using the `sendMessage` mutation.
   * 7. Removes the temporary message from the read messages list.
   * 8. Moves the newly sent message to the read messages list.
   * 9. Marks the chat as read using the `markAsRead` mutation.
   *
   * @param {string} [message] - The message text to be sent. If not provided or empty, the function will not proceed.
   * @returns {Promise<void>} - A promise that resolves when the message handling is complete.
   * @throws {Error} - Logs any errors that occur during message handling.
   */
  const handleSubmitMessage = async (message?: string) => {
    try {
      // Check if the message is not null, undefined, or an empty string after trimming whitespace (includes "\n")
      if (!sendingOneMsgAtm && message && message.trim() !== '') {
        // Set flag to prevent loss/inconsistency messages
        setSendingOneMsgAtm(true);

        // Set temp message object to display in message list
        const tempMsg = {
          'id': null,
          'text': `${message}`,
          'createdAt': '',
          'updatedAt': '',
          'sentAt': new Date().toISOString(),
          'readAt': 'null',
          'createdBy': 'MB_PERSONNEL',
          'createdByIdentity': 'not-defined',
          'metadata': null,
          'type': 'TEXT',
          'authId': '',
        };

        let tempReadMessages = [...readMessages];
        if (newMessages?.length) {
          setNewMessages([]);
          tempReadMessages = [...newMessages, ...readMessages];
        }
        // Add temp msg
        tempReadMessages.unshift(tempMsg);
        setReadMessages(tempReadMessages);

        // Send message
        const sentMessage = await sendMessage.mutateAsync({
          text: message,
          createdBy: MESSAGE_AUTHOR.MB_PERSONNEL,
          createdByIdentity: 'not-defined',
          authId: currentChat.authId,
          token,
        });

        // Remove temp msg
        tempReadMessages.shift();

        // Move the new message to readMessages
        moveMessagesToRead(sentMessage, tempReadMessages);
        setSendingOneMsgAtm(false);
      }
      markAsRead.mutate({ authId: currentChat.authId });
    } catch (error) {
      console.log(error);
    }
  };

  // When the chat is opened, the scroll moves to the last message
  const messagesBottomRef = useRef<HTMLDivElement>(null);
  const newMessagesRef = useRef<HTMLDivElement>(null);
  const isBottomInView = useInView(newMessagesRef);
  // Request more messages by scrolling up
  const messagesTopRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(messagesTopRef);

  useEffect(() => {
    if (isBottomInView) {
      markAsRead.mutateAsync({ authId: currentChat.authId });
    }
  }, [isBottomInView]);

  useEffect(() => {
    messagesBottomRef.current?.scrollIntoView({ behavior: 'instant' });
  }, [readMessagesPages?.pages[0].page]);

  useEffect(() => {
    if (isInView) {
      fetchNextPage();
    }
  }, [isInView]);

  return (
    <Stack
      style={{
        height: '100%',
        position: 'relative',
      }}
      gap={0}
      bg={'#F4F1EB'}
    >
      <Box
        style={{
          width: '100%',
          zIndex: 10,
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          backgroundColor: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '12px 16px',
          '@media (maxWidth: 768px)': {
            display: 'none',
          },
          height: '6dvh',
        }}
      >
        <Group gap='xs' align='center'>
          <ActionIcon onClick={onExit} color='#ffffff' style={{ cursor: 'pointer' }}>
            <ArrowLeftIcon color='#111827' style={{ width: 24, height: 24 }} />
          </ActionIcon>
          <Group gap='xs' align='center'>
            <Avatar
              src={currentChat.logoUrl}
              alt='Chat Logo'
              radius='xl'
              size={32}
              color='#374151'
            />
            <Text fw={600} size='sm' c='#111827'>
              {currentChat.name}
            </Text>
          </Group>
        </Group>
      </Box>

      <Box style={{ height: 'calc(95% - 6dvh)' }}>
        <MessagesList
          showNewMessages={showNewMessages}
          newMessagesLoading={false}
          newMessages={newMessages}
          readMessages={readMessages}
          isMobile={true}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          ref={{ messagesTopRef, messagesBottomRef } as any}
          isAdminView={true}
        />
      </Box>

      <Group h={'5dvh'}>
        <ChatInput
          onSend={handleSubmitMessage}
          moveMessagesToRead={moveMessagesToRead}
          sendingOneMsgAtm={sendingOneMsgAtm}
          authId={authId}
          isMobile={true}
        />
      </Group>
    </Stack>
  );
};

export default ChatBox;
