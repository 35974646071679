import { Box, Center } from '@mantine/core';
import { DemoDraft, DemoReview, Draft, Review } from '@prisma/client';
import { HTMLRendered } from './BodyParsed';
import { EmailPreviewFooter } from './EmailPreviewFooter';

interface TiptapEditorPreviewProps {
  showDesktopVersion: boolean;
  isMobile: boolean;
  styles: {
    editorColor: string;
    fontColor: string;
    borderRadius: string;
    borderWidth: string;
    borderColor: string;
    backgroundColor: string;
  };
  emailPreview: Draft | DemoDraft;
  emailReview: (Review | DemoReview) & {
    businessAddress: string;
    content: {
      comments?: Record<string, { content: string; xpath: string; position: { x: number; y: number; }; }[]>;
    };
  };
  selectedNodeId: string | null;
  localCommentsCounter: number;
  setStyles: (styles: any) => void;
  toggleCommentInput: (nodeId?: string) => void;
  getComments: (nodeId: string) => (string | { content: string; xpath: string; position: { x: number; y: number; }; })[];
}

const TiptapEditorPreview = ({
  showDesktopVersion,
  isMobile,
  styles,
  emailPreview,
  emailReview,
  selectedNodeId,
  localCommentsCounter,
  setStyles,
  toggleCommentInput,
  getComments,
}: TiptapEditorPreviewProps) => {
  return (
    <>
      <div
        className={(!isMobile && showDesktopVersion ? 'editor-preview-desktop' : 'editor-preview-mobile') + ' '}
        style={{
          backgroundColor: styles.editorColor,
          color: styles.fontColor,
          borderRadius: showDesktopVersion && !isMobile ? styles.borderRadius : '',
          border: styles.borderWidth && showDesktopVersion && !isMobile ? `${styles.borderWidth}px solid ${styles.borderColor}` : '',
        }}
      >
        <HTMLRendered
          emailPreview={emailPreview}
          emailReview={emailReview}
          selectedNodeId={selectedNodeId}
          localCommentsCounter={localCommentsCounter}
          setStyles={setStyles}
          toggleCommentInput={toggleCommentInput}
          getComments={getComments}
        />
        <EmailPreviewFooter businessMailAddress={emailReview?.businessAddress!} />
      </div>
      <Center
        style={{
          color: styles.fontColor,
        }}
        p='14px 48px 40px'
        fz={'sm'}
      >
        Powered by
        <span
          style={{
            color: '#3B82F6',
            textDecoration: 'underline',
          }}
        >
          <img
            width='75'
            height='18'
            style={{
              width: 75,
              height: 18,
              verticalAlign: 'text-bottom',
              display: 'inline-block',
              marginLeft: 5,
            }}
            src='https://d1q3o19jn6nxkf.cloudfront.net/email/mailberry-logo.png'
            alt='Mailberry logo'
          />
        </span>
      </Center>
    </>
  );
};

export default TiptapEditorPreview;
