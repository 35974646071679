import React from 'react';

interface MBResizeProps {
  size: number;
}

const MBResize = ({ size }: MBResizeProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1071_14229)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.78728 2.02304C2.59069 2.01938 2.39293 2.09256 2.24291 2.24258C2.19028 2.29521 2.14711 2.35371 2.11339 2.41598C2.11193 2.41869 2.11048 2.42141 2.10905 2.42413C2.05417 2.52835 2.02311 2.64707 2.02311 2.77304L2.02311 10.1977C2.02311 10.6119 2.3589 10.9477 2.77311 10.9477C3.18732 10.9477 3.52311 10.6119 3.52311 10.1977L3.52311 4.58344L9.66753 10.7279C9.96043 11.0208 10.4353 11.0208 10.7282 10.7279C11.0211 10.435 11.0211 9.96009 10.7282 9.6672L4.58403 3.52304L10.1977 3.52304C10.6119 3.52304 10.9477 3.18725 10.9477 2.77304C10.9477 2.35883 10.6119 2.02304 10.1977 2.02304L2.78728 2.02304Z'
          fill='#1D1D1D'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21.1834 21.9477C21.38 21.9513 21.5778 21.8781 21.7278 21.7281C21.7804 21.6755 21.8236 21.617 21.8573 21.5547C21.8588 21.552 21.8602 21.5493 21.8617 21.5466C21.9165 21.4424 21.9476 21.3236 21.9476 21.1977L21.9476 13.773C21.9476 13.3588 21.6118 13.023 21.1976 13.023C20.7834 13.023 20.4476 13.3588 20.4476 13.773L20.4476 19.3873L14.3032 13.2428C14.0103 12.9499 13.5354 12.9499 13.2425 13.2428C12.9496 13.5357 12.9496 14.0106 13.2425 14.3035L19.3867 20.4477L13.773 20.4477C13.3588 20.4477 13.023 20.7834 13.023 21.1977C13.023 21.6119 13.3588 21.9477 13.773 21.9477L21.1834 21.9477Z'
          fill='#1D1D1D'
        />
      </g>
      <defs>
        <clipPath id='clip0_1071_14229'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MBResize;
