import { genericAuthRequest, useMailberryQuery } from '@/services/api/base';
import { SUBSCRIBER_STATUS } from '@/sharedTypes';
import { Web } from '@/sharedTypes';

type IGetAllSubscribersRequest = {
  segmentIds: string[];
  status?: SUBSCRIBER_STATUS[];
  limit: number;
  offset: number;
  filterEmail?: string;
};

export function useGetAllSubscribers(userData: IGetAllSubscribersRequest) {
  return useMailberryQuery<Web.Response.Subscriber[]>({
    f: accessToken => {
      const params = {
        segmentIds: userData.segmentIds,
        status: userData.status,
        limit: userData.limit,
        offset: userData.offset,
        ...(userData.filterEmail ? { filterEmail: userData.filterEmail } : {}),
      };
      return genericAuthRequest(accessToken, 'get', `/subscriber`, params);
    },
    queryKey: [userData.segmentIds.join(''), userData.status?.join(''), userData.limit, userData.offset, userData.filterEmail],
  });
}

type IGetAllSubscribersCountRequest = {
  segmentIds: string[];
  status?: SUBSCRIBER_STATUS[];
  filterEmail?: string;
};

export function useGetAllSubscribersCount(userData: IGetAllSubscribersCountRequest, options?) {
  return useMailberryQuery<Web.Response.CountTotal>({
    f: accessToken => {
      const params = {
        segmentIds: userData.segmentIds,
        status: userData.status,
        ...(userData.filterEmail ? { filterEmail: userData.filterEmail } : {}),
      };
      return genericAuthRequest(accessToken, 'get', `/subscriber/total`, params);
    },
    options,
    queryKey: [userData?.segmentIds?.join(''), userData?.status?.join(''), userData?.filterEmail],
  });
}

export function useGetSubscriber(email: string) {
  return useMailberryQuery<Web.Response.Subscriber>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/subscriber/one/${email}`);
    },
    queryKey: [email],
  });
}
