var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MBDrag from '@assets/icons/MBDrag';
import { Box, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { MenuActions } from '@pages/EmailCompose/Common/Tiptap/Menus/MenuActions';
import { Heading, Section } from '@react-email/components';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import styleToJs from 'style-to-js';
import React from 'react';
var HeadingView = function (_a) {
    var _b, _c;
    var editor = _a.editor, node = _a.node, getPos = _a.getPos, selected = _a.selected;
    var textAlign = node.attrs.textAlign;
    var _d = useHover(), hovered = _d.hovered, ref = _d.ref;
    // Ensure the heading level is always between 1 and 6
    var level = Math.min(Math.max(((_b = node === null || node === void 0 ? void 0 : node.attrs) === null || _b === void 0 ? void 0 : _b.level) || 1, 1), 6);
    var headingTag = "h".concat(level);
    var sectionStyles = {
        padding: '12px 50px',
    };
    if (editor.storage.isMobile) {
        sectionStyles.padding = '12px 20px';
    }
    return (React.createElement(NodeViewWrapper, { ref: ref, style: {
            display: 'flex',
            position: 'relative',
        } },
        React.createElement(Box, { className: 'node-actions' },
            React.createElement(Group, { w: 80, pt: ((_c = node === null || node === void 0 ? void 0 : node.attrs) === null || _c === void 0 ? void 0 : _c.level) === 1 ? 9 : 8, gap: 0, align: 'left', style: {
                    visibility: hovered ? 'visible' : 'hidden',
                } },
                React.createElement(Group, { gap: 8 },
                    React.createElement(Box, { h: 24, style: { cursor: 'pointer' } },
                        React.createElement(MenuActions, { editor: editor, open: null, setOpen: null })),
                    React.createElement(Box, { h: 24, "data-drag-handle": true, style: { cursor: 'grab' } },
                        React.createElement(MBDrag, { size: 24, color: '#A0A0A0' }))))),
        React.createElement(Section, { style: sectionStyles },
            React.createElement(Heading, { style: __assign(__assign({}, styleToJs(node.attrs['data-heading-styles'])), { textAlign: textAlign }), as: headingTag },
                React.createElement(NodeViewContent, { as: 'span' })))));
};
export default HeadingView;
