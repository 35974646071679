import MBMonitor from '@/assets/icons/MBMonitor';
import MBSmartphone from '@/assets/icons/MBSmartphone';
import { parseStyleString } from '@/lib/utils';
import { useTrackDemoEvent } from '@/services/api/demo/tracking';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import AppState from '@/services/state/AppState';
import { EMAIL_EDITOR_TYPE, REVIEW_STATUS } from '@/sharedTypes';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { DemoDraft, DemoReview, Draft, Review } from '@prisma/client';
import { useTour } from '@reactour/tour';
import parse, { Element } from 'html-react-parser';
import { useEffect, useMemo, useRef, useState } from 'react';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import CodeEditorPreview from './CodeEditorPreview';
import Comments from './Comments';
import { CommentsContainer } from './hooks/useComments';
import { MailberryNode } from './MailberryNode';
import ReviewFooter from './ReviewFooter';
import TiptapEditorPreview from './TiptapEditorPreview';

export interface EmailReviewProps {
  standalonePage?: boolean;
  onClose?: () => void;
}

interface EmailReviewCore {
  draftId: string;
  emailReview: (Review | DemoReview) & { businessAddress: string; editor: EMAIL_EDITOR_TYPE; draft: Draft | DemoDraft; };
  emailPreview: Draft | DemoDraft;
  onApprove: (emailReviewId: string, content?: Record<string, any>) => void;
  onRequestChanges: (emailReviewId: string, content?: Record<string, any>) => void;
  isLoading: boolean;
}

const EmailReviewCore = ({
  draftId,
  emailReview,
  emailPreview,
  onApprove,
  onRequestChanges,
  isLoading,
}: EmailReviewCore) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const iframeRef = useRef(null);

  const comments = CommentsContainer.useContainer();

  const isMobile = useCheckMobileScreen();
  const [showDesktopVersion, setShowDesktopVersion] = useState(true);

  const trackDemoEvent = useTrackDemoEvent();
  const { setCurrentStep, setIsOpen, currentStep, isOpen } = useTour();

  const containerRef = useRef<HTMLDivElement>(null);
  const appState = AppState.useContainer();
  const [styles, setStyles] = useState({
    editorColor: '',
    fontColor: '',
    borderWidth: '',
    borderColor: '',
    borderRadius: '',
    backgroundColor: '',
  });

  // connection with iframe to exchange messages
  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      const handleMessage = event => {
        const { type, elementInfo, position, xpath } = event.data;
        if (type === 'elementClicked') {
          comments.setCommentPosition(position);
          comments.setXpath(xpath);
          comments.setShowCommentsBox(true);
          comments.toggleCommentInput(elementInfo?.xpath);
        }
      };

      window.addEventListener('message', handleMessage);
      return () => window.removeEventListener('message', handleMessage);
    }
  }, [iframeRef.current]);

  // When iframe loads, inject click handlers
  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    const script = `
      // Function to get XPath of an element
      function getXPath(element) {
        if (element.id !== '') {
          return '//*[@id="' + element.id + '"]';
        }

        if (element === document.body) {
          return '/html/body';
        }

        let ix = 0;
        const siblings = element.parentNode.childNodes;

        for (let i = 0; i < siblings.length; i++) {
          const sibling = siblings[i];
          if (sibling === element) {
            const pathIndex = ix + 1;
            const path = getXPath(element.parentNode) + '/' + element.tagName.toLowerCase() + '[' + pathIndex + ']';
            return path;
          }

          if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
            ix++;
          }
        }
      }

      document.body.addEventListener('click', (e) => {
        const rect = e.target.getBoundingClientRect();
        const xpath = getXPath(e.target);

        const elementInfo = {
          tagName: e.target.tagName,
          className: e.target.className,
          id: e.target.id,
          text: e.target.textContent.slice(0, 50),
          xpath: xpath
        };

        const position = {
          x: rect.left + rect.width,
          y: rect.top
        };

        window.parent.postMessage({
          type: 'elementClicked',
          elementInfo,
          position
        }, '*');

        e.preventDefault();
      });
    `;

    iframe.contentWindow.eval(script);
  };

  // force reload htmlParsed memo  (comments count bubbles)
  useEffect(() => {
    const localComments: null | (string | { content: string; xpath: string; position: { x: number; y: number; }; })[] = JSON.parse(localStorage.getItem('comments_' + emailReview?.id) || 'null');
    if (localComments) {
      comments.setLocalCommentsCounter(prev => prev + 1);
    }
  }, [emailReview?.id]);

  // verify draft has changued and discard old comments
  useEffect(() => {
    if (emailPreview?.id && emailReview?.id && comments.localCommentsCounter !== 0) {
      const bodyLength = emailPreview.body.length;

      const previousBodyLength = localStorage.getItem('bodyLength_' + draftId + '_' + emailReview?.id);

      if (previousBodyLength) {
        // compare
        if (Number(previousBodyLength) !== bodyLength) {
          localStorage.clear();
          comments.setLocalCommentsCounter(prev => 0);
        }
      }
      localStorage.setItem('bodyLength_' + draftId + '_' + emailReview?.id, String(bodyLength));
    }
  }, [emailPreview?.id, emailReview?.id, comments.localCommentsCounter]);

  useEffect(() => {
    if (comments.selectedNodeId && (isMobile || isOpen)) {
      setTimeout(() => {
        scrollToSelectedNode(comments.selectedNodeId);
      }, 100);
    }
  }, [isKeyboardOpen, comments.selectedNodeId]);

  const scrollToSelectedNode = (nodeId: string, behavior: ScrollBehavior = 'smooth') => {
    const selectedElement = document.getElementById(nodeId);
    const containerScroll = containerRef.current.parentElement;
    if (selectedElement && containerScroll) {
      const elementRect = selectedElement.getBoundingClientRect();
      const containerRect = containerScroll.getBoundingClientRect();

      const offsetTop = (elementRect.top - (containerRect.height * 0.2)) + containerScroll.scrollTop;
      // const viewportHeight = window.innerHeight;
      // const additionalOffset = isKeyboardOpen ? viewportHeight * 0.4 : 0;

      containerScroll.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const subjectAndPreheaderParsed = useMemo(() => {
    if (emailReview && emailPreview) {
      const formatStringToHtml = (key: string, value: string) => `<span><strong>${key}</strong> ${value}</span>`;

      let body = '';
      if (emailReview.status === REVIEW_STATUS.APPROVED || emailReview?.status === REVIEW_STATUS.CHANGES_REQUESTED) {
        body = formatStringToHtml('Subject line : ', emailReview.subject);

        if (emailReview.preHeader) {
          body += formatStringToHtml('Preview text : ', emailReview.preHeader || '');
        }
      } else {
        body = formatStringToHtml('Subject line : ', emailPreview.subject);
        if (emailPreview.preHeader) {
          body += formatStringToHtml('Preview text : ', emailPreview.preHeader || '');
        }
      }

      body += !isMobile ? '<hr style="margin: 0.5em 0em 1em 0em">' : '<hr>';

      return parse(body, {
        replace: (domNode, position) => {
          if (domNode instanceof Element && domNode.type === 'tag') {
            const uniqueId = `unique_id_${domNode.name}_${position}`;

            if (domNode.attribs.style) {
              const styleDict = parseStyleString(domNode.attribs.style);
              domNode.attribs.style = styleDict as any;
            }

            if (domNode.attribs.class) {
              domNode.attribs.className = domNode.attribs.class;
              delete domNode.attribs.class;
            }

            if (domNode.name === 'hr') {
              return <hr key={uniqueId} {...domNode.attribs} />;
            }

            const isSelected = comments.selectedNodeId === uniqueId;
            const nodeComments = comments.getComments(uniqueId);

            return (
              <div
                onClick={() => comments.toggleCommentInput(uniqueId)}
                className={!isSelected ? 'commentNumber' : ''}
                style={{
                  display: 'flex',
                  position: 'relative',
                  backgroundColor: isSelected ? '#A5B4FC' : '',
                }}
              >
                <MailberryNode
                  id={uniqueId}
                  domNode={domNode}
                />
                {(nodeComments?.length > 0)
                  && (
                    <Button
                      style={{
                        position: 'absolute',
                        right: 0,
                        height: '30px',
                      }}
                    >
                      {nodeComments?.length}
                    </Button>
                  )}
              </div>
            );
          }
        },
      });
    }
  }, [emailReview?.subject, emailReview?.preHeader, emailPreview?.subject, emailPreview?.preHeader, comments.selectedNodeId, comments.localCommentsCounter]);

  if (!emailReview) {
    return (
      <Group justify='center' style={{ width: '100%' }}>
        <Text size='lg'>Review not found!</Text>
      </Group>
    );
  }

  useEffect(() => {
    if (currentStep === 1) {
      appState.userAndCustomer.setIsInTour(true);
      setCurrentStep(currentStep + 1);
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep + 1 }).catch(e => console.error(e));
      comments.setSelectedNodeId('unique_id_mailberryimage_0');
      setTimeout(() => {
        scrollToSelectedNode('unique_id_mailberryimage_0', 'auto');
      }, 50);
    }

    if (appState.userAndCustomer.isInTour && currentStep === 3) {
      setIsOpen(false);
      comments.setShowCommentsBox(true);
    }

    if (appState.userAndCustomer.isInTour && currentStep === 5) {
      comments.setSelectedNodeId('unique_id_p_4');
      setCurrentStep(currentStep + 1);
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep + 1 }).catch(e => console.error(e));
      setIsOpen(true);
    }

    if (currentStep === 7) {
      comments.setSelectedNodeId(null);
    }
  }, [currentStep, appState.userAndCustomer.isInTour]);

  return (
    <Box
      style={{
        height: '100%',
      }}
      p={isMobile ? 0 : 4}
      ref={containerRef}
    >
      <Stack
        align='center'
        gap={10}
        w='100%'
      >
        <Group
          justify='center'
          display={isMobile ? 'none' : 'flex'}
          // gap={4}
          // m={'sm'}
        >
          <Button
            onClick={() => {
              setShowDesktopVersion(true);
            }}
            variant={showDesktopVersion ? 'light' : 'white'}
          >
            <MBMonitor size={24} color={showDesktopVersion ? '#3B82F6' : '#6B7280'} />
          </Button>
          <Button
            onClick={() => {
              setShowDesktopVersion(false);
            }}
            variant={!showDesktopVersion ? 'light' : 'white'}
          >
            <MBSmartphone size={24} color={!showDesktopVersion ? '#3B82F6' : '#6B7280'} />
          </Button>
        </Group>

        <Box
          style={{
            // borderRadius: !isMobile && showDesktopVersion ? '1em' : '0',
            // boxShadow: !isMobile ? '1px 1px 24px rgba(0,0,0,0.1)' : 'none',
            width: isMobile ? '100%' : showDesktopVersion ? '760px' : '390px',
            zIndex: 1,
          }}
        >
          <Box fz={'xl'} p={'8px 16px'}>
            <Stack gap={0}>
              {subjectAndPreheaderParsed}
            </Stack>
          </Box>
          <Box
            bg={!isMobile && showDesktopVersion ? styles.backgroundColor : ''}
            pt={!isMobile && showDesktopVersion ? '24px' : 0}
            mih={'300px'}
          >
            <Box className='tour-second-step'>
              {emailReview.editor === EMAIL_EDITOR_TYPE.CODE
                && (
                  <CodeEditorPreview
                    showDesktopVersion={showDesktopVersion}
                    isMobile={isMobile}
                    styles={styles}
                    emailReview={emailReview}
                    iframeRef={iframeRef}
                  />
                )}
              {emailReview.editor === EMAIL_EDITOR_TYPE.TIPTAP
                && (
                  <TiptapEditorPreview
                    showDesktopVersion={showDesktopVersion}
                    isMobile={isMobile}
                    styles={styles}
                    emailPreview={emailPreview}
                    emailReview={emailReview}
                    selectedNodeId={comments.selectedNodeId}
                    localCommentsCounter={comments.localCommentsCounter}
                    setStyles={setStyles}
                    toggleCommentInput={nodeId => comments.toggleCommentInput(nodeId, isOpen, currentStep)}
                    getComments={comments.getComments}
                  />
                )}
            </Box>
          </Box>
        </Box>

        <ReviewFooter
          emailReview={emailReview}
          isMobile={isMobile}
          showDesktopVersion={showDesktopVersion}
          localCommentsCounter={comments.localCommentsCounter}
          isLoading={isLoading}
          isOpen={isOpen}
          currentStep={currentStep}
          onRequestChanges={onRequestChanges}
          onApprove={onApprove}
        />
      </Stack>

      {comments.showCommentsBox
        && (
          <Comments
            isMobile={isMobile}
            showInput={emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW}
            showCommentList={comments.selectedNodeComments?.length !== 0}
            comments={comments.selectedNodeComments}
            onClose={comments.resetSelection}
            onAddComment={(content, xpath, position) => {
              const onAddComment = comments => {
                if (iframeRef?.current?.contentWindow) {
                  (iframeRef.current.contentWindow as any).eval(`addCommentCounters(${JSON.stringify(comments)})`);
                }
              };
              comments.handleAddComment(content, xpath, position, onAddComment);
            }}
            topPosition={containerRef.current?.parentElement.parentElement.parentElement.parentElement.scrollTop || 0 + 100}
            xpath={comments.xpath}
            position={comments.commentPosition}
          />
        )}
    </Box>
  );
};

const WrappedEmailReviewCore = (props: EmailReviewCore) => (
  <CommentsContainer.Provider initialState={{ emailReview: props.emailReview }}>
    <EmailReviewCore {...props} />
  </CommentsContainer.Provider>
);

export default WrappedEmailReviewCore;
