import { demoEmail } from '@/lib/constants';
import { getClientTimezone } from '@/lib/utils';
import MailberryTable from '@/mailberry/MailberryTable';
import { useGetDeliveryLinks, useGetDeliverySubscribersWhoClickedALink, useGetDeliverySubscribersWhoClickedALinkCount } from '@/services/api/delivery';
import AppState from '@/services/state/AppState';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import MBArrowLeft from '@assets/icons/MBArrowLeft';
import MBEmptyClicks from '@mailberry/Empty/MBEmptyClicks';
import { MBTableSkeleton } from '@mailberry/Table/Skeleton/MBTableSkeleton';
import { Box, Button, Group, Pagination, ScrollArea, Stack, Table, Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { usePagination } from '@services/hooks/usePagination';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const implementedFeatureDate = 1702496858954;

export const ClicksWrapper = ({ delivery, isLoadingDraft }) => {
  const { isLoading, data: links } = useGetDeliveryLinks(delivery?.id);

  return <Clicks delivery={delivery} links={links} isLoading={isLoading || isLoadingDraft}></Clicks>;
};

export function Clicks({ delivery, links, isLoading }) {
  const [linkSelected, setLinkSelected] = useState(null);
  const appState = AppState.useContainer();
  if (isLoading) {
    return (
      <Box>
        <ScrollArea style={{ height: 'calc(80vh - 200px)' }}>
          <MBTableSkeleton title={'Clicks'} manySelects={0} checkbox rows={[{ label: 'Link' }, { label: 'Clicks' }]} />
        </ScrollArea>
      </Box>
    );
  }

  const rows = links?.map(link => {
    if (link.link === '') return;
    const showNewFeature = new Date(link.createdAt) > new Date(implementedFeatureDate);

    return (
      <Table.Tr key={link.id}>
        <Table.Td>
          <a
            href={link.link}
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#1D1D1D',
              textDecoration: 'none',
            }}
          >
            {link.link}
          </a>
        </Table.Td>
        <Table.Td
          onClick={() => {
            if (showNewFeature) {
              setLinkSelected(link);
            } else {
              openContextModal({
                modal: 'content-not-available',
                title: 'Content Not Available',
                innerProps: {
                  text: `This section is only available for emails created after ${dayjs(implementedFeatureDate).format('MM/DD/YYYY')}`,
                },
              });
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          {link.clicks} Clicks
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Box>
      {linkSelected ? <LinkClickedOverview delivery={delivery} linkSelected={linkSelected} setLinkSelected={setLinkSelected} /> : (
        <Stack>
          <Title order={2} m={16}>{appState.signupAndLogin.email === demoEmail ? 45129 : (links?.length > 0 ? links.map(i => i.clicks).reduce((a, b) => a + b) : 0)} Clicks</Title>
          <ScrollArea>
            {links.length
              ? (
                <Table verticalSpacing='sm'>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Link</Table.Th>
                      <Table.Th>Clicks</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  {!!links.length && <tbody>{rows}</tbody>}
                </Table>
              )
              : <MBEmptyClicks />}
          </ScrollArea>
        </Stack>
      )}
    </Box>
  );
}

const LinkClickedOverview = ({ delivery, linkSelected, setLinkSelected }) => {
  const { isLoading: isLoadingCount, data: deliverySuscribersCount } = useGetDeliverySubscribersWhoClickedALinkCount(linkSelected.id);
  const pagination = usePagination(10, deliverySuscribersCount?.total);
  const { isLoading, data: deliverySubscribers } = useGetDeliverySubscribersWhoClickedALink(linkSelected.id, pagination.limit, pagination.offset);

  return (
    <Stack>
      <Group>
        <Button
          variant='subtle'
          color='gray'
          px={5}
          onClick={() => {
            setLinkSelected(null);
          }}
        >
          <MBArrowLeft size={24} />
        </Button>
        <h2 style={{ margin: 0 }}>{linkSelected.clicks} Clicked</h2>
      </Group>
      <ClicksTable
        isLoading={isLoading || isLoadingCount}
        linkSelected={linkSelected.link}
        subscribersCount={deliverySuscribersCount}
        deliverySubscribers={deliverySubscribers}
        pagination={pagination}
        delivery={delivery}
      />
    </Stack>
  );
};

const ClicksTable = ({ isLoading, linkSelected, subscribersCount, pagination, deliverySubscribers, delivery }) => {
  const { totalPages, page, setPage } = pagination;
  const timeZone = getClientTimezone();

  function setSubscribers(deliverySubscribers) {
    if (deliverySubscribers?.length) {
      return deliverySubscribers.map(deliverySubscriber => ({
        id: deliverySubscriber.id,
        email: (
          <Link
            to={'#'}
            style={{
              textDecoration: 'none',
              color: 'black',
            }}
          >
            {deliverySubscriber.email}
          </Link>
        ),
        clickedAt: formatDateToUserTimezone(deliverySubscriber.clickedAt, timeZone),
      }));
    }
  }

  if (isLoading) {
    return <MBTableSkeleton title={linkSelected} manySelects={0} checkbox rows={[{ label: 'Link' }, { label: 'Clicks' }]} />;
  }

  return (
    <Stack>
      <h2>{linkSelected}</h2>
      <MailberryTable
        headers={['id', 'Subscriber', 'Clicked At']}
        tableData={setSubscribers(deliverySubscribers)}
        total={subscribersCount?.total}
      />
      <Pagination
        display={totalPages > 1 ? 'flex' : 'none'}
        value={page}
        onChange={setPage}
        total={totalPages}
        defaultValue={1}
        color='mbblue'
      />
    </Stack>
  );
};
