import DeliveryReportStats from '@/mailberry/Stats/DeliveryReportStats';
import { useGetDeliveryLinks } from '@/services/api/delivery';
import { Divider, Group, LoadingOverlay } from '@mantine/core';
import { useGetDraftById } from '@services/api/drafts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ClicksWrapper } from './Clicks';
import OpenWrapper from './Opens';
import { OverviewWrapper } from './Overview/OverviewWrapper';
import { OverviewStats } from './OverviewStats';
import { EmailPreviewWrapper } from './Preview';
import RecipientsWrapper from './Recipients';
import UnsubscribedWrapper from './Unsubscribed';

const stylesChildren = [
  { border: false },
  { border: false },
  { border: false },
  { border: false },
  { border: false },
  { border: false },
];

const ReportStats = ({ showHeader }) => {
  const { draftId } = useParams();
  const { data: draft, isLoading } = useGetDraftById(draftId);
  // const { data: links, isLoading: isLoadingLinks } = useGetDeliveryLinks(draft?.delivery?.id);

  // if (isLoading || isLoadingLinks) return <LoadingOverlay visible />;

  return (
    (draft && draft.delivery) || isLoading
      ? (
        <DeliveryReportStats showHeader={showHeader} stylesChildren={stylesChildren}>
          <OverviewWrapper draft={draft} isLoading={isLoading} />
          <EmailPreviewWrapper draft={draft} isLoading={isLoading} />
          <RecipientsWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
          <OpenWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
          <ClicksWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
          <UnsubscribedWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
        </DeliveryReportStats>
      )
      : (
        <Group justify='center' align='center' w={'100%'} h={'100%'}>
          <h1>Report Not Found</h1>
        </Group>
      )
  );

  return (
    (draft && draft.delivery)
      ? (
        <>
          <OverviewStats delivery={draft?.delivery} />
          <Divider />
          <EmailPreviewWrapper draft={draft} isLoading={isLoading} />
        </>
      )
      : (
        <Group justify='center' align='center' w={'100%'} h={'100%'}>
          <h1>Report Not Found</h1>
        </Group>
      )
  );
};

export default ReportStats;
