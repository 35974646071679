import { useGetAllSubscribersCount } from '@/services/api/subscriber';
import AppState from '@/services/state/AppState';
import { SUBSCRIBER_STATUS } from '@/sharedTypes';
import MBRecipients from '@assets/icons/MBRecipients';
import { Box, Flex, Group, Skeleton, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconPlayerPlay } from '@tabler/icons-react';
import { fakeStats } from '../Dashboard/Overview/SequencesList';
import classes from './SequenceSegmentTriggerCard.module.css';

type SequenceSegmentTriggerCardProps = {
  segmentName: string;
  segmentId: string;
};

const SequenceSegmentTriggerCard = ({ segmentName, segmentId }: SequenceSegmentTriggerCardProps) => {
  const { data: subscribersTotal, isLoading: isLoadingSubscribersCount } = useGetAllSubscribersCount({
    segmentIds: [segmentId],
    status: [SUBSCRIBER_STATUS.ACTIVE],
  });

  const appState = AppState.useContainer();

  return (
    <Box className={classes.cardContainer}>
      <Flex>
        <Stack className={classes.descriptionContainer} gap={0}>
          <Flex align={'center'}>
            <Box px={16}>
              <ThemeIcon size={'xl'} radius={'md'}>
                <IconPlayerPlay fill='#fff' />
              </ThemeIcon>
            </Box>
            <Stack style={{ flex: 1, borderLeft: '1px solid #727EF2', overflow: 'hidden' }} gap={0} py={16} px={12}>
              <Text span fw={400} color='mbdefault.3' style={{ fontSize: 14 }}>Segment Trigger</Text>
              <Text span fw={700} size={'md'} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{segmentName}</Text>
            </Stack>
          </Flex>
          <Stack px={12} align='center' py={8} style={{ borderTop: '1px solid #727EF2' }}>
            {isLoadingSubscribersCount ? <Skeleton h={32} w={120} /> : (
              <Tooltip label='Subscribers'>
                <Group gap={8}>
                  <MBRecipients size={20} color='#727EF2' />
                  <Text span c='mbblueberry.2'>{fakeStats(segmentName, subscribersTotal?.total, appState.signupAndLogin.email)}</Text>
                </Group>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};

export default SequenceSegmentTriggerCard;
