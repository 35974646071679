import ChevronRight from '@/assets/icons/ChevronRight';
import { Title } from '@mantine/core';
import { Link } from 'react-router-dom';

interface SectionTitleProps {
  title: string;
  href?: string;
}

export default function SectionTitle({ title, href }: SectionTitleProps) {
  const content = (
    <Title order={4} fw={600} style={{ display: 'flex', alignItems: 'center', gap: '4px', color: '#000', marginLeft: '20px' }}>
      {title}
      {href && <ChevronRight size={20} />}
    </Title>
  );

  if (href) {
    return (
      <Link to={href} style={{ display: 'flex', alignItems: 'center', gap: '4px', textDecoration: 'none' }}>
        {content}
      </Link>
    );
  }

  return content;
}
