import React, { ForwardedRef, forwardRef, useEffect, useRef } from 'react';

interface AutoHeightIframeProps {
  htmlContent: string;
  onLoad?: () => void;
}

const AutoHeightIframe = forwardRef<HTMLIFrameElement, AutoHeightIframeProps>(({ htmlContent, onLoad }, ref: ForwardedRef<HTMLIFrameElement>) => {
  console;

  useEffect(() => {
    const handleResize = () => {
      if (!ref || typeof ref === 'function') return;
      const iframe = ref.current;
      if (iframe) {
        iframe.height = iframe.contentWindow.document.body.scrollHeight + 30 + 'px';
      }
    };

    if (!ref || typeof ref === 'function') return;
    const iframe = ref.current;
    if (iframe) {
      iframe.addEventListener('load', handleResize);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleResize);
      }
    };
  }, [htmlContent]);

  return <iframe ref={ref} srcDoc={htmlContent} style={{ width: '100%', border: 'none', borderWidth: '0px' }} onLoad={onLoad} />;
});

export default AutoHeightIframe;
