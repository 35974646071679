import { useEnableIntegration } from '@/services/api/shopify';
import { Container, LoadingOverlay } from '@mantine/core';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EnableShopifyApp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const hmac = searchParams.get('hmac');
  const host = searchParams.get('host');
  const state = searchParams.get('state');
  const shop = searchParams.get('shop');
  const timestamp = searchParams.get('timestamp');

  const enableIntegration = useEnableIntegration();

  useEffect(() => {
    enableIntegration.mutateAsync({ code, hmac, host, shop, state, timestamp })
      // .then(r => {
      //   console.log(r);
      // })
      .catch(e => console.error(e))
      .finally(() => navigate('/settings/shopify'));
  }, []);

  return (
    <>
      <Container fluid style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column', flexWrap: 'wrap' }}>
        <LoadingOverlay visible />
      </Container>
    </>
  );
};

export default EnableShopifyApp;
