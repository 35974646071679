var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MBDrag from '@assets/icons/MBDrag';
import { Box, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { MenuActions } from '@pages/EmailCompose/Common/Tiptap/Menus/MenuActions';
import { Section, Text } from '@react-email/components';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';
import styleToJs from 'style-to-js';
import React from 'react';
var ParagraphView = function (_a) {
    var editor = _a.editor, node = _a.node, getPos = _a.getPos, selected = _a.selected, l = __rest(_a, ["editor", "node", "getPos", "selected"]);
    var textAlign = node.attrs.textAlign;
    var _b = useHover(), hovered = _b.hovered, ref = _b.ref;
    var _c = useState(false), menuOpen = _c[0], setMenuOpen = _c[1];
    var sectionStyles = {
        padding: '12px 50px',
        height: '40px',
    };
    if (editor.storage.isMobile) {
        sectionStyles.padding = '0 20px';
    }
    return (React.createElement(NodeViewWrapper
    // onClick={() => toggleCommentInput('uniqueId')}
    //  className={classNames.join(' ')}
    , { 
        // onClick={() => toggleCommentInput('uniqueId')}
        //  className={classNames.join(' ')}
        ref: ref, style: {
            display: 'flex',
            position: 'relative',
            // backgroundColor:inClicked? '#A5B4FC':'transparent',
            //cursor: 'pointer',
        } },
        React.createElement(Box, { className: 'node-actions', style: { zIndex: menuOpen ? 999 : 300 } },
            React.createElement(Group, { w: 80, mr: 0, gap: 0, align: 'left', style: {
                    visibility: hovered || menuOpen ? 'visible' : 'hidden',
                } },
                React.createElement(Group, { gap: 8 },
                    React.createElement(Box, { h: 24, style: { cursor: 'pointer' } },
                        React.createElement(MenuActions, { editor: editor, open: menuOpen, setOpen: setMenuOpen })),
                    React.createElement(Box, { h: 24, "data-drag-handle": true, style: { cursor: 'grab' } },
                        React.createElement(MBDrag, { size: 24, color: '#A0A0A0' }))))),
        React.createElement(Section, { style: sectionStyles },
            React.createElement(Text, { style: __assign({ fontSize: '18px', margin: '8px 0', textAlign: textAlign }, styleToJs(node.attrs.style)) },
                React.createElement(NodeViewContent, { as: 'span' })))));
};
export default ParagraphView;
